<app-header></app-header>

<div class="page-header" style="background-image:url(../../../../assets/img/login.jpg);background-size: cover;">
    <div class="page-header-image" ></div>
    <div class="container box">
    <div class="col-md-8 col-sm-12 mx-auto">
        <div class="resetPassword">
       
            <!-- <form [formGroup]="resetformforloophead">
                <div class="form-group col-lg-12 ">
                    <label for="password">Password</label><span class="mx-4 text-danger">*</span>
                    <input type="password" (keyup.enter)="Submit()" class="form-control "
                        formControlName="passwordLoginpage" placeholder="Password" name="password" name="password">
                    <span style="display: flex; justify-content: space-between; flex-direction: column;">
                        <small class="form-text text-danger"
                            *ngIf="resetformforloophead.get('passwordLoginpage')?.touched && resetformforloophead.get('passwordLoginpage')?.hasError('required')">Password
                            is required</small>
                        <small class="form-text text-danger"
                            *ngIf="resetformforloophead.get('passwordLoginpage')?.touched && resetformforloophead.get('passwordLoginpage')?.hasError('minlength')">Minimum
                            6 characters required</small>
                        <small class="form-text text-danger"
                            *ngIf=" resetformforloophead.get('passwordLoginpage')?.hasError('pattern')">Password should
                            include at
                            least
                            one uppercase letter and one special character</small>
                    </span>
                </div>
                <div class="form-group col-lg-12 ">
                    <label for="password">Confirm Password</label><span class="mx-4 text-danger">*</span>
                    <input type="password" (keyup.enter)="Submit()" class="form-control "
                        formControlName="passwordconfirmLoginpage" placeholder="Password" name="password"
                        name="password">
                    <span style="display: flex; justify-content: space-between; flex-direction: column;">
                        <small class="form-text text-danger"
                            *ngIf="resetformforloophead.get('passwordconfirmLoginpage')?.touched && resetformforloophead.get('passwordconfirmLoginpage')?.hasError('required')">Confirm
                            Password
                            is required</small>
                        <small class="form-text text-danger"
                            *ngIf="resetformforloophead.get('passwordconfirmLoginpage')?.touched && resetformforloophead.get('passwordconfirmLoginpage')?.hasError('minlength')">Minimum
                            6 characters required</small>
                        <small class="form-text text-danger"
                            *ngIf=" resetformforloophead.get('passwordconfirmLoginpage')?.hasError('pattern')">Password
                            should
                            include at
                            least
                            one uppercase letter and one special character</small>
                        <small id="confirmpasserror" class="text-danger confirmperror"></small>
                    </span>

                </div>

                <div class="form-group col-lg-12 col-md-6">
                    <button type="button" class="btn btn-theme" (click)="Submit()">Submit</button>
                </div>
            </form> -->
            <div class="card z-index-0" style="border-radius: 0; padding: 25px">
            
                <div class="card-body">
                    <h1 class=" center">Reset Password</h1>
            <form  [formGroup]="resetformforloophead">
                <div class="form-group">
                    <label style="color: black;" for="password">Password</label><span class="mx-4 text-danger">*</span>
                    <input type="password" (keyup.enter)="Submit()" class="form-control "
                        formControlName="passwordLoginpage" placeholder="Password" name="password" name="password">
                    <span style="display: flex; justify-content: space-between; flex-direction: column;">
                        <small class="form-text text-danger"
                            *ngIf="resetformforloophead.get('passwordLoginpage')?.touched && resetformforloophead.get('passwordLoginpage')?.hasError('required')">Password
                            is required</small>
                        <small class="form-text text-danger"
                            *ngIf="resetformforloophead.get('passwordLoginpage')?.touched && resetformforloophead.get('passwordLoginpage')?.hasError('minlength')">Minimum
                            6 characters required</small>
                        <small class="form-text text-danger"
                            *ngIf=" resetformforloophead.get('passwordLoginpage')?.hasError('pattern')">Password should
                            include at
                            least
                            one uppercase letter and one special character</small>
                    </span>
                </div>
                <div class="form-group">
                    <label style="color: black;"  for="password">Confirm Password</label><span class="mx-4 text-danger">*</span>
                    <input type="password" (keyup.enter)="Submit()" class="form-control "
                        formControlName="passwordconfirmLoginpage" placeholder="Password" name="password"
                        name="password">
                    <span style="display: flex; justify-content: space-between; flex-direction: column;">
                        <small class="form-text text-danger"
                            *ngIf="resetformforloophead.get('passwordconfirmLoginpage')?.touched && resetformforloophead.get('passwordconfirmLoginpage')?.hasError('required')">Confirm
                            Password
                            is required</small>
                        <small class="form-text text-danger"
                            *ngIf="resetformforloophead.get('passwordconfirmLoginpage')?.touched && resetformforloophead.get('passwordconfirmLoginpage')?.hasError('minlength')">Minimum
                            6 characters required</small>
                        <small class="form-text text-danger"
                            *ngIf=" resetformforloophead.get('passwordconfirmLoginpage')?.hasError('pattern')">Password
                            should
                            include at
                            least
                            one uppercase letter and one special character</small>
                        <small id="confirmpasserror" class="text-danger confirmperror"></small>
                    </span>
                </div>
                <div class="form-group">
                    <button type="button"
                    style="border-radius: 10px;
                    padding: 12px 50px;
                    background-color: black;
                    display: flex;
                    width: fit-content;" class="btn btn-theme btn-rounded" (click)="Submit()">Submit</button>
                </div>
            </form>
            </div>
            </div>
        </div>
    </div>
    </div>
</div>
