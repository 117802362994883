import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { commonservice } from 'src/app/allServices/commonservice';
import { APIURL } from 'src/app/allServices/constant.service';

@Component({
  selector: 'app-confirm',
  templateUrl: './confirm.component.html',
  styleUrls: ['./confirm.component.css']
})
export class ConfirmComponent implements OnInit {
  user_id: any;

  constructor(   public matDialog: MatDialog,
    private toastr: ToastrService,
    public common: commonservice,
    private http: HttpClient,
    private router: Router,
  ) { }

  ngOnInit(): void {
  }
  cancel() {
    this.matDialog.closeAll();
  }
  submit() {
    let tokkenget: any = localStorage.getItem("clientintoken");
    let headers: any = {
      Authorization: `Bearer ${tokkenget}`,
      "My-Custom-Header": "foobar",
    };

        this.user_id = localStorage.getItem("client_data");
        this.http
      .post(
        `${APIURL.BASE_URL}/user/upload_all`,
        { user_id: JSON.parse(this.user_id).user_type_id },
        {
          headers: headers,
        }
      )
      .pipe()
      .subscribe((event: any) => {
        // ////console.log("event:", event);
        if (event.response.status) {
        this.toastr.success("Loops Uploaded Successfully. ", "", { positionClass: 'toast-bottom-right' });
        
        this.router.navigate([`user/sounds/@${JSON.parse(this.user_id).username}`]);
      }
        this.matDialog.closeAll();
      });
  }

}
