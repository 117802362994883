



import { environment } from 'src/app/sevicesall/alldata';
import { DatePipe } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { ApiServiceService } from 'src/app/allServices/api-service.service';
import { commonservice } from 'src/app/allServices/commonservice';
import { APIURL, CONSTNAME, VARS } from 'src/app/allServices/constant.service';
import * as CryptoJS from 'crypto-js';
import * as moment from 'moment';
import { ConfirmDeleteComponent } from 'src/app/CLIENT_PAGES/MODAL/confirm-delete/confirm-delete.component';
import { MatDialog } from '@angular/material/dialog';
import { DownloadmodalComponent } from 'src/app/CLIENT_PAGES/MODAL/downloadmodal/downloadmodal.component';
import { ShareComponent } from 'src/app/CLIENT_PAGES/MODAL/share/share.component';
import { ZipService } from 'src/app/allServices/zip.service';
import { EditPackComponent } from 'src/app//CLIENT_PAGES/MODAL/edit-pack/edit-pack.component';
import { EditLoopComponent } from 'src/app/CLIENT_PAGES/MODAL/edit-loop/edit-loop.component';
import { DownloadComponent } from 'src/app/CLIENT_PAGES/MODAL/downloads/downloads.component';
@Component({
  selector: 'app-loopkitsuserlikes',
  templateUrl: './loopkitsuserlikes.component.html',
  styleUrls: ['./loopkitsuserlikes.component.css']
})


export class LoopkitsuserlikesComponent implements OnInit {
  @Input() check: any;


  userMenuforlike = environment.Likedlooploopkit;
  loop = environment.Likedlooploopkit[0].active;

  userMenuforSoundmenu = environment.SOundprofile;
  sound = environment.SOundprofile[0].active;


  tokenFromUI: string = "0123456789123456";
  encrypted: any = "";
  decrypted: string;
  request: string;
  responce: string;
  pagenolist: any;
  tableData: any[] = [];
  pageno: any = 1;
  rowsize: any = 10;
  pagercount: any = [];
  validationforsearch: any = '';
  paginationempty = false;
  noresult = false;
  sortsign: any = "sort";
  searchwordget: any;
  getpageno: any;
  total: any;
  record: any;
  hidepagination = true;
  norecordfound: any;
  sorttype = "";
  sorton = "";
  imageofcomment: any;
  profile_image_pathofcomments = "assets/img/artwork.jpg";
  value: any;
  encryptedagainremoveslashes: string;
  tablefortagsdisplay1: any;
  tablefortagsdisplay2: any;
  tablefortagsdisplay3: any;
  time: string;
  addred: boolean;
  addgreen: boolean;
  durationofaudio: any;
  above15second = false;
  apihitforstream = true;
  above5secondlessthan15 = false;
  above1secondlessthan5 = false;
  id: any;
  nocomments: boolean;
  src: any;
  pagenocount: any;
  yesgreencolor: boolean;
  fileService: any;
  encrypted2: string;
  checktoken: boolean;
  idfromwebsite: any;
  array: any = [];
  urlpass: string;
  urlstatusmessage: string;
  valuefromurl: any;
  search: any;
  loopidurl: any;
  loopidfromurl: any;
  arraytableforallvalues: any;
  user: any;
  userData: any;
  res: any;
  // @Output() messageEvent = new EventEmitter<string>();


  constructor(private router: Router, private http: HttpClient,private zipService: ZipService, private toastr: ToastrService, private route: ActivatedRoute, private datePipe: DatePipe, public common: commonservice, public api: ApiServiceService, private matDialog: MatDialog) {
    // //////console.log(this.router.url) 
    // this.messageEvent.emit()
  }

  ngAfterViewInit() {
    // //////console.log(this.router.url,"likes")

  }

  // userMenuforfollow = environment.followerspage;
  // followers = environment.followerspage[0].active
  // following = environment.followerspage[1].active 
  ngOnInit(): void {

    // this.valuefromurl = this.route.snapshot.queryParamMap.get('msg');

    // this.urlstatusmessage = atob(this.valuefromurl)

    // if (this.urlstatusmessage == "success") {
    //   // //////console.log("in success")
    //   this.loopidurl = this.route.snapshot.queryParamMap.get('loop_id');
    //   // //////console.log(this.loopidurl)

    //   this.loopidfromurl = atob(this.loopidurl)
      // //////console.log("in loop id ", this.loopidfromurl);



      // //////console.log(this.loopidfromurl)
      // setTimeout(() => {
      //   this.apihitforpaymentapi();
      // }, 2000);

      // //////console.log("yes now api hit")
    // }
    // this.pageno = this.route.snapshot.queryParamMap.get('pageno')
    // if (this.pageno) {
    //   this.pageno = Number(this.pageno)
    // } else {
    //   this.pageno = 1;
    // }

    // this.urlpass = APIURL.BASE_URLforredirect + this.router.url
    // //////console.log(this.urlpass)

    // this.value = this.route.snapshot.queryParamMap.get('user_id');
    // this.encryptedagainremoveslashes = decodeURIComponent(this.value);

    const username = this.router.url.slice(13,13+50)
      // ////console.log('Username:', username);
      this.value = username
    this.getapicallforcouponcode();

    this.common.getDataInCookie(VARS.Login_Client_data).then((res: any) => {
      if (!res) {
        this.checktoken = false
        // this.getUserDetail();
      } else {
        // //////console.log("in")
        this.checktoken = true;
        let resa = JSON.parse(res)
        this.idfromwebsite = resa.user_type_id
        // //////console.log(this.idfromwebsite)
      }
    })
  }

  ngOnDestroy() {
    this.stopAudio();
  }

  async getapicallforcouponcode() {

    // this.common.showLoader();


    // this.router.navigate(['user/liked-loops-loopkits'], { queryParams: { user_id: this.value, pageno: this.pageno } });

    // if (this.pageno == 1) {
    //   this.router.navigate(['user/liked-loops-loopkits'], { queryParams: { user_id: this.value, pageno: this.pageno } });
    // } else {
    //   this.router.navigate(['user/liked-loops-loopkits'], { queryParams: { user_id: this.value, pageno: this.pageno } });
     var sendingdata1 = {
      "username": this.value
    }
   //  ////console.log(sendingdata);
    
          this.api.forclientgettokenpublic(`/user/get_user_id`, sendingdata1).then(async (res: any) => {
 
    this.userData = localStorage.getItem("client_data");
    this.res = JSON.parse(this.userData)
    let sendingdata = {
      "page_no": this.pageno,
      "sort_on": this.sorton,
      "row_size": this.rowsize,
      "sort_type": this.sorttype,
      "get_liked_loops": 0,
      "profile_user_id": res?.response?.data?.user_type_id,
      "login_user_id": this.res?.user_type_id,
      "search":this.search
    }

    let tokkenget = await this.common.getlocal(VARS.CLIENT_LOCAL_TOKEN_KEY);

    let headers: any;
    if (tokkenget) {
      headers = { 'Authorization': `Bearer ` + tokkenget, 'My-Custom-Header': 'foobar', };

    }



    // this.http.post(APIURL.BASE_URL + `/user/get_liked_loops_loopkits`, sendingdata, { headers }).subscribe(async (res: any) => {
    this.api.forclientgettokenpublic(`/user/get_all_loops_loopkits`, sendingdata).then((res: any) => {



      if (res.response.status == 1) {

        // this.common.hideLoader();


        if (res.response.data.total_records == 0) {
          this.norecordfound = false;
          this.common.hideLoader();
        } else {
          // setTimeout(() => {
          this.norecordfound = true;
          this.common.hideLoader();
          // }, 3000);

          this.tableData = res.response.data.pagging_list
          // //////console.log('this.tableData',this.tableData);
          
          // setTimeout(() => {
          this.common.hideLoader();
          // }, 4000);
        }

        if (res.response.data.total_pages == 1) {
          this.hidepagination = true;
          // //////console.log("hide");
        } else {
          this.hidepagination = false;
          // //////console.log("not hide");
        }
        if (this.paginationempty == true) {
          this.pagercount = [];
        }
        this.total = res.response.data.total_records;
        if (res.response.data.total_records == 0) {
          this.norecordfound = false;
          this.common.hideLoader();
        } else {
          // setTimeout(() => {
            this.common.hideLoader();
          // }, 3000);

          for (let i = 0; i < res.response.data.pagging_list.length; i++) {
            this.array = res.response.data.pagging_list[i];

            let a = this.array.tags
          let strtag: any = a?.split('\n ');
          this.array['tag1'] = strtag[0];
          this.array['tag2'] = strtag[1];
          this.array['tag3'] = strtag[2];

            this.common.hideLoader();


            // //////console.log(this.encrypted)

            this.array['enc_link'] = this.value;
            this.tableData.push(this.array)
            let _key = CryptoJS.enc.Utf8.parse(this.tokenFromUI);
            let _iv = CryptoJS.enc.Utf8.parse(this.tokenFromUI);
            let encrypted = CryptoJS.AES.encrypt(
              JSON.stringify(this.array.loop_id), _key, {
              keySize: 16,
              iv: _iv,
              mode: CryptoJS.mode.ECB,
              padding: CryptoJS.pad.Pkcs7
            });
            this.encrypted = encrypted.toString();
            this.encrypted = encodeURIComponent(this.encrypted);

            
            this.array['loop_idencrypt'] = this.encrypted;
            this.tableData.push(this.array)




          }

          // this.tableData = res.response.data.pagging_list;

          this.record = this.tableData.length;
          // //////console.log(this.record,this.pageno)
          if (this.record == 0) {
            this.nocomments = false;
          } else {
            this.nocomments = true;
          }
          this.pagenolist = res.response.data.total_pages + 1;
          if (this.pageno == 1) {
             this.record = (25*0) + "1";
          } else {
            let a = this.pageno - 1
            this.record = (25*(a)+ 1) ;
          }
         
          // //////console.log(10*(this.pageno - 1) + res.response.data.pagging_list.length);
          this.pagenocount = 25 * (this.pageno - 1) + res.response.data.pagging_list.length

          this.tableData = res.response.data.pagging_list;
          // this.record = this.tableData.length;
          this.pagenolist = res.response.data.total_pages + 1;
          if (this.record == "01") {
            this.record = 1;
          }
          for (let i = 1; i < this.pagenolist; i++) {
            this.pagercount.push(i);
          }
          this.norecordfound = true;
          for (i = 0; i < res.response.data.pagging_list.length; i++) {
            let a = res.response.data.pagging_list[i].tags
            let strtag: any = a.split('\n ');
            let strtag1: any = strtag[0];
            let strtag2: any = strtag[1];
            let strtag3: any = strtag[2];

            this.tablefortagsdisplay1 = strtag1;
            this.tablefortagsdisplay2 = strtag2;
            this.tablefortagsdisplay3 = strtag3;
          }

          var date_future: any = new Date();
          var date_now: any = new Date(res.response.data.pagging_list[0].modified_on);


          if (res.response.data.pagging_list[0].is_liked == 1) {

            this.addred = true;

          } else {
            this.addred = false;

          }
          if (res.response.data.pagging_list[0].is_reposted == 1) {
            this.addgreen = true;

          } else {
            this.addgreen = false;

          }

          var time = date_future - date_now;

          var seconds = moment.duration(time).seconds();
          var minutes = moment.duration(time).minutes();
          var hours = moment.duration(time).hours();
          var days = moment.duration(time).days();





          if (seconds < 60 && minutes === 0) {
            // //////console.log(seconds)
            return "Posted " + seconds + " sec" + " ago"
          }
          else if (minutes < 60 && hours === 0) {

            return "Posted " + minutes + " min" + " ago"

          }
          else if (hours == 1 && days === 0) {
            return "Posted " + hours + " hr" + "ago"
          }
          else if (hours <= 24 && days === 0) {
            return "Posted " + hours + " hrs" + " ago"
          } else if (days === 1) {
            return "Posted " + days + " day" + " ago"

          }

          else if (days > 1) {
            // //////console.log(days)
            return "Posted " + days + " days" + " ago"
          }

          for (let i = 1; i < this.pagenolist; i++) {
            this.pagercount.push(i);

          }



          this.total = res.response.data.total_records;
          // //////console.log(this.total)

          if (res.response.data.pagging_list[0].loop_type == 1) {
            if (res.response.data.pagging_list[0].tagged_loop_path !== null) {
              this.src = CONSTNAME.fILE_PATH + res.response.data.pagging_list[0].tagged_loop_path;
              this.durationofaudio = res.response.data.pagging_list[0].tagged_loop_duration;
              // //////console.log("checking");
            } else {
              this.src = CONSTNAME.fILE_PATH + res.response.data.pagging_list[0].loop_path;
              this.durationofaudio = res.response.data.pagging_list[0].loop_duration;
            }

            // //////console.log(this.src);
          } else {
            this.src = CONSTNAME.fILE_PATH + res.response.data.pagging_list[0].tagged_loop_path;
            // //////console.log(this.src);
            this.durationofaudio = res.response.data.pagging_list[0].tagged_loop_duration;
          }
        }
        for (let i = 0; i < res.response.data.pagging_list.length; i++) {
          this.array = res.response.data.pagging_list[i];
          let a = this.array.tags
          let strtag: any = a.split('\n ');
          this.array['tag1'] = strtag[0];
          this.array['tag2'] = strtag[1];
          this.array['tag3'] = strtag[2];
          this.common.hideLoader();
          let _key = CryptoJS.enc.Utf8.parse(this.tokenFromUI);
          let _iv = CryptoJS.enc.Utf8.parse(this.tokenFromUI);
          let encrypted = CryptoJS.AES.encrypt(
            JSON.stringify(this.array.loop_id), _key, {
            keySize: 16,
            iv: _iv,
            mode: CryptoJS.mode.ECB,
            padding: CryptoJS.pad.Pkcs7
          });
          this.encrypted = encrypted.toString();
          this.encrypted = encodeURIComponent(this.encrypted);
          this.array['loop_idencrypt'] = this.encrypted;
          this.tableData.push(this.array)
        }
        for (let i = 0; i < res.response.data.pagging_list.length; i++) {
          this.array = res.response.data.pagging_list[i];
          this.common.hideLoader();
          let _key = CryptoJS.enc.Utf8.parse(this.tokenFromUI);
          let _iv = CryptoJS.enc.Utf8.parse(this.tokenFromUI);
          let encrypted = CryptoJS.AES.encrypt(
            JSON.stringify(this.array.user_id), _key, {
            keySize: 16,
            iv: _iv,
            mode: CryptoJS.mode.ECB,
            padding: CryptoJS.pad.Pkcs7
          });
          this.encrypted = encrypted.toString();
          this.encrypted = encodeURIComponent(this.encrypted);
          this.array['enc_link'] = this.encrypted;
          this.tableData.push(this.array)
        }

      } else {
        this.common.hideLoader();


        let errorthroughapi = res.response.errors;
        let error = [];
        for (var i = 0; i < errorthroughapi.length; i++) {

          this.toastr.error(res.response.errors[i], "", { positionClass: 'toast-bottom-right' })
        }

      }
    }, async e => {
      this.common.hideLoader();

      this.toastr.error("Error in Api", "", { positionClass: 'toast-bottom-right' });

    });
  });
  }
  paginationgetmore() {
    this.paginationempty = true;

    // this.pageno = pageinfo;
    this.rowsize += this.rowsize
    // //////console.log(this.rowsize);


    this.getapicallforcouponcode();



  }

  callforallinfo(res: any) {
    for (let i = 0; i < res.response.data.pagging_list.length; i++) {
      let a = res.response.data.pagging_list[i].tags
      let strtag: any = a.split('\n ');
      let strtag1: any = strtag[0];
      let strtag2: any = strtag[1];
      let strtag3: any = strtag[2];

      this.tablefortagsdisplay1 = strtag1;
      this.tablefortagsdisplay2 = strtag2;
      this.tablefortagsdisplay3 = strtag3;
    }

    var date_future: any = new Date();
    var date_now: any = new Date(res.response.data.pagging_list[0].modified_on);


    if (res.response.data.pagging_list[0].is_liked == 1) {

      this.addred = true;

    } else {
      this.addred = false;

    }
    if (res.response.data.pagging_list[0].is_reposted == 1) {
      this.addgreen = true;

    } else {
      this.addgreen = false;

    }

    var time = date_future - date_now;

    var seconds = moment.duration(time).seconds();
    var minutes = moment.duration(time).minutes();
    var hours = moment.duration(time).hours();
    var days = moment.duration(time).days();




    if (seconds < 60 && minutes === 0) {
      // //////console.log(seconds)
      return "Posted " + seconds + " sec" + " ago"
    }
    else if (minutes < 60 && hours === 0) {

      return "Posted " + minutes + " min" + " ago"

    }
    else if (hours == 1 && days === 0) {
      return "Posted " + hours + " hr" + "ago"
    }
    else if (hours <= 24 && days === 0) {
      return "Posted " + hours + " hrs" + " ago"
    } else if (days === 1) {
      return "Posted " + days + " day" + " ago"

    }

    else if (days > 1) {
      // //////console.log(days)
      return "Posted " + days + " days" + " ago"
    }

    for (let i = 1; i < this.pagenolist; i++) {
      this.pagercount.push(i);

    }




    this.total = res.response.data.total_records;

    if (res.response.data.pagging_list[0].loop_type == 1) {
      if (res.response.data.pagging_list[0].tagged_loop_path !== null) {
        this.src = CONSTNAME.fILE_PATH + res.response.data.pagging_list[0].tagged_loop_path;
        this.durationofaudio = res.response.data.pagging_list[0].tagged_loop_duration;
        // //////console.log("checking");
      } else {
        this.src = CONSTNAME.fILE_PATH + res.response.data.pagging_list[0].loop_path;
        this.durationofaudio = res.response.data.pagging_list[0].loop_duration;
      }

      // //////console.log(this.src);
    } else {
      this.src = CONSTNAME.fILE_PATH + res.response.data.pagging_list[0].tagged_loop_path;
      // //////console.log(this.src);
      this.durationofaudio = res.response.data.pagging_list[0].tagged_loop_duration;
    }
  }
  timeget(items: any) {



    let newa: any = new Date();
    let next: any = new Date(items.created_on)

    var seconds: any = Math.floor((newa - next) / 1000);

    var interval: any = seconds / 31536000;

    if (interval > 1) {
      return "Posted " + Math.floor(interval) + " year ago";
    }
    interval = seconds / 2592000;
    if (interval > 1) {

      return "Posted " + Math.floor(interval) + " month ago";
    }
    interval = seconds / 86400;
    if (interval > 1) {
      if (Math.floor(interval) == 1) {
        return "Posted " + Math.floor(interval) + " day ago";
      } else {
        return "Posted " + Math.floor(interval) + " days ago";
      }

    }
    interval = seconds / 3600;
    if (interval > 1) {
      if (Math.floor(interval) == 1) {
        return "Posted " + Math.floor(interval) + " hour ago";
      } else {
        return "Posted " + Math.floor(interval) + " hours ago";
      }
    }
    interval = seconds / 60;
    if (interval > 1) {
      return "Posted " + Math.floor(interval) + " minute ago";
    }
    return "Posted " + Math.floor(seconds) + " second ago";
  }

  priceget(item: any) {
    if (item.loop_price == 0) {

    } else {
      return (Math.round(item.loop_price * 100) / 100).toFixed(2);

    }
  }

  tagsget(items: any) {
    let a = items.tags
    let strtag: any = a.split('\n ');
    let strtag1: any = strtag[0];
    let strtag2: any = strtag[1];
    let strtag3: any = strtag[2];
    // //////console.log(strtag1);
    // //////console.log(strtag2);
    // //////console.log(strtag3);
    return "#" + strtag1;

  }
  tagsget2(items: any) {
    let a = items.tags
    let strtag: any = a.split('\n ');

    let strtag2: any = strtag[1];
    return "#" + strtag2
  }
  tagsget3(items: any) {
    let a = items.tags
    let strtag: any = a.split('\n ');
    //  //////console.log(strtag[2])
    let strtag3: any = strtag[2];
    // //////console.log(strtag3);
    return "#" + strtag3
  }


  srcforsound(items: any) {
    if (items.loop_type == 1) {
      if (items.tagged_loop_path !== null) {
        this.durationofaudio = items.tagged_loop_duration;
        return CONSTNAME.fILE_PATH + items.tagged_loop_path;
        // //////console.log("checking");
      } else {
        this.durationofaudio = items.loop_duration;
        return CONSTNAME.fILE_PATH + items.loop_path;
      }

      // //////console.log(this.src);
    } else {
      this.durationofaudio = items.tagged_loop_duration;
      return CONSTNAME.fILE_PATH + items.tagged_loop_path;
      // //////console.log(this.src);
    }
  }









  detailpageofloop(value: any) {

    this.encryptUsingAES256(value);

  }


  paginationget(pageinfo: any) {
    this.paginationempty = true;
    this.pageno = pageinfo;

    if (this.route.snapshot.queryParamMap.get('search')) {
      // this.searchApi()
    } else {
      this.getapicallforcouponcode();
    }


  }

  // routerLink=""
  toreportedloops() {

    this.router.navigate(['/admin/loops/reported-loops']);
  }

  encryptUsingAES256(value: any) {
    let _key = CryptoJS.enc.Utf8.parse(this.tokenFromUI);
    let _iv = CryptoJS.enc.Utf8.parse(this.tokenFromUI);
    let encrypted = CryptoJS.AES.encrypt(
      JSON.stringify(value), _key, {
      keySize: 16,
      iv: _iv,
      mode: CryptoJS.mode.ECB,
      padding: CryptoJS.pad.Pkcs7
    });
    this.encrypted = encrypted.toString();
    this.encrypted = encodeURIComponent(this.encrypted);

    this.router.navigate(['detail-loop'], { queryParams: { loop_id: this.encrypted } });
  }

  // [routerLink]="['/admin/loops/detail-uploads/'+item.loop_id]"
  delete(item: any){
    this.api.forclientgettokenpublic(`/user/delete_pack`, {pack_id:item.pack_id}).then((res: any) => {
      this.getapicallforcouponcode()
    })
  }
  addcolor(item: any) {
    if (item == this.pageno) {
      return 'activated';
    } else {
      return ''
    }
  }

  followingredirect() {
    this.loop = environment.Likedlooploopkit[0].active = true;




    this.sound = environment.SOundprofile[0].active = false;
  }

   setimage(data: any) {
    if (data.pack_cover_img == "undefined") {
      if (data.profile_image_path == null) {
        return this.profile_image_pathofcomments;

      } else {
        return data.profile_image_path;
      }

    } else {

      return CONSTNAME.fILE_PATH + data.pack_cover_img;
    }
  }


redirecttodownloadspage(item: any){
    let dialogue = this.matDialog.open(DownloadComponent, { panelClass: 'share', disableClose: true, data: { pack_id:item.pack_id  }});
    dialogue.afterClosed().subscribe(result => {

    })
    dialogue.afterOpened().subscribe(() => {
      document.documentElement.classList.remove('cdk-global-scrollblock');
      document.documentElement.style.top = '0px';
    });
  }
  addredcolor(item: any) {
    // //////console.log("yes add")
    if (item.is_liked == 1) {

      return "red";
    }
  }


  async heartcolorred(value: any, detail: any, item: any) {

    let sendingData = {
      "loop_id": value,
    }

    // if (item.is_liked == 1) {
    //   item.is_liked = 0;
    // } else if (item.is_liked == 0) {
    //   item.is_liked = 1;
    // }




    let tokkenget = await this.common.getlocal(VARS.CLIENT_LOCAL_TOKEN_KEY);

    let headers: any;
    if (tokkenget) {
      // this.common.showLoader();
      this.api.postApiHit(`/user/like_dislike_upload`, sendingData).then((res: any) => {
        if (res.response.status == 1) {
          // let v: any = document.getElementById("red");
          // v.classList.add("red");
          // this.getapicallforcouponcode();
          this.common.hideLoader();
          this.toastr.success(res.response.msg, "", { positionClass: 'toast-bottom-right' });
          // if (item.user_id !== t) {

          // }
          if (item.is_liked == 1) {
            item.is_liked = 0;

            return ((item.number_of_likes) = item.number_of_likes - 1);
          } else if (item.is_liked == 0) {
            item.is_liked = 1;

            return ((item.number_of_likes) = item.number_of_likes + 1);
          }





          // this.getallinfoofcomments();
        } else if (res.response.status == 2) {
          let errorthroughapi = res.response.errors;
          let error = [];

          this.common.hideLoader();
          for (var i = 0; i < errorthroughapi.length; i++) {
            this.toastr.error(res.response.errors[i], "", { positionClass: 'toast-bottom-right' })
          }


        }
        else {
          let errorthroughapi = res.response.errors;
          let error = [];

          this.common.hideLoader();
          for (var i = 0; i < errorthroughapi.length; i++) {
            this.toastr.error(res.response.errors[i], "", { positionClass: 'toast-bottom-right' })
          }
        }
      }, async e => {

        this.common.hideLoader();
        this.toastr.error("Error in Api", "", { positionClass: 'toast-bottom-right' });
      });
    } else {
      this.opendialogueforverification();
      // this.router.navigate([CONSTNAME.REDIRECT_CLIENT_registerCLIENT]);
      // this.toastr.success("You must make an account to interact with uploaded media", "", { positionClass: 'toast-bottom-right' })
    }

  }
  openEdit(item:any){
    // //////console.log("item",item);
    let _key = CryptoJS.enc.Utf8.parse(this.tokenFromUI);
    let _iv = CryptoJS.enc.Utf8.parse(this.tokenFromUI);
    let encrypted = CryptoJS.AES.encrypt(
      JSON.stringify(item.pack_id), _key, {
      keySize: 16,
      iv: _iv,
      mode: CryptoJS.mode.ECB,
      padding: CryptoJS.pad.Pkcs7
    });
    this.encrypted = encrypted.toString();
    this.encrypted = encodeURIComponent(this.encrypted);
    // this.router.navigate(['upload-loop'], { queryParams: { loop_id: this.encrypted } });
    this.openDialog(item.pack_id)
  }
  openDialog(item:any): void {
    // //////console.log(item);
    
    let dialogue = this.matDialog.open(EditPackComponent, { panelClass: 'share',  data: { data: item.pack_id }});
    dialogue.afterClosed().subscribe(result => {
      this.getapicallforcouponcode()

    })
    dialogue.afterOpened().subscribe(() => {
      document.documentElement.classList.remove('cdk-global-scrollblock');
      document.documentElement.style.top = '0px';
    });
  }
  setimage1(data: any) {
    // //////console.log(data?.pack_cover_img);
    this.user = localStorage.getItem('client_data')
    const profile = JSON.parse(this.user)
    if (data?.pack_cover_img == "undefined"||data?.pack_cover_img == undefined) {
      if (profile.profile_image_path == null) {
        return this.profile_image_pathofcomments;

      } else {
        return CONSTNAME.fILE_PATH + profile.profile_image_path;
      }

    } else {

      return CONSTNAME.fILE_PATH + data.pack_cover_img;
    }
  }
  decryptUsingAES256() {

    // let _key = CryptoJS.enc.Utf8.parse(this.tokenFromUI);
    // let _iv = CryptoJS.enc.Utf8.parse(this.tokenFromUI);
    // this.decrypted = CryptoJS.AES.decrypt(
    //   this.encryptedagainremoveslashes, _key, {
    //   keySize: 16,
    //   iv: _iv,
    //   mode: CryptoJS.mode.ECB,
    //   padding: CryptoJS.pad.Pkcs7
    // }).toString(CryptoJS.enc.Utf8);

    // this.decrypted = this.decrypted.replace(/[""]/gi, '');

    this.getapicallforcouponcode();


  }


  opendialogueforverification(): void {
    let dialogue = this.matDialog.open(ConfirmDeleteComponent, { panelClass: 'forgot-password', disableClose: true, data: { pageValue: "detailpage" } });
    dialogue.afterClosed().subscribe(result => {



      if (result.data == true) {
        this.router.navigate([CONSTNAME.REDIRECT_CLIENT_registerCLIENT]);
        this.toastr.success("You must make an account to interact with uploaded media", "", { positionClass: 'toast-bottom-right' })
        // this.router.navigate(['/users/uploadloopstatus']);
      } else {

      }

    });
  }

  // addgreencolorin() {

  //     return "green";

  // }

  addcolorin() {

  }

  addgreencolor(item: any) {
    if (item.is_reposted == 1) {
      return "green";
    }
    // if (this.yesgreencolor) {
    //   return "green";
    // }
  }
  repostnumber(item: any) {
    return item.number_of_reposts
  }

  likesnumber(item: any) {
    return item.number_of_likes
  }

  async repost(value: any, item: any) {


    // //////console.log(item);
    let sendingData = {
      "loop_id": value,
    }

    let tokkenget = await this.common.getlocal(VARS.CLIENT_LOCAL_TOKEN_KEY);

    let headers: any;
    if (tokkenget) {


      // this.common.showLoader();
      this.api.postApiHit(`/user/repost_upload`, sendingData).then((res: any) => {
        if (res.response.status == 1) {
          // this.getapicallforcouponcode();

          this.common.hideLoader();
          this.toastr.success(res.response.msg, "", { positionClass: 'toast-bottom-right' });
          this.addcolorin();

          if (item.is_reposted == 1) {
            item.is_reposted = 0;
            //  //////console.log(item.number_of_reposts - 1) ;
            return ((item.number_of_reposts) = item.number_of_reposts - 1);
          } else if (item.is_reposted == 0) {
            item.is_reposted = 1;
            // //////console.log(item.number_of_reposts + 1) ;
            return ((item.number_of_reposts) = item.number_of_reposts + 1);
          }

          // this.getallinfoofcomments();
        } else if (res.response.status == 2) {
          let errorthroughapi = res.response.errors;
          let error = [];
          // this.cancel();
          this.common.hideLoader();

          for (var i = 0; i < errorthroughapi.length; i++) {
            this.toastr.error(res.response.errors[i], "", { positionClass: 'toast-bottom-right' })
          }
          this.router.navigate(['/users/uploadloopstatus']);

        }
        else {
          let errorthroughapi = res.response.errors;
          let error = [];
          // this.cancel();
          this.common.hideLoader();
          for (var i = 0; i < errorthroughapi.length; i++) {
            this.toastr.error(res.response.errors[i], "", { positionClass: 'toast-bottom-right' })
          }
        }
      }, async e => {

        this.common.hideLoader();
        this.toastr.error("Error in Api", "", { positionClass: 'toast-bottom-right' });
      });
    } else {
      this.opendialogueforverification();


    }
  }
  redirectto(item: any) {
    // //////console.log(item)
    this.router.navigate(['search-by-tag/'], { queryParams: { search_by: item } });
    // //////console.log(item)
    
  }
  async redirecttolooppagecomment(value: any) {
    let tokkenget = await this.common.getlocal(VARS.CLIENT_LOCAL_TOKEN_KEY);
    if (tokkenget) {
      this.redirecttolooppage(value)
    } else {
      this.opendialogueforverification();
    }

  }

  redirecttolooppage(value: any) {


    let _key = CryptoJS.enc.Utf8.parse(this.tokenFromUI);
    let _iv = CryptoJS.enc.Utf8.parse(this.tokenFromUI);
    let encrypted = CryptoJS.AES.encrypt(
      JSON.stringify(value), _key, {
      keySize: 16,
      iv: _iv,
      mode: CryptoJS.mode.ECB,
      padding: CryptoJS.pad.Pkcs7
    });
    this.encrypted = encrypted.toString();
    this.encrypted = encodeURIComponent(this.encrypted);


    this.router.navigate(['detail-loop'], { queryParams: { loop_id: this.encrypted } });



  }


  async apihitforstreaming(item: any, r: any, dura: any) {

    let tokkenget = await this.common.getlocal(VARS.CLIENT_LOCAL_TOKEN_KEY);

    let headers: any;
    if (tokkenget) {
      headers = { 'Authorization': `Bearer ` + tokkenget, 'My-Custom-Header': 'foobar', };

    }

    let sendingdata = {
      "audio_duration": dura,
      "loop_id": item.loop_id,
      "play_duration": r
    }
    this.http.post(APIURL.BASE_URL + `/user/stream_upload`, sendingdata, { headers }).subscribe(async (res: any) => {
      if (res.response.status == 1) {
        // this.toastr.success(res.response.msg, "", { positionClass: 'toast-bottom-right' });
      }

      else {
        let errorthroughapi = res.response.errors;
        let error = [];
        // this.cancel();
        this.common.hideLoader();
        for (var i = 0; i < errorthroughapi.length; i++) {
          this.toastr.error(res.response.errors[i], "", { positionClass: 'toast-bottom-right' })
        }
      }


    }, async e => {

      this.common.hideLoader();
      this.toastr.error("Error in Api", "", { positionClass: 'toast-bottom-right' });
    });
  }

  playAudio(item: any) {

    this.tableData.map((r: any) => {
      r.hidepausebutton = false;
    })
    this.tableData.map((r: any) => {
      if (r.audio) r.audio.pause()
    })




    if (!item.audio) {
      if (item.loop_type == 1) {
        if (item.tagged_loop_path !== null) {
          item.file = CONSTNAME.fILE_PATH + item.tagged_loop_path;
          this.durationofaudio = item.tagged_loop_duration;
        } else {
          item.file = CONSTNAME.fILE_PATH + item.loop_path;
          this.durationofaudio = item.loop_duration;
        }
      } else {
        item.file = CONSTNAME.fILE_PATH + item.tagged_loop_path;
        this.durationofaudio = item.tagged_loop_duration;
      }
      // if (!item.duration) {
      //   let sound: any;
      //   sound = await new Promise((resolve, reject) => {
      //     new p5.SoundFile(item.file, (s: any) => resolve(s), (err: any) => reject(err));
      //   });
      // }
      item.duration = this.durationofaudio;
      item.audio = new Audio(item.file);
      item.audio.play();

      if (item.duration >= 15) {
        this.above15second = true;
        this.apihitforstream = true

      }
      else if (item.duration < 15 && item.duration >= 5) {

        this.apihitforstream = true
        this.above5secondlessthan15 = true;
      } else if (item.duration > 1 && item.duration <= 5) {

        this.apihitforstream = true
        this.above1secondlessthan5 = true;
      }
    } else {
      item.audio.play();
    }





    item.hidepausebutton = true;
    let r: any;
    this.id = setInterval(() => {
      r = item.audio.currentTime;
      if (r === item.duration || r > item.duration) {

        setTimeout(() => {

        }, 1000);
      }

      let dura: any = item.duration;
      if (item.duration >= 15) {
        if (r > 15) {
          if (this.apihitforstream == true) {
            this.apihitforstreaming(item, r, dura);
            clearInterval(this.id);
            this.apihitforstream = false;

          }

        }
      } else if (item.duration < 15 && item.duration >= 5) {

        if (r > 5) {

          if (this.apihitforstream == true) {
            this.apihitforstreaming(item, r, dura);
            clearInterval(this.id);
            this.apihitforstream = false;

          }
        }


      } else if (item.duration > 1 && item.duration <= 5) {
        if (r >= 1) {
          if (this.apihitforstream == true) {
            this.apihitforstreaming(item, r, dura);
            clearInterval(this.id);
            this.apihitforstream = false;

          }

        }
      }
    }, 200);

  }

  stopAudio(item: any = null) {
    if (item) {
      if (item.audio) {
        item.audio.pause()
      }
      clearInterval(this.id);

      this.tableData.map((r: any) => {
        r.hidepausebutton = false;
      })

    } else {
      this.tableData.map((r: any) => {
        if (r.audio) {
          r.audio.pause()
        }
        r.hidepausebutton = false;
      })

    }


  }

  onRegionChange(value: any = null) {
    // //////console.log(value);
  }
  onTrackLoaded(v: any = null) {
    // //////console.log("track");
    // //////console.log(v);

  }
  onTrackRendered(v: any = null) {
    // //////console.log("trackren");
    // //////console.log(v);
  }

  audio_duration: any;
  play_duration: any;
  onDurationChange(value: any = null) {
    if (value == 0) {

    } else {
      this.audio_duration = value;
      // this.totaltime = (Math.round( value* 100) / 100).toFixed(2);

    }
    // //////console.log(this.audio_duration);

    if (this.durationofaudio >= 15) {
      this.above15second = true;
      // //////console.log("above 15 sec");
    }
    else if (this.durationofaudio < 15 && value >= 5) {
      // //////console.log("between 5 and 15");
      this.above5secondlessthan15 = true;
    } else if (this.durationofaudio > 1 && value <= 5) {
      // //////console.log("between 1 and 5");
      this.above1secondlessthan5 = true;
    }
    // //////console.log(this.durationofaudio);
    // if (value >= 15) {
    //   this.above15second = true;
    //   // //////console.log("above 15 sec");
    // }
    // else if (value < 15 && value >= 5) {
    //   // //////console.log("between 5 and 15");
    //   this.above5secondlessthan15 = true;
    // } else if (value > 1 && value <= 5) {
    //   // //////console.log("between 1 and 5");
    //   this.above1secondlessthan5 = true;
    // }
  }



  onTimeUpdate(v: any = null) {

    if (v.progress) {

      // //////console.log("time", v.time);
      // this.currenttime = (Math.round( v.time* 100) / 100).toFixed(2); 
      if (v.progress >= 100) {
        // //////console.log("complete")
        // this.hidepausebutton = true;
        // this.hideplaybutton = false;
        setTimeout(() => {
          this.apihitforstream = true;
          // this.currenttime = "0.00";
        }, 1000);
        setTimeout(() => {

          // this.currenttime = "0.00";
        }, 10);


      }
      if (this.above15second) {
        if (v.time >= 15) {

          // this.apihitforstream = true;
          // alert("15 second is passed");
          if (this.apihitforstream == true) {
            // //////console.log(v.time);
            this.play_duration = v.time
            // //////console.log("api will hit");
            // this.apihitforstreaming();
            this.apihitforstream = false;
          }

        }
      } else if (this.above5secondlessthan15) {
        if (v.time >= 5) {
          if (this.apihitforstream == true) {
            // //////console.log(v.time);
            this.play_duration = v.time
            // //////console.log("api will hit");
            // this.apihitforstreaming();
            this.apihitforstream = false;
          }
        }

      } else if (this.above1secondlessthan5) {
        if (v.time >= 1) {
          if (this.apihitforstream == true) {

            this.play_duration = v.time

            // this.apihitforstreaming();
            this.apihitforstream = false;
          }
        }

      }




    }


  }


  onPaused(v: any = null) {
    // //////console.log("pause");
  }
  openshare(item: any):void{
    let _key = CryptoJS.enc.Utf8.parse(this.tokenFromUI);
    let _iv = CryptoJS.enc.Utf8.parse(this.tokenFromUI);
    let encrypted = CryptoJS.AES.encrypt(
      JSON.stringify(item.pack_id ), _key, {
      keySize: 16,
      iv: _iv,
      mode: CryptoJS.mode.ECB,
      padding: CryptoJS.pad.Pkcs7
    });
    this.encrypted = encrypted.toString();
    this.encrypted = encodeURIComponent(this.encrypted);
    let dialogue = this.matDialog.open(ShareComponent, { panelClass: 'share', disableClose: true, data: { link:this.encrypted,data:"pack"  }});
    dialogue.afterClosed().subscribe(result => {

    })
    dialogue.afterOpened().subscribe(() => {
      document.documentElement.classList.remove('cdk-global-scrollblock');
      document.documentElement.style.top = '0px';
    });
  }
  looplikeon() {


    this.loop = environment.Likedlooploopkit[0].active = false;
    this.sound = environment.SOundprofile[0].active = true;
  }
  edit(item: any){
    let _key = CryptoJS.enc.Utf8.parse(this.tokenFromUI);
    let _iv = CryptoJS.enc.Utf8.parse(this.tokenFromUI);
    let encrypted = CryptoJS.AES.encrypt(
      JSON.stringify(item.pack_id), _key, {
      keySize: 16,
      iv: _iv,
      mode: CryptoJS.mode.ECB,
      padding: CryptoJS.pad.Pkcs7
    });
    this.encrypted = encrypted.toString();
    this.encrypted = encodeURIComponent(this.encrypted);
    // //////console.log(this.encrypted);
    
    // //////console.log(['user/'], { queryParams: { user_id: item.user_id } });
    // this.router.navigate(['create-pack'], { queryParams: { pack_id: this.encrypted } });
    this.openDialog(item)

  }
  handleCreate(){
    const count = localStorage.getItem('loop_count')
   
    if(count!=='0'){
      this.router.navigate(['/create-pack']);

    }else{
      this.toastr.error("You must upload loops before creating a pack", "", { positionClass: 'toast-bottom-right' });
    }
  }  
  redirecttopackpage(item: any) {
    // ////////console.log(item);
    
    let _key = CryptoJS.enc.Utf8.parse(this.tokenFromUI);
    let _iv = CryptoJS.enc.Utf8.parse(this.tokenFromUI);
    let encrypted = CryptoJS.AES.encrypt(
      JSON.stringify(item.pack_id), _key, {
      keySize: 16,
      iv: _iv,
      mode: CryptoJS.mode.ECB,
      padding: CryptoJS.pad.Pkcs7
    });
    this.encrypted = encrypted.toString();
    this.encrypted = encodeURIComponent(this.encrypted);
    // ////////console.log(this.encrypted);
    
    // ////////console.log(['user/'], { queryParams: { user_id: item.user_id } });
    this.router.navigate(['detail-pack'], { queryParams: { pack_id: this.encrypted } });
  }
  downloadzip(item:any): void {
    // this.common.showLoader()
    this.user = localStorage.getItem("client_data");
    const user_id = JSON.parse(this.user);  
    this.api.forclientgettoken('/user/save_free_pack',{user_id:user_id.user_type_id,pack_id:item.pack_id}).then((res:any)=>{
      if(res.response.status){
    var sendingdata = {
      pack_id : item.pack_id
    }
    this.api.forclientgettokenpublic(`/user/pack_detail`, sendingdata).then(async (res: any) => {
      // this.api.postApiHit(, sendingdata).then((res: any) => {
    let wavFiles:any=[]
    res.response.data.loop_detials.map((i: any,index:any)=>{
    // ////////console.log(i,index);
    wavFiles.push({ name : i.title,data : CONSTNAME.fILE_PATH + i.loop_path})
    })
    // ////////console.log(wavFiles);
    this.zipService.addSongToZip(wavFiles.name, wavFiles.data);


    this.zipService.downloadZip(wavFiles,res.response.data.pack_name);
   })
    }else{
      this.toastr.error(res.response.errors, "", { positionClass: 'toast-bottom-right' })
    }
  })
}
  redirecttoloopdetailpage(items: any) {
    let _key = CryptoJS.enc.Utf8.parse(this.tokenFromUI);
    let _iv = CryptoJS.enc.Utf8.parse(this.tokenFromUI);
    let encrypted = CryptoJS.AES.encrypt(
      JSON.stringify(items.loop_id), _key, {
      keySize: 16,
      iv: _iv,
      mode: CryptoJS.mode.ECB,
      padding: CryptoJS.pad.Pkcs7
    });
    this.encrypted = encrypted.toString();
    this.encrypted = encodeURIComponent(this.encrypted);
    // ////////console.log(value);
    // ////////console.log(this.encrypted);
    // this.router.navigate(['/detail-loop'], { queryParams: { loop_id: value } });

    this.router.navigate(['detail-loop'], { queryParams: { loop_id: this.encrypted } });
  }

  sendingstrtag1: any
  sendingstrtag2: any
  sendingstrtag3: any

  redirectto1(items: any) {
    let a = items?.tags
    let strtag: any = a.split('\n ');
    this.sendingstrtag1 = strtag[0];
    // ////////console.log(item)
    this.router.navigate(['search-by-tag/'], { queryParams: { search_by: this.sendingstrtag1 } });
    // ////////console.log(this.sendingstrtag1)

  }
  redirectto2(items: any) {
    let a = items.tags
    let strtag: any = a.split('\n ');
    this.sendingstrtag2 = strtag[1];
    // ////////console.log(item)
    this.router.navigate(['search-by-tag/'], { queryParams: { search_by: this.sendingstrtag2 } });
    // ////////console.log(item)
    // ////////console.log(this.sendingstrtag2)

  }
  redirectto3(items: any) {
    let a = items.tags
    let strtag: any = a.split('\n ');
    this.sendingstrtag3 = strtag[2];
    // ////////console.log(item)
    this.router.navigate(['search-by-tag/'], { queryParams: { search_by: this.sendingstrtag3 } });
    // //////console.log(item)
    // //////console.log(this.sendingstrtag3)

  }


  openmodal(item: any) {
    let valuebtoa = btoa(item.loop_id)
    // //////console.log(item)
    let idfromloop: any = item.user_id
    this.common.getDataInCookie(VARS.Login_Client_data).then((res: any) => {
      if (!res) {
        this.getUserDetail();
      }
      let resa = JSON.parse(res)
      if (resa) {
        let idfromwebsite: any = resa.user_type_id
        // //////console.log(idfromwebsite, idfromloop)

        if (idfromloop === idfromwebsite) {
          // //////console.log("gaya")
          // //////console.log("download")
          this.downloadloop(item)
          // this.apihitelsepart(item);
        } else {
          // //////console.log(this.urlpass + `&loop_id=${valuebtoa}`);
          this.urlpass = this.urlpass + `&loop_id=${valuebtoa}`
          this.apihitelsepart(item);
          // //////console.log(" not download")

        }
      };
    })
  }


  //   downloadloop(res: any) {
  //     //////console.log(res)
  //     if (res.loop_type == 1) {

  //       this.src = CONSTNAME.fILE_PATH + res.loop_path;
  //       // //////console.log(CONSTNAME.fILE_PATH + res.loop_path)
  //  this.fileService.downloadFile().subscribe((response: any) => {
  //       window.location.href = CONSTNAME.fILE_PATH +  res.loop_path;
  //     }), (error: any) => //////console.log('Error downloading the file'),
  //       () => //console.info('File downloaded successfully');



  //     } else {
  //       this.src = CONSTNAME.fILE_PATH + res.tagged_loop_path;
  //       // //////console.log(CONSTNAME.fILE_PATH + res.tagged_loop_path)
  //       this.fileService.downloadFile().subscribe((response: any) => {
  //         window.location.href = CONSTNAME.fILE_PATH + res.tagged_loop_path;
  //       }), (error: any) => //////console.log('Error downloading the file'),
  //         () => //console.info('File downloaded successfully');

  //     }


  //   }
  // downloadloop(res: any) {
  //   //////console.log(res)
  //   if (res.loop_type == 1) {
  //     let file_name = CONSTNAME.fILE_PATH + res.loop_path
  //     window.open(file_name, '_blank');
  //   } else {
  //     let file_name = CONSTNAME.fILE_PATH + res.tagged_loop_path
  //     window.open(file_name, '_blank');
  //   }
  // }




  getUserDetail() {
    // this.common.showLoader();
    this.api.postApiHit(APIURL.user_get_logged_in_user_details, {}, VARS.ClIENT_TYPE).then((res: any) => {
      this.common.hideLoader();
      if (res.response.status) {
        this.common.setDataInCookie(VARS.Login_Client_data, res.response.data);
        this.common.getDataInCookie(VARS.Login_Client_data).then((res: any) => {
          let resa = JSON.parse(res)
          this.api.geturltoredirectexceptplandetail(resa.is_on_boarding_complete, resa.steps_completed);
        })
      }
    })
  }


  apihitelsepart(item: any) {
    // //////console.log(item)
    this.common.showLoader();
    let sendingdata = {
      "loop_id": item.loop_id
    }
    this.api.postApiHit(`/user/get_upload_detail`, sendingdata).then((res: any) => {
      if (res.response.status == 1) {
        // //////console.log(res);

        if (res.response.data.loop_details[0].is_already_downloaded == 0 && res.response.data.loop_details[0].is_already_paid == 0) {

          if (res.response.data.loop_details[0].selling_preference == 1) {
            this.download("free", item);
            // //////console.log("Selling")
            this.common.hideLoader();
            // //////console.log("free")
          } else {
            if (res.response.data.loop_details[0].loop_price == 0) {
              this.download("free", item);
              this.common.hideLoader();
              // //////console.log("free")
            } else {
              this.download("premium", item);
              this.common.hideLoader();
              // //////console.log("premium")
            }


          }
        } else if (res.response.data.loop_details[0].is_already_downloaded == 0 && res.response.data.loop_details[0].is_already_paid == 1) {
          this.common.hideLoader();
        } else if (res.response.data.loop_details[0].is_already_downloaded == 1 && res.response.data.loop_details[0].is_already_paid == 1) {
          // //////console.log("yes");
          this.common.hideLoader();
          this.downloadloop(item)
        } else if (res.response.data.loop_details[0].is_already_downloaded == 1 && res.response.data.loop_details[0].is_already_paid == 0) {
          this.downloadloop(item)
          this.common.hideLoader();
        }
        this.common.hideLoader();
      } else if (res.response.status == 2) {
        let errorthroughapi = res.response.errors;
        let error = [];
        this.common.hideLoader();
        for (var i = 0; i < errorthroughapi.length; i++) {
          this.toastr.error(res.response.errors[i], "", { positionClass: 'toast-bottom-right' })
        }
      }
      else if (res.response.status == 4) {
        // //////console.log("private account")
        // let errorthroughapi = res.response.errors;
        // let error = [];
        // this.common.hideLoader();
        // for (var i = 0; i < errorthroughapi.length; i++) {
        this.toastr.error("This account is Private, Follow to download the loop", "", { positionClass: 'toast-bottom-right' })
        // }
      }
      else {
        let errorthroughapi = res.response.errors;
        let error = [];
        this.common.hideLoader();
        for (var i = 0; i < errorthroughapi.length; i++) {
          this.toastr.error(res.response.errors[i], "", { positionClass: 'toast-bottom-right' })
        }
      }
    }, async e => {
      this.common.hideLoader();
      this.toastr.error("Error in Api", "", { positionClass: 'toast-bottom-right' });
    });
  }

  show(item: any) {
    // let checktoken = false
    // let idfromwebsite: any
    // //////console.log("gaya")
    // //////console.log(item.user_id)
    // //////console.log(item.paypal_merchant_account_linked == 1);
    if (item.paypal_merchant_account_linked == 1) {
      // //////console.log("gaya")
      return true
    }else if (item.stripe_merchant_account_linked == 1) {
      return true
    } else if (item.loop_price == 0) {
      return true
    } else if (this.checktoken) {
      if (this.idfromwebsite == item.user_id) {
        return true
      } else {
        return false
      }

    } else {
      return false
    }


  }

  downloadlooppaid(res: any, item: any) {
    // //////console.log(res,item)
    if (res) {
      this.encrypted = btoa(item)
      this.encrypted2 = btoa(res)
      this.router.navigate(['/invoice'], { queryParams: { loop_id: this.encrypted, payment_id: this.encrypted2, type: 2 } });
    }
    this.common.hideLoader();
    window.location.href = `${APIURL.BASE_URL}/user/download_loop?loop_id=${item}`;

  }
  downloadloop(res: any) {





    this.common.hideLoader();
    window.location.href = `${APIURL.BASE_URL}/user/download_loop?loop_id=${res.loop_id}`;

  }


  download(value: any, item: any): void {
    // //////console.log(item)
    let dialogue = this.matDialog.open(DownloadmodalComponent, { panelClass: 'forgot-password', disableClose: true, data: { pagevalue: value, allitems: item, url: this.urlpass } });
    dialogue.afterClosed().subscribe(result => {

      if (result.data == "downloadpre") {
        this.downloadlooppaid(result.data2, item);
        // //////console.log("download")
      } else if (result.data == "downloadfree") {
        // //////console.log("gaya")
        this.downloadloop(item);
      } else {

      }

    });
  }

  apihitforpaymentapi() {
    // //////console.log(this.loopidfromurl)
    this.common.showLoader();
    // this.btndis = true;
    let sendingdata = {
      "loop_id": this.loopidfromurl,
      // "success_referrer": this.urlfromback,
      // "cancel_referrer": this.cancelurl
    }


    this.api.postApiHit(`/user/verify_stripe_payment`, sendingdata).then((res: any) => {
      if (res.response.status == 1) {
        this.common.hideLoader();

        // //////console.log(res.response.data)
        // //////console.log(this.loopidfromurl);

        this.downloadlooppaid(res.response.data, this.loopidfromurl);

        // //////console.log(res.response.msg)
        // this.matDialog.closeAll()
        // this.toastr.success(res.response.msg, "", { positionClass: 'toast-bottom-right' })
        // this.dialogRef.close({ data: "downloadpre", data2: res.response.data });


        this.common.hideLoader();

      }
      else if (res.response.status == 2) {
        let errorthroughapi = res.response.errors;
        let error = [];

        this.common.hideLoader();
        for (var i = 0; i < errorthroughapi.length; i++) {
          this.toastr.error(res.response.msg, "", { positionClass: 'toast-bottom-right' })
        }


      }
      else if (res.response.status == 3) {
        let errorthroughapi = res.response.errors;
        let error = [];
        this.common.hideLoader();
        // //////console.log("in going")
        for (var i = 0; i < errorthroughapi.length; i++) {
          let jk = error.push(errorthroughapi);
          // this.toastr.error(res.response.errors[i], "", { positionClass: 'toast-bottom-right' })
        }

        this.common.clearAllLocalAndSession();
        // localStorage.removeItem("clientintoken");
        this.router.navigate(['/login']);


      }
      else if (res.response.status == 4) {
        let errorthroughapi = res.response.errors;
        let error = [];

        this.common.hideLoader();
        for (var i = 0; i < errorthroughapi.length; i++) {
          this.toastr.error(res.response.msg, "", { positionClass: 'toast-bottom-right' })
        }


      }
      else {
        let errorthroughapi = res.response.errors;
        let error = [];
        // this.cancel();
        this.common.hideLoader();
        for (var i = 0; i < errorthroughapi.length; i++) {
          this.toastr.error(res.response.errors[i], "", { positionClass: 'toast-bottom-right' })
        }
      }
    }, async e => {

      this.common.hideLoader();
      this.toastr.error("Error in Api", "", { positionClass: 'toast-bottom-right' });
    });
  }

}



