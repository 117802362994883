
import { environment } from 'src/app/sevicesall/alldata';
import { DatePipe } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Component, ElementRef, EventEmitter, HostListener, Input, OnInit, Output } from '@angular/core';
import { ActivatedRoute, NavigationEnd, NavigationStart, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { ApiServiceService } from 'src/app/allServices/api-service.service';
import { commonservice } from 'src/app/allServices/commonservice';
import { APIURL, CONSTNAME, VARS } from 'src/app/allServices/constant.service';
import * as CryptoJS from 'crypto-js';
import * as moment from 'moment';
import { ConfirmDeleteComponent } from 'src/app/CLIENT_PAGES/MODAL/confirm-delete/confirm-delete.component';
import { MatDialog } from '@angular/material/dialog';
import { DownloadmodalComponent } from '../../MODAL/downloadmodal/downloadmodal.component';
import { ShareComponent } from '../../MODAL/share/share.component';
import { EditLoopComponent } from '../../MODAL/edit-loop/edit-loop.component';
import { DownloadComponent } from '../../MODAL/downloads/downloads.component';
import { Subscription } from 'rxjs';
import { ZipService } from 'src/app/allServices/zip.service';

@Component({
  selector: 'app-sounds',
  templateUrl: './sounds.component.html',
  styleUrls: ['./sounds.component.css']
})
export class SoundsComponent implements OnInit {
  @Input() Idpass: any;
  @Input() check: any;
  @Input() profileid: any;

  userMenuforfollow = environment.followerspage;
  followers = environment.followerspage[0].active;
  following = environment.followerspage[1].active;

  currentwaveform:any;
  userMenuforlike = environment.Likedlooploopkit;
  loop = environment.Likedlooploopkit[0].active;


  userMenuforSoundmenu = environment.SOundprofile;
  sound = environment.SOundprofile[0].active;


  tokenFromUI: string = "0123456789123456";
  encrypted: any = "";
  decrypted: string;
  request: string;
  responce: string;
  pagenolist: any;
  tableData: any[] = [];
  pageno: any = 1;
  rowsize: any = 10;
  pagercount: any = [];
  validationforsearch: any = '';
  paginationempty = false;
  noresult = false;
  sortsign: any = "sort";
  searchwordget: any;
  getpageno: any;
  total: any;
  record: any;
  hidepagination = true;
  norecordfound: any;
  sorttype = "";
  sorton = "";
  imageofcomment: any;
  profile_image_pathofcomments = "assets/img/artwork.jpg";
  value: any;
  encryptedagainremoveslashes: string;
  tablefortagsdisplay1: any;
  tablefortagsdisplay2: any;
  tablefortagsdisplay3: any;
  time: string;
  addred: boolean;
  addgreen: boolean;
  durationofaudio: any;
  above15second = false;
  apihitforstream = true;
  above5secondlessthan15 = false;
  above1secondlessthan5 = false;
  id: any;
  nocomments: boolean;
  src: any;
  pagenocount: any;
  fileService: any;
  checktoken: boolean;
  idfromwebsite: any;
  encrypted2: string;
  totalrecordsinarray: any;
  array: any = [];
  urlpass: string;
  urlstatusmessage: string;
  valuefromurl: any;

  loopidurl: any;
  loopidfromurl: any;
  advancesearch: any;
  active: boolean;
  valueofmain_key: string;
  valueofsub_key: string;
  bpmrange: boolean;
  hideexactbpm: boolean;
  // checks: string;
  totalRecords: any;
  showAudioPlayer: boolean;
  search: any;
  player: any = 0;
  disable: boolean=false;
  completerender: boolean = false;
  sliderValue : any = 0 
  mute: boolean;
  steps: any=0.01;
  volumeValue: number = 1; 
  userData: any;
  res: any;
  private navigationSubscription: Subscription;
  // messageEvent: any;
  @HostListener("window:keydown", ["$event"])
  handleKeyboardEvent(event: KeyboardEvent) {
    //console.warn("hii", event, this.player);
  
    if (event.key === "ArrowUp") {
      event.preventDefault();
      if (this.player >= 0) {
        this.previous();
      }
    } else if (event.key === "ArrowDown") {
      event.preventDefault();

      // if(this.player<=this.tableData.length){
      this.next(this.player);
      // }else{
      //   this.player = 0
      //   this.next()
      // }
    }  else if (event.key === " ") {
      const searchInput: HTMLInputElement = this.elementRef.nativeElement.querySelector('.form-control');
      const isFocused = (searchInput === document.activeElement);
            if(!isFocused){
              event.preventDefault();
              if (this.tableData[this.player]?.hidepausebutton) {
                this.stopAudio(this.tableData[this.player], this.player);
              } else {
                this.playAudio(this.tableData[this.player], this.player, event);
                // }
              }
          }
      // if(!this.player){
      // this.playAudio(this.tableData[0],0);
      // }else{
    
    }
  }
  constructor(private router: Router, private http: HttpClient,
    private zipService: ZipService,
     private toastr: ToastrService, private route: ActivatedRoute, private datePipe: DatePipe, public common: commonservice, public api: ApiServiceService, private matDialog: MatDialog,
    private elementRef: ElementRef

  ) {
    //  ////console.log(this.router.url,"sounds")
    this.navigationSubscription = this.router.events.subscribe((event) => {
      if (event instanceof NavigationStart) {
        // Navigation is about to start, handle cleanup logic
        this.handleCleanup();
      } else if (event instanceof NavigationEnd) {
        // Navigation has ended, perform any necessary actions
        // (e.g., trigger the click event)
        this.handleNavigationEnd();
      }
    });
  }
  waveformLoaded: boolean[] = [];

  onWaveformLoaded(index: number) {
    this.waveformLoaded[index] = true;
  }
  private handleCleanup() {
    // Perform cleanup logic before navigation
    // (e.g., pausing audio or video playback)
    const element = this.elementRef.nativeElement.querySelector("#player-row-" +  this.player + " .play_button.pause");
    if (element) {
      element.click();
    }
    // setTimeout(() => {
    const username = this.router.url
    // ////console.log(username);
      
      // if(this.router.url.indexOf('user/sounds') > -1)
      // location.reload()
      // }, 2000);
    // ////console.log('Performing cleanup before navigation');
  }

  private handleNavigationEnd() {
    // Navigation has ended, perform necessary actions
    // (e.g., trigger the click event)
    // ////console.log('Navigation has ended, triggering click event');

    const element = this.elementRef.nativeElement.querySelector("#player-row-" +  this.player + " .play_button.pause");
    if (element) {
      element.click();
    }
  }


  fontSize(item:any){
    if (item?.title?.length <= 50 && item?.title?.length >= 30) {
      return 'long'
    } else if (item?.title?.length <= 30 && item?.title?.length >= 10) {
      return 'medium'
    } else {
      return 'small'
    }
  }

  ngOnInit(): void {
    this.valuefromurl = this.route.snapshot.queryParamMap.get('msg');
    // ////console.log(this.valuefromurl,"this.valuefromurl")
    const username = this.router.url.slice(14,14+50)
      // ////console.log('Username:', username);
      this.value = username
    this.urlstatusmessage= atob(this.valuefromurl)

    if (this.urlstatusmessage == "success") {
      this.loopidurl = this.route.snapshot.queryParamMap.get('loop_id');
      this.loopidfromurl = atob(this.loopidurl)
      // setTimeout(() => {
        // this.apihitforpaymentapi();
      // }, 2000);
    } else {
      // ////console.log("no")
    }

    // this.pageno = this.route.snapshot.queryParamMap.get('pageno')
    // if (this.pageno) {
    //   this.pageno = Number(this.pageno)
    // } else {
      // this.pageno = 1
      // ////console.log("yess gotchha")
    // }
    // ////console.log(this.pageno)

    // this.urlpass = APIURL.BASE_URLforredirect + this.router.url
    // ////console.log(this.urlpass,"this.urlpass")
    // this.value = this.route.snapshot.queryParamMap.get('user_id');
    // ////console.log(this.value,"this.value")
    this.encryptedagainremoveslashes = decodeURIComponent(this.value);
    this.decryptUsingAES256();
    this.common.getDataInCookie(VARS.Login_Client_data).then((res: any) => {
      if (!res) {
        this.checktoken = false
        // this.getUserDetail();
      } else {
        // ////console.log("in")
        this.checktoken = true;
        let resa = JSON.parse(res)
        this.idfromwebsite = resa.user_type_id
        // ////console.log(this.idfromwebsite)
      }
    })
  }
 




 
  ngOnDestroy() {
    // ////console.log("des");
    const element = document.querySelectorAll(
      "#player-row-" + this.player + " .play_button.pause"
    )[0];

    if (element && "click" in element) {
      (element as HTMLElement).click();
    }
      
    // let user = localStorage.getItem('currentuser')
    // // this.route.params.subscribe(params => {
    //   // Extract the 'username' parameter from the route
    //   var username
    // if (this.router.url.indexOf('user/packs') > -1) {
     
    //   }else{
    //     if(this.router.url.indexOf('user/sounds') > -1){
    //     username = this.router.url.slice(14,14+50)
    //     if(username != user){
    //       setTimeout(() => {
    //           location.reload()
    //         }, 5);}
    //   }
    // }
    // this.stopAudio(this.tableData[this.player], this.player);
  }
//   ngAfterViewInit() {
// //////console.log(this.check);

//     // this.messageEvent.emit()
//   }
  openEdit(item:any){
    // //////console.log("item",item);
    let _key = CryptoJS.enc.Utf8.parse(this.tokenFromUI);
    let _iv = CryptoJS.enc.Utf8.parse(this.tokenFromUI);
    let encrypted = CryptoJS.AES.encrypt(
      JSON.stringify(item.loop_id), _key, {
      keySize: 16,
      iv: _iv,
      mode: CryptoJS.mode.ECB,
      padding: CryptoJS.pad.Pkcs7
    });
    this.encrypted = encrypted.toString();
    this.encrypted = encodeURIComponent(this.encrypted);
    // this.router.navigate(['upload-loop'], { queryParams: { loop_id: this.encrypted } });
    this.openDialog(item.loop_id)
  }
  /* Start of Player Functions */
  next(i:any) {
    if (this.tableData.length - 1 == i) {
      this.disable = false;
      this.tableData[i].hidepausebutton = false;
      this.tableData[i].audio.currentTime = 0;
      this.sliderValue = 0;
      //  this.player = this.player + 1
      // this.playAudio(this.tableData[0], 0, event);
    } else {
      // this.disable = false;
      // this.tableData[i].audio.currentTime = 0;
      // this.sliderValue = 0;
      // this.stopAudio(this.tableData[i], i);
      i = i + 1;
      var seek_btn = document.querySelectorAll(
        "#player-row-" + i + " .play_button.vol"
      )[0];
      seek_btn.setAttribute("data-seek-vol", String(this.volumeValue));
      (seek_btn as HTMLElement).click();
      setTimeout(() => {
      this.playAudio(this.tableData[i], i, event);
        
      }, 1000);
      if(this.mute===true){
        var seek_btn = document.querySelectorAll(
          "#player-row-" + i + " .play_button.mute"
        )[0];
        // seek_btn.setAttribute("data-seek-time", String());
        (seek_btn as HTMLElement).click();
      }
    }
  }
  previous() {
    ////////console.log(this.tableData.length - 1, this.player);

    if (this.player == 0) {
      this.disable = true;
    } else {
      this.disable = false;
      this.sliderValue = 0;
      this.tableData[this.player].audio.currentTime = 0;
      this.stopAudio(this.tableData[this.player], this.player);
      this.player = this.player - 1;
      this.playAudio(this.tableData[this.player], this.player, event);
    }
  }
  onInput(event: any): void {
    // ////console.log('event',event.target.value);
//     // this.stopAudio(this.tableData[this.player],this.player)
    var seek_time = parseFloat(event.target.value).toFixed(2);
    // ////console.log(seek_time);
    
//     this.tableData[this.player].audio.currentTime = seek_time;
//     ////console.log("sli",this.tableData[this.player].audio.currentTime);
//     // this.playAudio(this.tableData[this.player],this.player,event)
    
this.sliderValue = seek_time
// ////console.log("sli",this.sliderValue);

    var seek_btn = document.querySelectorAll(
      "#player-row-" + this.player + " .play_button.seek"
    )[0];
    seek_btn.setAttribute("data-seek-time", String(seek_time));
    (seek_btn as HTMLElement).click();
  }


  onVolumeInput(event: any): void {
    // //////console.log(event.target.value);
    var seek_time = parseFloat(event.target.value).toFixed(2);
    this.tableData[this.player].audio.volume = parseFloat(event.target.value);
    this.volumeValue =  this.tableData[this.player].audio.volume
    var seek_btn = document.querySelectorAll(
      "#player-row-" + this.player + " .play_button.vol"
    )[0];
    seek_btn.setAttribute("data-seek-vol", String(seek_time));
    (seek_btn as HTMLElement).click();
    if(this.volumeValue>0){
      this.Unmute()
    }else if(this.volumeValue==0){
      this.Mute()
    }
  }
  Mute() {
    this.volumeValue = 0
    this.mute = true;
    this.tableData[this.player].audio.muted = true;
    var seek_btn = document.querySelectorAll(
      "#player-row-" + this.player + " .play_button.mute"
    )[0];
    // seek_btn.setAttribute("data-seek-time", String());
    (seek_btn as HTMLElement).click();
  }
  Unmute() {
    this.volumeValue = this.tableData[this.player].audio.volume
    this.mute = false;
    this.tableData[this.player].audio.muted = false;
    var seek_btn = document.querySelectorAll(
      "#player-row-" + this.player + " .play_button.unmute"
    )[0];
    // seek_btn.setAttribute("data-seek-time", String());
    (seek_btn as HTMLElement).click();
  }


  forward() {
    // this.steps = this.steps + 0.10;
    this.tableData[this.player].audio.currentTime += 2;
    this.sliderValue =  this.tableData[this.player].audio.currentTime
    var seek_btn = document.querySelectorAll(
      "#player-row-" + this.player + " .play_button.forward"
    )[0];
    // seek_btn.setAttribute("data-seek-time", String());
    (seek_btn as HTMLElement).click();
    ////////console.log(this.steps);
  }
  backward() {
    // this.steps = this.steps - 0.01;
    this.tableData[this.player].audio.currentTime -= 2;
    this.sliderValue =  this.tableData[this.player].audio.currentTime
    var seek_btn = document.querySelectorAll(
      "#player-row-" + this.player + " .play_button.backward"
    )[0];
    // seek_btn.setAttribute("data-seek-time", String(seek_time));
    (seek_btn as HTMLElement).click();
  }

  playAudio(item: any, index: any, event: any) {
    // ////console.log(item,index);
    const divsWithClass = document.querySelectorAll('div.list');

    divsWithClass.forEach(div=>{
      div.classList.add('ply');
    })
    // this.common.showLoader();
   
    this.showAudioPlayer = true;
  
    // list.forEach((menu: Element) => {
    //   // //////////console.log(menu);
    //   menu.classList.remove('ply');
    // })

    // this.waveform = item
    this.player = index;
    var play_time: any =0.00;
    // document.querySelectorAll("#player-row-"+index+ " .play_button.play")[0].click();
   

    this.tableData.map((r: any) => {
      r.hidepausebutton = false;
    });
    this.tableData.map((r: any,i:any) => {
      if (r.audio) {
        ////////console.log('hii');
        
        r.audio.pause();
        const element = document.querySelectorAll(
          "#player-row-" + i + " .play_button.pause"
        )[0];
    
        if (element && "click" in element) {
          (element as HTMLElement).click();
        }
      }
    });
    const element = document.querySelectorAll(
      "#player-row-" + index + " .play_button.play"
    )[0];

    // ////console.log(element);
    
    play_time = document
      .querySelectorAll("#player-row-" + index + " .play_button.seek")[0]
      ?.getAttribute("data-seek-time");
    ////////console.log(play_time);

    play_time = parseFloat(play_time).toFixed(2);
    // this.tableData[index].audio.addEventListener('timeupdate', () => {
    //   this.sliderValue = this.tableData[index].audio.currentTime;
    // });

    if (!item.audio) {
      if (item.loop_type == 1) {
        if (item.tagged_loop_path !== null) {
          item.file = CONSTNAME.fILE_PATH + item.tagged_loop_path;
          this.durationofaudio = item.tagged_loop_duration;
        } else {
          item.file = CONSTNAME.fILE_PATH + item.loop_path;
          this.durationofaudio = item.loop_duration;
        }
      } else {
        item.file = CONSTNAME.fILE_PATH + item.tagged_loop_path;
        this.durationofaudio = item.tagged_loop_duration;
      }
      // if (!item.duration) {
      //   let sound: any;
      //   sound = await new Promise((resolve, reject) => {
      //     new p5.SoundFile(item.file, (s: any) => resolve(s), (err: any) => reject(err));
      //   });
      // }
      item.duration = this.durationofaudio;
      item.audio = new Audio(item.file);
      // this.childComponent.onPlayButtonClick(event)
      // ////////console.log(this.waveform);
      // item.waveform = new NgWaveformComponent(item.file);
      // this.waveform = item.waveform
      // ////////console.log(this.waveform);
      // this.waveform.play();
      // var target = event.target || event.srcElement || event.currentTarget;
      // var currently_playing_btn:any = document.querySelectorAll(".is-playing");
      // if (currently_playing_btn.length > 0) {
      //   if (target != currently_playing_btn[0]) {
      //     currently_playing_btn[0].nextSibling.click();
      //   }
      // }
      // item.audio.currentTime = play_time;
    
      // item.audio.addEventListener('loadedmetadata', () => {
        if(!this.mute){
          item.audio.muted = false
            }
      item.audio.volume = this.volumeValue
      
    //  const i =  item.audio.play();
    //  ////console.log(i);
     
      // if(!item.audio.paused){
      if (element && "click" in element) {
        (element as HTMLElement).click();
      }
    // }
      // });
      // item.audio.play();

      this.common.hideLoader();
      // item.audio.playbackRate = 1.3
      // ////console.log(item.audio.currentTime);
    //   item.audio.addEventListener("timeupdate", (test:any, test2:any) => {
    //     // //////console.log("fff",test, test2);
    // ////console.log("timeupdate",item.audio.currentTime);
        
    //   this.sliderValue = item.audio.currentTime;
    //     // this?.currentwaveform?.setRegionStart(this.sliderValue);
    //   //////////console.log(item.audio.currentTime);
    //   //////////console.log(this.sliderValue);
    //     var seek_btn = document.querySelectorAll(
    //       "#player-row-" + index + " .play_button.seek"
    //     )[0];
    //     // seek_btn.setAttribute("data-seek-time", String(item.audio.currentTime));
    //     // (seek_btn as HTMLElement).click();
    //     // this.currentwaveform.setRegionStart(this.sliderValue);
    //   });
      item.audio.addEventListener("ended", () => {
        // item.audio.currentTime = 0.00
        this.next(index);
      });
      if (item.duration >= 15) {
        this.above15second = true;
        this.apihitforstream = true;
      } else if (item.duration < 15 && item.duration >= 5) {
        this.apihitforstream = true;
        this.above5secondlessthan15 = true;
      } else if (item.duration > 1 && item.duration <= 5) {
        this.apihitforstream = true;
        this.above1secondlessthan5 = true;
      }
    } else {
      if(!this.mute){
        item.audio.muted = false
          }
      item.audio.volume = this.volumeValue
      // item.audio.play();
      // item.audio.currentTime = play_time;
      // ////console.log("currentTime",item.audio.currentTime);
      // item.audio.addEventListener("timeupdate", () => {
      //   this.sliderValue = item.audio.currentTime;
      //   // var seek_btn = document.querySelectorAll(
      //   //   "#player-row-" + index + " .play_button.seek"
      //   // )[0];
      //   // seek_btn.setAttribute("data-seek-time", String(item.audio.currentTime));
      //   // (seek_btn as HTMLElement).click();
      //   ////////console.log( this.currentwaveform);
      //   // this?.currentwaveform?.setRegionStart(item.audio.currentTime);
        
      //   // this.currentwaveform.setRegionStart(this.sliderValue);
      // });
      item.audio.addEventListener("ended", () => {
        // item.audio.currentTime = 0.00
        // this?.currentwaveform?.setRegionStart(item.duration);
        this.next(index);
      });
      if (element && "click" in element) {
        (element as HTMLElement).click();
      }
      this.common.hideLoader();
    }

    item.hidepausebutton = true;
    let r: any;
    this.id = setInterval(() => {
      r = item.audio.currentTime;
      if (r === item.duration || r > item.duration) {
        setTimeout(() => {}, 1000);
      }

      let dura: any = item.duration;
      if (item.duration >= 15) {
        if (r > 15) {
          if (this.apihitforstream == true) {
            this.apihitforstreaming(item, r, dura);
            clearInterval(this.id);
            this.apihitforstream = false;
          }
        }
      } else if (item.duration < 15 && item.duration >= 5) {
        if (r > 5) {
          if (this.apihitforstream == true) {
            this.apihitforstreaming(item, r, dura);
            clearInterval(this.id);
            this.apihitforstream = false;
          }
        }
      } else if (item.duration > 1 && item.duration <= 5) {
        if (r >= 1) {
          if (this.apihitforstream == true) {
            this.apihitforstreaming(item, r, dura);
            clearInterval(this.id);
            this.apihitforstream = false;
          }
        }
      }
    }, 200);
  }
  stopAudio(item: any, index: any) {
    // ////console.log(item);
    if (item) {
      if (item.audio) {
        item.audio.pause();
        // this.showAudioPlayer = false;s
      }
      clearInterval(this.id);

      this.tableData.map((r: any) => {
        r.hidepausebutton = false;
      });
    } else {
      this.tableData.map((r: any) => {
        if (r.audio) {
          r.audio.pause();
        }
        r.hidepausebutton = false;
      });
    }
    // item.waveform.pause();

    // document.querySelectorAll("#player-row-"+index+ " .play_button.pause")[0].click();
    const element = document.querySelectorAll(
      "#player-row-" + index + " .play_button.pause"
    )[0];

    if (element && "click" in element) {
      (element as HTMLElement).click();
    }
  }
  /* End of Player Functions */
  onSliderValueChanged(value: number) {
    
  //  ////console.log(value);
   this.sliderValue = value; // Update sliderValue in the parent component
  //  ////console.log(this.sliderValue);
   
  }
  SliderValueChanged() {
    
    return this.sliderValue
    
   }
  timeupdate(){
    
    const du =  this.tableData[this.player].duration || this.tableData[this.player].loop_duration || this.tableData[this.player].tagged_loop_duration
    
    return du * 1000
   }
    handleCreate(){
      if(this.norecordfound){
        this.router.navigate(['/create-pack']);

      }else{
        this.toastr.error("You must upload loops before creating a pack", "", { positionClass: 'toast-bottom-right' });
      }
    }   
  
    async searchloop() {
      // this.common.showLoader();
  this.stopAudio(this.tableData[this.player],this.player)
  
      // this.router.navigate(['user/sounds'], { queryParams: { user_id: this.value, pageno: this.pageno } });
      //  //////console.log(this.pageno)
  
      // if (this.pageno == 1) {
      //   this.router.navigate(['user/sounds'], { queryParams: { user_id: this.value} });
      // } else {
      //   // //////console.log("here it comes")
      //   this.router.navigate(['user/sounds'], { queryParams: { user_id: this.value} });
      // }
      // //////console.log(this.decrypted);
      // let passno: any = this.decrypted
      // //////console.log(Number(this.decrypted))
      var sendingdata1 = {
        "username": this.value
       }
      //  ////console.log(sendingdata);
       
             this.api.forclientgettokenpublic(`/user/get_user_id`, sendingdata1).then(async (res: any) => {
              // ////console.log(res);
              
               this.decrypted = res.response.data.user_type_id
      this.userData = localStorage.getItem("client_data");
      this.res = JSON.parse(this.userData)
      let sendingdata = {
        "page_no": this.pageno,
        "sort_on": this.sorton,
        "row_size": this.rowsize,
        "sort_type": this.sorttype,
        "profile_user_id": res.response.data.user_type_id,
        
        "login_user_id": this.res.user_type_id,
  
        "get_liked_loops": 1,
        "search" : this.search
  
      }
  
      let tokkenget = await this.common.getlocal(VARS.CLIENT_LOCAL_TOKEN_KEY);
  
      let headers: any;
      if (tokkenget) {
        headers = { 'Authorization': `Bearer ` + tokkenget, 'My-Custom-Header': 'foobar', };
  
      }
  
  
  
      // this.http.post(APIURL.BASE_URL + `/user/get_all_user_sounds`, sendingdata, { headers }).subscribe(async (res: any) => {
      this.api.forclientgettokenpublic(`/user/get_all_user_sounds`, sendingdata).then((res: any) => {
  
  
        // //////console.log(res)
        if (res.response.status == 1) {
          // //////console.log(this.Idpass);
          // this.common.hideLoader();
  
  
          localStorage.setItem('loop_count',res.response.data.total_records)
  
  
  
          if (res.response.data.total_pages == this.pageno) {
            this.hidepagination = true;
          } else {
            this.hidepagination = false;
          }
          if (this.paginationempty == true) {
            this.pagercount = [];
          }
          this.common.setlocal(VARS.USAGE_BUTTON, JSON.stringify(res.response.data.pagging_list[0]?.set_usage));
          this.common.setlocal(VARS.USAGE, JSON.stringify(res.response.data.pagging_list[0]?.usage_rights));
          res.response.data.pagging_list.map((item:any,index: any) =>{
            // //////console.log(item.tags);
          if(item.tags!=null){
          res.response.data.pagging_list[index].tags_array= item?.tags?.split('\n');
          }
        });
          this.total = res.response.data.total_records;
          this.totalrecordsinarray = res.response.data.total_records;
  
  
          if (res.response.data.total_records == 0) {
            this.norecordfound = false;
            
  
            this.common.hideLoader();
          } else {
            // setTimeout(() => {
              this.common.hideLoader();
            // }, 3000);
  
            
              for (let i = 0; i < res.response.data.pagging_list.length; i++) {
                this.array = res.response.data.pagging_list[i];
                if (this.array.tags !== null) {
                  let a = this.array.tags;
                  let strtag: any = a.split("\n ");
                  this.array["tag1"] = strtag[0];
                  this.array["tag2"] = strtag[1];
                  this.array["tag3"] = strtag[2];
  
                  this.common.hideLoader();
                }
  
              this.common.hideLoader();
  
  
              // //////console.log(this.encrypted)
  
              this.array['enc_link'] = this.value;
              // this.tableData.push(this.array)
              let _key = CryptoJS.enc.Utf8.parse(this.tokenFromUI);
              let _iv = CryptoJS.enc.Utf8.parse(this.tokenFromUI);
              let encrypted = CryptoJS.AES.encrypt(
                JSON.stringify(this.array.loop_id), _key, {
                keySize: 16,
                iv: _iv,
                mode: CryptoJS.mode.ECB,
                padding: CryptoJS.pad.Pkcs7
              });
              this.encrypted = encrypted.toString();
              this.encrypted = encodeURIComponent(this.encrypted);
  
              
              this.array['loop_idencrypt'] = this.encrypted;
              // this.tableData.push(this.array)
  
  
  
  
            }
  
            // this.tableData = res.response.data.pagging_list;
  
            this.record = this.tableData.length;
            // //////console.log(this.record,this.pageno)
            if (this.record == 0) {
              this.nocomments = false;
            } else {
              this.nocomments = true;
            }
            this.pagenolist = res.response.data.total_pages + 1;
            if (this.pageno == 1) {
               this.record = (25*0) + "1";
            } else {
              let a = this.pageno - 1
              this.record = (25*(a)+ 1) ;
            }
           
            // //////console.log(10*(this.pageno - 1) + res.response.data.pagging_list.length);
            this.pagenocount = 25 * (this.pageno - 1) + res.response.data.pagging_list.length
          //   res.response.data.pagging_list.map((item: any,i: any)=>{
          //     ////console.log(item);
              
          //   this.tableData.push(item)
          // })
          this.tableData = res.response.data.pagging_list
            // this.record = this.tableData.length;
            this.pagenolist = res.response.data.total_pages + 1;
            if (this.record == "01") {
              this.record = 1;
            }
            for (let i = 1; i < this.pagenolist; i++) {
              this.pagercount.push(i);
            }
            this.norecordfound = true;
            localStorage.setItem('sounds','true')
            for (i = 0; i < res.response.data.pagging_list.length; i++) {
              let a = res.response.data.pagging_list[i].tags
              if (a !== null) {
  
                let strtag: any = a.split("\n ");
                let strtag1: any = strtag[0];
                let strtag2: any = strtag[1];
                let strtag3: any = strtag[2];
  
                this.tablefortagsdisplay1 = strtag1;
                this.tablefortagsdisplay2 = strtag2;
                this.tablefortagsdisplay3 = strtag3;
                }
            }
  
            var date_future: any = new Date();
            var date_now: any = new Date(res.response.data.pagging_list[0].modified_on);
  
  
            if (res.response.data.pagging_list[0].is_liked == 1) {
  
              this.addred = true;
  
            } else {
              this.addred = false;
  
            }
            if (res.response.data.pagging_list[0].is_reposted == 1) {
              this.addgreen = true;
  
            } else {
              this.addgreen = false;
  
            }
  
            var time = date_future - date_now;
  
            var seconds = moment.duration(time).seconds();
            var minutes = moment.duration(time).minutes();
            var hours = moment.duration(time).hours();
            var days = moment.duration(time).days();
  
  
  
  
  
            if (seconds < 60 && minutes === 0) {
              // //////console.log(seconds)
              return "Posted " + seconds + " sec" + " ago"
            }
            else if (minutes < 60 && hours === 0) {
  
              return "Posted " + minutes + " min" + " ago"
  
            }
            else if (hours == 1 && days === 0) {
              return "Posted " + hours + " hr" + "ago"
            }
            else if (hours <= 24 && days === 0) {
              return "Posted " + hours + " hrs" + " ago"
            } else if (days === 1) {
              return "Posted " + days + " day" + " ago"
  
            }
  
            else if (days > 1) {
              // //////console.log(days)
              return "Posted " + days + " days" + " ago"
            }
  
            for (let i = 1; i < this.pagenolist; i++) {
              this.pagercount.push(i);
  
            }
  
  
  
            this.total = res.response.data.total_records;
            // //////console.log(this.total)
  
            if (res.response.data.pagging_list[0].loop_type == 1) {
              if (res.response.data.pagging_list[0].tagged_loop_path !== null) {
                this.src = CONSTNAME.fILE_PATH + res.response.data.pagging_list[0].tagged_loop_path;
                this.durationofaudio = res.response.data.pagging_list[0].tagged_loop_duration;
                // //////console.log("checking");
              } else {
                this.src = CONSTNAME.fILE_PATH + res.response.data.pagging_list[0].loop_path;
                this.durationofaudio = res.response.data.pagging_list[0].loop_duration;
              }
  
              // //////console.log(this.src);
            } else {
              this.src = CONSTNAME.fILE_PATH + res.response.data.pagging_list[0].tagged_loop_path;
              // //////console.log(this.src);
              this.durationofaudio = res.response.data.pagging_list[0].tagged_loop_duration;
            }
          }
  
  
  
  
  
        } else {
          this.common.hideLoader();
  
  
          let errorthroughapi = res.response.errors;
          let error = [];
          for (var i = 0; i < errorthroughapi.length; i++) {
  
            this.toastr.error(res.response.errors[i], "", { positionClass: 'toast-bottom-right' })
          }
  
        }
      }, async e => {
        this.common.hideLoader();
  
        this.toastr.error("Error in Api", "", { positionClass: 'toast-bottom-right' });
  
      });
    })
  
    }
  async getapicallforcouponcode(value:any) {
    // this.common.showLoader();


    // this.router.navigate(['user/sounds'], { queryParams: { user_id: this.value, pageno: this.pageno } });
    //  //////console.log(this.pageno)

    // if (this.pageno == 1) {
    //   this.router.navigate(['user/sounds'], { queryParams: { user_id: this.value} });
    // } else {
    //   // //////console.log("here it comes")
    //   this.router.navigate(['user/sounds'], { queryParams: { user_id: this.value} });
    // }
    // //////console.log(this.decrypted);
    // let passno: any = this.decrypted
    // //////console.log(Number(this.decrypted))
    var sendingdata1 = {
      "username": this.value
     }
    //  ////console.log(sendingdata);
     
           this.api.forclientgettokenpublic(`/user/get_user_id`, sendingdata1).then(async (res: any) => {
            // ////console.log(res);
            
             this.decrypted = res?.response?.data?.user_type_id
    this.userData = localStorage.getItem("client_data");
    this.res = JSON.parse(this.userData)
    let sendingdata = {
      "page_no": this.pageno,
      "sort_on": this.sorton,
      "row_size": this.rowsize,
      "sort_type": this.sorttype,
      "profile_user_id": res.response?.data?.user_type_id,
      
      "login_user_id": this.res?.user_type_id,

      "get_liked_loops": 1,
      "search" : this.search

    }

    let tokkenget = await this.common.getlocal(VARS.CLIENT_LOCAL_TOKEN_KEY);

    let headers: any;
    if (tokkenget) {
      headers = { 'Authorization': `Bearer ` + tokkenget, 'My-Custom-Header': 'foobar', };

    }



    // this.http.post(APIURL.BASE_URL + `/user/get_all_user_sounds`, sendingdata, { headers }).subscribe(async (res: any) => {
    this.api.forclientgettokenpublic(`/user/get_all_user_sounds`, sendingdata).then((res: any) => {


      // //////console.log(res)
      if (res.response.status == 1) {
        // //////console.log(this.Idpass);
        // this.common.hideLoader();


        localStorage.setItem('loop_count',res.response.data.total_records)



        if (res.response.data.total_pages == this.pageno) {
          this.hidepagination = true;
        } else {
          this.hidepagination = false;
        }
        if (this.paginationempty == true) {
          this.pagercount = [];
        }
        this.common.setlocal(VARS.USAGE_BUTTON, JSON.stringify(res.response.data.pagging_list[0]?.set_usage));
        this.common.setlocal(VARS.USAGE, JSON.stringify(res.response.data.pagging_list[0]?.usage_rights));
        res.response.data.pagging_list.map((item:any,index: any) =>{
          // //////console.log(item.tags);
        if(item.tags!=null){
        res.response.data.pagging_list[index].tags_array= item?.tags?.split('\n');
        }
      });
        this.total = res.response.data.total_records;
        this.totalrecordsinarray = res.response.data.total_records;


        if (res.response.data.total_records == 0) {
          this.norecordfound = false;
          

          this.common.hideLoader();
        } else {
          // setTimeout(() => {
            this.common.hideLoader();
          // }, 3000);

          
            for (let i = 0; i < res.response.data.pagging_list.length; i++) {
              this.array = res.response.data.pagging_list[i];
              if (this.array.tags !== null) {
                let a = this.array.tags;
                let strtag: any = a.split("\n ");
                this.array["tag1"] = strtag[0];
                this.array["tag2"] = strtag[1];
                this.array["tag3"] = strtag[2];

                this.common.hideLoader();
              }

            this.common.hideLoader();


            // //////console.log(this.encrypted)

            this.array['enc_link'] = this.value;
            // this.tableData.push(this.array)
            let _key = CryptoJS.enc.Utf8.parse(this.tokenFromUI);
            let _iv = CryptoJS.enc.Utf8.parse(this.tokenFromUI);
            let encrypted = CryptoJS.AES.encrypt(
              JSON.stringify(this.array.loop_id), _key, {
              keySize: 16,
              iv: _iv,
              mode: CryptoJS.mode.ECB,
              padding: CryptoJS.pad.Pkcs7
            });
            this.encrypted = encrypted.toString();
            this.encrypted = encodeURIComponent(this.encrypted);

            
            this.array['loop_idencrypt'] = this.encrypted;
            // this.tableData.push(this.array)




          }

          // this.tableData = res.response.data.pagging_list;

          this.record = this.tableData.length;
          // //////console.log(this.record,this.pageno)
          if (this.record == 0) {
            this.nocomments = false;
          } else {
            this.nocomments = true;
          }
          this.pagenolist = res.response.data.total_pages + 1;
          if (this.pageno == 1) {
             this.record = (25*0) + "1";
          } else {
            let a = this.pageno - 1
            this.record = (25*(a)+ 1) ;
          }
         
          // //////console.log(10*(this.pageno - 1) + res.response.data.pagging_list.length);
          this.pagenocount = 25 * (this.pageno - 1) + res.response.data.pagging_list.length
          if(value==="pagination"){
          res.response.data.pagging_list.map((item: any,i: any)=>{
            // ////console.log(item);
            
          this.tableData.push(item)
        })}else{
          this.tableData =  res.response.data.pagging_list
        }
          // this.record = this.tableData.length;
          this.pagenolist = res.response.data.total_pages + 1;
          if (this.record == "01") {
            this.record = 1;
          }
          for (let i = 1; i < this.pagenolist; i++) {
            this.pagercount.push(i);
          }
          this.norecordfound = true;
          localStorage.setItem('sounds','true')
          for (i = 0; i < res.response.data.pagging_list.length; i++) {
            let a = res.response.data.pagging_list[i].tags
            if (a !== null) {

              let strtag: any = a.split("\n ");
              let strtag1: any = strtag[0];
              let strtag2: any = strtag[1];
              let strtag3: any = strtag[2];

              this.tablefortagsdisplay1 = strtag1;
              this.tablefortagsdisplay2 = strtag2;
              this.tablefortagsdisplay3 = strtag3;
              }
          }

          var date_future: any = new Date();
          var date_now: any = new Date(res.response.data.pagging_list[0].modified_on);


          if (res.response.data.pagging_list[0].is_liked == 1) {

            this.addred = true;

          } else {
            this.addred = false;

          }
          if (res.response.data.pagging_list[0].is_reposted == 1) {
            this.addgreen = true;

          } else {
            this.addgreen = false;

          }

          var time = date_future - date_now;

          var seconds = moment.duration(time).seconds();
          var minutes = moment.duration(time).minutes();
          var hours = moment.duration(time).hours();
          var days = moment.duration(time).days();





          if (seconds < 60 && minutes === 0) {
            // //////console.log(seconds)
            return "Posted " + seconds + " sec" + " ago"
          }
          else if (minutes < 60 && hours === 0) {

            return "Posted " + minutes + " min" + " ago"

          }
          else if (hours == 1 && days === 0) {
            return "Posted " + hours + " hr" + "ago"
          }
          else if (hours <= 24 && days === 0) {
            return "Posted " + hours + " hrs" + " ago"
          } else if (days === 1) {
            return "Posted " + days + " day" + " ago"

          }

          else if (days > 1) {
            // //////console.log(days)
            return "Posted " + days + " days" + " ago"
          }

          for (let i = 1; i < this.pagenolist; i++) {
            this.pagercount.push(i);

          }



          this.total = res.response.data.total_records;
          // //////console.log(this.total)

          if (res.response.data.pagging_list[0].loop_type == 1) {
            if (res.response.data.pagging_list[0].tagged_loop_path !== null) {
              this.src = CONSTNAME.fILE_PATH + res.response.data.pagging_list[0].tagged_loop_path;
              this.durationofaudio = res.response.data.pagging_list[0].tagged_loop_duration;
              // //////console.log("checking");
            } else {
              this.src = CONSTNAME.fILE_PATH + res.response.data.pagging_list[0].loop_path;
              this.durationofaudio = res.response.data.pagging_list[0].loop_duration;
            }

            // //////console.log(this.src);
          } else {
            this.src = CONSTNAME.fILE_PATH + res.response.data.pagging_list[0].tagged_loop_path;
            // //////console.log(this.src);
            this.durationofaudio = res.response.data.pagging_list[0].tagged_loop_duration;
          }
        }





      } else {
        this.common.hideLoader();


        let errorthroughapi = res.response.errors;
        let error = [];
        for (var i = 0; i < errorthroughapi.length; i++) {

          this.toastr.error(res.response.errors[i], "", { positionClass: 'toast-bottom-right' })
        }

      }
    }, async e => {
      this.common.hideLoader();

      this.toastr.error("Error in Api", "", { positionClass: 'toast-bottom-right' });

    });
  })

  }
  getFileExtension(filename: string): string {
    // Extract the extension by splitting the filename at the last dot
    const parts = filename.split('.');
    if (parts.length > 1) {
      return parts[parts.length - 1].toLowerCase();
    } else {
      // If there's no dot in the filename, consider it as having no extension
      return '';
    }
  }
  
  downloadFile(filePath: any) {
    // Create a hidden anchor element
    this.disable = true
    const a = document.createElement("a");
    // //////console.log("jid");
    const fileExtension = this.getFileExtension(CONSTNAME.fILE_PATH + filePath.loop_path ||
      CONSTNAME.fILE_PATH + filePath.tagged_loop_path);
    document.body.appendChild(a);
    a.style.display = "none";
    this.common.getDataInCookie(VARS.Login_Client_data).then((res: any) => {
      // //////console.log(res);
      if(res===null){
        this.toastr.error("Please Login First", "", {
          positionClass: "toast-bottom-right",
        });
        setTimeout(() => {
        this.router.navigate(["/login"])
      }, 1000);
      }
      let resa = JSON.parse(res);
      this.idfromwebsite = resa.user_type_id;
      if (filePath.user_id !== this.idfromwebsite) {
        this.api
          .postApiHit(`/user/save_free_loop`, {
            loop_id: filePath.loop_id,
            user_type_id: this.idfromwebsite,
          })
          .then((res: any) => {
            if (res.response.status) {
              fetch(
                CONSTNAME.fILE_PATH + filePath.loop_path ||
                  CONSTNAME.fILE_PATH + filePath.tagged_loop_path
              )
                .then((response) => response.blob())
                .then((blob) => {
                  // Create a blob URL for the file
                  const blobUrl = window.URL.createObjectURL(blob);
                  
                  // Set the anchor's href and download attributes
                  a.href = blobUrl;

                  a.download =
                    filePath.title +
                    "_" +
                    filePath.bpm +
                    "_" +
                    filePath.sub_key +
                    "_@" +
                    filePath.username +
                    `.${fileExtension}`; // Set the desired filename
                  this.common.hideLoader();

                  // Simulate a click on the anchor to trigger the download
                  a.click();

                  // Clean up by revoking the blob URL
                  window.URL.revokeObjectURL(blobUrl);
                })
                .catch((response) => {
                  this.toastr.error(response.response.errors, "", {
                    positionClass: "toast-bottom-right",
                  });
                });

              // Remove the anchor element from the DOM
              document.body.removeChild(a);
    this.disable = false
  } else {
              this.toastr.error(res.response.errors, "", {
                positionClass: "toast-bottom-right",
              });
    this.disable = false
  }
          });
      } else {
        fetch(
          CONSTNAME.fILE_PATH + filePath.loop_path ||
            CONSTNAME.fILE_PATH + filePath.tagged_loop_path
        )
          .then((response) => response.blob())
          .then((blob) => {
            // Create a blob URL for the file
            const blobUrl = window.URL.createObjectURL(blob);

            // Set the anchor's href and download attributes
            a.href = blobUrl;

            a.download =
              filePath.title +
              "_" +
              filePath.bpm +
              "_" +
              filePath.sub_key +
              "_@" +
              filePath.username +
              `.${fileExtension}`; // Set the desired filename
            this.common.hideLoader();

            // Simulate a click on the anchor to trigger the download
            a.click();

            // Clean up by revoking the blob URL
            window.URL.revokeObjectURL(blobUrl);
    this.disable = false
  })
          .catch((response) => {
            this.toastr.error(response.response.errors, "", {
              positionClass: "toast-bottom-right",
            });
    this.disable = false
  });

        // Remove the anchor element from the DOM
        document.body.removeChild(a);
      }
    });
    if(filePath.stems){
      this.zipService.downloadFile(CONSTNAME.fILE_PATH  + filePath.stems).subscribe((data) => {
        const blob = new Blob([data], { type: 'application/zip' });
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download = `${filePath.title}.zip`;
        link.click();
    })
  }
    // Create a blob for the file data
  }
  arraylength() {
    // //////console.log(this.totalrecordsinarray)
    return this.totalrecordsinarray
  }

  show(item: any) {
    // let checktoken = false
    // let idfromwebsite: any
    // //////console.log("gaya")
    // //////console.log(item.user_id)
    // //////console.log(item.paypal_merchant_account_linked == 1);
    if (item.paypal_merchant_account_linked == 1) {
      // //////console.log("gaya")
      return true
    }else if (item.stripe_merchant_account_linked == 1) {
      return true
    } else if (item.loop_price == 0) {
      return true
    } else if (this.checktoken) {
      if (this.idfromwebsite == item.user_id) {
        return true
      } else {
        return false
      }

    } else {
      return false
    }


  }

  srcforsound(items: any) {
    if (items.loop_type == 1) {
      if (items.tagged_loop_path !== null) {
        this.durationofaudio = items.tagged_loop_duration;
        return CONSTNAME.fILE_PATH + items.tagged_loop_path;
        // //////console.log("checking");
      } else {
        this.durationofaudio = items.loop_duration;
        return CONSTNAME.fILE_PATH + items.loop_path;
      }

      // //////console.log(this.src);
    } else {
      this.durationofaudio = items.tagged_loop_duration;
      return CONSTNAME.fILE_PATH + items.tagged_loop_path;
      // //////console.log(this.src);
    }
  }



  redirecttoloopdetailpage(items: any) {
    let _key = CryptoJS.enc.Utf8.parse(this.tokenFromUI);
    let _iv = CryptoJS.enc.Utf8.parse(this.tokenFromUI);
    let encrypted = CryptoJS.AES.encrypt(
      JSON.stringify(items.loop_id), _key, {
      keySize: 16,
      iv: _iv,
      mode: CryptoJS.mode.ECB,
      padding: CryptoJS.pad.Pkcs7
    });
    this.encrypted = encrypted.toString();
    this.encrypted = encodeURIComponent(this.encrypted);
    // //////console.log(value);
    // //////console.log(this.encrypted);
    // this.router.navigate(['/detail-loop'], { queryParams: { loop_id: value } });

    this.router.navigate(['detail-loop'], { queryParams: { loop_id: this.encrypted } });
  }














  detailpageofloop(value: any) {

    this.encryptUsingAES256(value);

  }


  paginationget(pageinfo: any) {
    this.paginationempty = true;
    this.pageno = pageinfo;

    if (this.route.snapshot.queryParamMap.get('search')) {
      // this.searchApi()
    } else {
      this.getapicallforcouponcode("search");
    }


  }

  // routerLink=""
  toreportedloops() {

    this.router.navigate(['/admin/loops/reported-loops']);
  }

  encryptUsingAES256(value: any) {
    let _key = CryptoJS.enc.Utf8.parse(this.tokenFromUI);
    let _iv = CryptoJS.enc.Utf8.parse(this.tokenFromUI);
    let encrypted = CryptoJS.AES.encrypt(
      JSON.stringify(value), _key, {
      keySize: 16,
      iv: _iv,
      mode: CryptoJS.mode.ECB,
      padding: CryptoJS.pad.Pkcs7
    });
    this.encrypted = encrypted.toString();
    this.encrypted = encodeURIComponent(this.encrypted);

    this.router.navigate(['detail-loop'], { queryParams: { loop_id: this.encrypted } });
  }

  // [routerLink]="['/admin/loops/detail-uploads/'+item.loop_id]"

  addcolor(item: any) {
    if (item == this.pageno) {
      return 'activated';
    } else {
      return ''
    }
  }

  followingredirect() {
    this.loop = environment.Likedlooploopkit[0].active = true;



    this.followers = environment.followerspage[0].active = false;
    this.following = environment.followerspage[1].active = false;
  }

  setimage(data: any) {
    if (data.artwork_path == null) {
      if (data.profile_image_path == null) {
        return this.profile_image_pathofcomments;

      } else {
        if (data.profile_image_path.includes("/profile_photos/")) {
          return  CONSTNAME.fILE_PATH + data.profile_image_path;
  
          }else
          return data.profile_image_path;
      }

    } else {

      return CONSTNAME.fILE_PATH + data.artwork_path;
    }
  }




  async heartcolorred(value: any, detail: any, item: any) {
    //  //////console.log("gaya")
    let sendingData = {
      "loop_id": value,
    }
    // if (item.is_liked == 1) {
    //   item.is_liked = 0;
    // } else if (item.is_liked == 0) {
    //   item.is_liked = 1;
    // }



    let tokkenget = await this.common.getlocal(VARS.CLIENT_LOCAL_TOKEN_KEY);

    let headers: any;
    if (tokkenget) {
      // this.common.showLoader();
      this.api.postApiHit(`/user/like_dislike_upload`, sendingData).then((res: any) => {
        if (res.response.status == 1) {
          // let v: any = document.getElementById("red");
          // v.classList.add("red");
          // this.getapicallforcouponcode();
          this.common.hideLoader();
          this.toastr.success(res.response.msg, "", { positionClass: 'toast-bottom-right' });

          // if (item.user_id !== t) {

          // }
          if (item.is_liked == 1) {
            item.is_liked = 0;

            return ((item.number_of_likes) = item.number_of_likes - 1);
          } else if (item.is_liked == 0) {
            item.is_liked = 1;

            return ((item.number_of_likes) = item.number_of_likes + 1);
          }



          
          // this.getallinfoofcomments();
        } else if (res.response.status == 2) {
          let errorthroughapi = res.response.errors;
          let error = [];

          this.common.hideLoader();
          for (var i = 0; i < errorthroughapi.length; i++) {
            this.toastr.error(res.response.errors[i], "", { positionClass: 'toast-bottom-right' })
          }


        }
        else {
          let errorthroughapi = res.response.errors;
          let error = [];

          this.common.hideLoader();
          for (var i = 0; i < errorthroughapi.length; i++) {
            this.toastr.error(res.response.errors[i], "", { positionClass: 'toast-bottom-right' })
          }
        }
      }, async e => {

        this.common.hideLoader();
        this.toastr.error("Error in Api", "", { positionClass: 'toast-bottom-right' });
      });
    } else {
      this.opendialogueforverification();
      // this.router.navigate([CONSTNAME.REDIRECT_CLIENT_registerCLIENT]);
      // this.toastr.success("You must make an account to interact with uploaded media", "", { positionClass: 'toast-bottom-right' })
    }

  }
  openDialog(item:any): void {
    // //////console.log(item);
    this.stopAudio(this.tableData[this.player], this.player)
    let dialogue = this.matDialog.open(EditLoopComponent, { panelClass: 'share',  data: { data: item }});
    dialogue.afterClosed().subscribe(result => {
      //console.log(result);
      
      // if(result)
        setTimeout(() => {
          this.getapicallforcouponcode("delete")
          
        }, 1000);

    })
    dialogue.afterOpened().subscribe(() => {
      document.documentElement.classList.remove('cdk-global-scrollblock');
      document.documentElement.style.top = '0px';
    });
  }

  decryptUsingAES256() {

    let _key = CryptoJS.enc.Utf8.parse(this.tokenFromUI);
    let _iv = CryptoJS.enc.Utf8.parse(this.tokenFromUI);
    this.decrypted = CryptoJS.AES.decrypt(
      this.encryptedagainremoveslashes, _key, {
      keySize: 16,
      iv: _iv,
      mode: CryptoJS.mode.ECB,
      padding: CryptoJS.pad.Pkcs7
    }).toString(CryptoJS.enc.Utf8);
    this.decrypted = this.decrypted.replace(/[""]/gi, '');
    this.getapicallforcouponcode("main");
    // //////console.log(this.decrypted);

    // //////console.log(this.decrypted);


  }


  opendialogueforverification(): void {
    let dialogue = this.matDialog.open(ConfirmDeleteComponent, { panelClass: 'forgot-password', disableClose: true, data: { pageValue: "detailpage" } });
    dialogue.afterClosed().subscribe(result => {



      if (result.data == true) {
        this.router.navigate([CONSTNAME.REDIRECT_CLIENT_registerCLIENT]);
        this.toastr.success("You must make an account to interact with uploaded media", "", { positionClass: 'toast-bottom-right' })
        // this.router.navigate(['/users/uploadloopstatus']);
      } else {

      }

    });
    dialogue.afterOpened().subscribe(() => {
      document.documentElement.classList.remove('cdk-global-scrollblock');
      document.documentElement.style.top = '0px';
    });
  }

  addredcolor(item: any) {
    // //////console.log("in");
    if (item.is_liked == 1) {
      return "red";
    }
  }
  addgreencolor(item: any) {
    if (item.is_reposted == 1) {
      return "green";
    }
  }

  repostnumber(item: any) {
    return item.number_of_reposts
  }
  likesnumber(item: any) {
    return item.number_of_likes
  }

  async repost(value: any, item: any) {


    let sendingData = {
      "loop_id": value,
    }



    let tokkenget = await this.common.getlocal(VARS.CLIENT_LOCAL_TOKEN_KEY);

    let headers: any;
    if (tokkenget) {


      // this.common.showLoader();
      this.api.postApiHit(`/user/repost_upload`, sendingData).then((res: any) => {
        if (res.response.status == 1) {
          // this.getapicallforcouponcode();

          this.common.hideLoader();

          this.toastr.success(res.response.msg, "", { positionClass: 'toast-bottom-right' });
          // this.getallinfoofcomments();
          if (item.is_reposted == 1) {
            item.is_reposted = 0;

            return ((item.number_of_reposts) = item.number_of_reposts - 1);
          } else if (item.is_reposted == 0) {
            item.is_reposted = 1;

            return ((item.number_of_reposts) = item.number_of_reposts + 1);
          }
        } else if (res.response.status == 2) {
          let errorthroughapi = res.response.errors;
          let error = [];
          // this.cancel();
          this.common.hideLoader();

          for (var i = 0; i < errorthroughapi.length; i++) {
            this.toastr.error(res.response.errors[i], "", { positionClass: 'toast-bottom-right' })
          }
          this.router.navigate(['/users/uploadloopstatus']);

        }
        else {
          let errorthroughapi = res.response.errors;
          let error = [];
          // this.cancel();
          this.common.hideLoader();
          for (var i = 0; i < errorthroughapi.length; i++) {
            this.toastr.error(res.response.errors[i], "", { positionClass: 'toast-bottom-right' })
          }
        }
      }, async e => {

        this.common.hideLoader();
        this.toastr.error("Error in Api", "", { positionClass: 'toast-bottom-right' });
      });
    } else {
      this.opendialogueforverification();


    }
  }

  async redirecttolooppagecomment(value: any) {
    let tokkenget = await this.common.getlocal(VARS.CLIENT_LOCAL_TOKEN_KEY);
    if (tokkenget) {
      this.redirecttolooppage(value)
    } else {
      this.opendialogueforverification();
    }

  }

  redirecttolooppage(value: any) {

// //////console.log(value);

    let _key = CryptoJS.enc.Utf8.parse(this.tokenFromUI);
    let _iv = CryptoJS.enc.Utf8.parse(this.tokenFromUI);
    let encrypted = CryptoJS.AES.encrypt(
      JSON.stringify(value.loop_id), _key, {
      keySize: 16,
      iv: _iv,
      mode: CryptoJS.mode.ECB,
      padding: CryptoJS.pad.Pkcs7
    });
    this.encrypted = encrypted.toString();
    this.encrypted = encodeURIComponent(this.encrypted);

    // if(value.visiblity===0)
    this.router.navigate(['detail-loop'], { queryParams: { loop_id: this.encrypted } });



  }


  async apihitforstreaming(item: any, r: any, dura: any) {

    let tokkenget = await this.common.getlocal(VARS.CLIENT_LOCAL_TOKEN_KEY);

    let headers: any;
    if (tokkenget) {
      headers = { 'Authorization': `Bearer ` + tokkenget, 'My-Custom-Header': 'foobar', };

    }

    let sendingdata = {
      "audio_duration": dura,
      "loop_id": item.loop_id,
      "play_duration": r
    }
    this.http.post(APIURL.BASE_URL + `/user/stream_upload`, sendingdata, { headers }).subscribe(async (res: any) => {
      if (res.response.status == 1) {
        // this.toastr.success(res.response.msg, "", { positionClass: 'toast-bottom-right' });
      }

      else {
        let errorthroughapi = res.response.errors;
        let error = [];
        // this.cancel();
        this.common.hideLoader();
        for (var i = 0; i < errorthroughapi.length; i++) {
          this.toastr.error(res.response.errors[i], "", { positionClass: 'toast-bottom-right' })
        }
      }


    }, async e => {

      this.common.hideLoader();
      this.toastr.error("Error in Api", "", { positionClass: 'toast-bottom-right' });
    });
  }
  paginationgetmore() {
    this.paginationempty = true;

    this.pageno = this.pageno+1;
    // this.rowsize += this.rowsize
    // //////console.log(this.rowsize);
this.stopAudio(this.tableData[this.player],this.player)
    // const element = document.querySelectorAll(
    //   "#player-row-" + this.player + " .play_button.pause"
    // )[0];

    // if (element && "click" in element) {
    //   (element as HTMLElement).click();
    // }
    this.getapicallforcouponcode("pagination");



  }
  // playAudio(item: any) {
  //   this.showAudioPlayer = true;

  //   this.tableData.map((r: any) => {
  //     r.hidepausebutton = false;
  //   })
  //   this.tableData.map((r: any) => {
  //     if (r.audio) r.audio.pause()
  //   })




  //   if (!item.audio) {
  //     if (item.loop_type == 1) {
  //       if (item.tagged_loop_path !== null) {
  //         item.file = CONSTNAME.fILE_PATH + item.tagged_loop_path;
  //         this.durationofaudio = item.tagged_loop_duration;
  //       } else {
  //         item.file = CONSTNAME.fILE_PATH + item.loop_path;
  //         this.durationofaudio = item.loop_duration;
  //       }
  //     } else {
  //       item.file = CONSTNAME.fILE_PATH + item.tagged_loop_path;
  //       this.durationofaudio = item.tagged_loop_duration;
  //     }
  //     // if (!item.duration) {
  //     //   let sound: any;
  //     //   sound = await new Promise((resolve, reject) => {
  //     //     new p5.SoundFile(item.file, (s: any) => resolve(s), (err: any) => reject(err));
  //     //   });
  //     // }
  //     item.duration = this.durationofaudio;
  //     item.audio = new Audio(item.file);
  //     item.audio.play();

  //     if (item.duration >= 15) {
  //       this.above15second = true;
  //       this.apihitforstream = true

  //     }
  //     else if (item.duration < 15 && item.duration >= 5) {

  //       this.apihitforstream = true
  //       this.above5secondlessthan15 = true;
  //     } else if (item.duration > 1 && item.duration <= 5) {

  //       this.apihitforstream = true
  //       this.above1secondlessthan5 = true;
  //     }
  //   } else {
  //     item.audio.play();
  //   }





  //   item.hidepausebutton = true;
  //   let r: any;
  //   this.id = setInterval(() => {
  //     r = item.audio.currentTime;
  //     if (r === item.duration || r > item.duration) {

  //       setTimeout(() => {

  //       }, 1000);
  //     }

  //     let dura: any = item.duration;
  //     if (item.duration >= 15) {
  //       if (r > 15) {
  //         if (this.apihitforstream == true) {
  //           this.apihitforstreaming(item, r, dura);
  //           clearInterval(this.id);
  //           this.apihitforstream = false;

  //         }

  //       }
  //     } else if (item.duration < 15 && item.duration >= 5) {

  //       if (r > 5) {

  //         if (this.apihitforstream == true) {
  //           this.apihitforstreaming(item, r, dura);
  //           clearInterval(this.id);
  //           this.apihitforstream = false;

  //         }
  //       }


  //     } else if (item.duration > 1 && item.duration <= 5) {
  //       if (r >= 1) {
  //         if (this.apihitforstream == true) {
  //           this.apihitforstreaming(item, r, dura);
  //           clearInterval(this.id);
  //           this.apihitforstream = false;

  //         }

  //       }
  //     }
  //   }, 200);

  // }

  // stopAudio(item: any = null) {
  //   if (item) {
  //     if (item.audio) {
  //       item.audio.pause()
  //   this.showAudioPlayer = true;
  // }
  //     clearInterval(this.id);

  //     this.tableData.map((r: any) => {
  //       r.hidepausebutton = false;
  //     })

  //   } else {
  //     this.tableData.map((r: any) => {
  //       if (r.audio) {
  //         r.audio.pause()
  //       }
  //       r.hidepausebutton = false;
  //     })

  //   }


  // }

  onRegionChange(value: any = null) {
    // //////console.log(value);
  }
  onTrackLoaded(v: any = null) {
    // //////console.log("track");
    // //////console.log(v);

  }
  onTrackRendered(v: any = null) {
    // //////console.log("trackren");
    // //////console.log(v);
  }

  audio_duration: any;
  play_duration: any;
  onDurationChange(value: any = null) {
    if (value == 0) {

    } else {
      this.audio_duration = value;
      // this.totaltime = (Math.round( value* 100) / 100).toFixed(2);

    }
    // //////console.log(this.audio_duration);

    if (this.durationofaudio >= 15) {
      this.above15second = true;
      // //////console.log("above 15 sec");
    }
    else if (this.durationofaudio < 15 && value >= 5) {
      // //////console.log("between 5 and 15");
      this.above5secondlessthan15 = true;
    } else if (this.durationofaudio > 1 && value <= 5) {
      // //////console.log("between 1 and 5");
      this.above1secondlessthan5 = true;
    }
    // //////console.log(this.durationofaudio);
    // if (value >= 15) {
    //   this.above15second = true;
    //   // //////console.log("above 15 sec");
    // }
    // else if (value < 15 && value >= 5) {
    //   // //////console.log("between 5 and 15");
    //   this.above5secondlessthan15 = true;
    // } else if (value > 1 && value <= 5) {
    //   // //////console.log("between 1 and 5");
    //   this.above1secondlessthan5 = true;
    // }
  }



  onTimeUpdate(v: any = null) {

    if (v.progress) {

      // //////console.log("time", v.time);
      // this.currenttime = (Math.round( v.time* 100) / 100).toFixed(2); 
      if (v.progress >= 100) {
        // //////console.log("complete")
        // this.hidepausebutton = true;
        // this.hideplaybutton = false;
        setTimeout(() => {
          this.apihitforstream = true;
          // this.currenttime = "0.00";
        }, 1000);
        setTimeout(() => {

          // this.currenttime = "0.00";
        }, 10);


      }
      if (this.above15second) {
        if (v.time >= 15) {

          // this.apihitforstream = true;
          // alert("15 second is passed");
          if (this.apihitforstream == true) {
            // //////console.log(v.time);
            this.play_duration = v.time
            // //////console.log("api will hit");
            // this.apihitforstreaming();
            this.apihitforstream = false;
          }

        }
      } else if (this.above5secondlessthan15) {
        if (v.time >= 5) {
          if (this.apihitforstream == true) {
            // //////console.log(v.time);
            this.play_duration = v.time
            // //////console.log("api will hit");
            // this.apihitforstreaming();
            this.apihitforstream = false;
          }
        }

      } else if (this.above1secondlessthan5) {
        if (v.time >= 1) {
          if (this.apihitforstream == true) {

            this.play_duration = v.time

            // this.apihitforstreaming();
            this.apihitforstream = false;
          }
        }

      }




    }



  }

  timeget(items: any) {
    // //////console.log("great ", items)


    let newa: any = new Date();
    let next: any = new Date(items.created_on)

    var seconds: any = Math.floor((newa - next) / 1000);

    var interval: any = seconds / 31536000;

    if (interval > 1) {
      return "Posted " + Math.floor(interval) + " year ago";
    }
    interval = seconds / 2592000;
    if (interval > 1) {

      return "Posted " + Math.floor(interval) + " month ago";
    }
    interval = seconds / 86400;
    if (interval > 1) {
      if (Math.floor(interval) == 1) {
        return "Posted " + Math.floor(interval) + " day ago";
      } else {
        return "Posted " + Math.floor(interval) + " days ago";
      }

    }
    interval = seconds / 3600;
    if (interval > 1) {
      if (Math.floor(interval) == 1) {
        return "Posted " + Math.floor(interval) + " hour ago";
      } else {
        return "Posted " + Math.floor(interval) + " hours ago";
      }
    }
    interval = seconds / 60;
    if (interval > 1) {
      return "Posted " + Math.floor(interval) + " minute ago";
    }
    return "Posted " + Math.floor(seconds) + " second ago";
  }



  priceget(item: any) {
    if (item.loop_price == 0) {

    } else {
      return (Math.round(item.loop_price * 100) / 100).toFixed(2);

    }
  }
  onPaused(v: any = null) {
    // //////console.log("pause");
  }
  sendingstrtag1: any
  sendingstrtag2: any
  sendingstrtag3: any

  tagsget(items: any) {
    let a = items.tags
    let strtag: any = a.split('\n ');
    let strtag1: any = strtag[0];
    let strtag2: any = strtag[1];
    let strtag3: any = strtag[2];
    // this.sendingstrtag1 = strtag[0];
    // this.sendingstrtag2 = strtag[1];
    // this.sendingstrtag3 = strtag[2];
    // //////console.log(strtag1);
    // //////console.log(strtag2);
    // //////console.log(strtag3);
    return "#" + strtag1;

  }
  tagsget2(items: any) {
    let a = items.tags
    let strtag: any = a.split('\n ');

    let strtag2: any = strtag[1];
    return "#" + strtag2
  }
  tagsget3(items: any) {
    let a = items.tags
    let strtag: any = a.split('\n ');
    //  //////console.log(strtag[2])
    let strtag3: any = strtag[2];
    // //////console.log(strtag3);
    return "#" + strtag3
  }

  redirecttoprofilepage(item: any) {
    this.router.navigate([`user/sounds/@${this.value}`])
  }
  redirectto(item: any) {
    // //////console.log(item)
    let result = item.trimStart();

    // this.router.navigate(["advancesearch/"], {
    //   queryParams: { key_word: result },
    // });
    // setTimeout(() => {
    // location.reload()
      
    // }, 1000);
    this.router.navigate(['advancesearch/'], { queryParams:{ page_no:1,sort_on:'relevance',row_size:10,sort_type:'DESC',key_word:result,loop_search:1  } });
    // //////console.log(item)
    
  }
  redirectto1(items: any) {
    let a = items.tags
    let strtag: any = a.split('\n ');
    this.sendingstrtag1 = strtag[0];
    // //////console.log(item)
    this.router.navigate(['search-by-tag/'], { queryParams: { search_by: this.sendingstrtag1 } });
    // //////console.log(this.sendingstrtag1)

  }
  redirectto2(items: any) {
    let a = items.tags
    let strtag: any = a.split('\n ');
    this.sendingstrtag2 = strtag[1];
    // //////console.log(item)
    this.router.navigate(['search-by-tag/'], { queryParams: { search_by: this.sendingstrtag2 } });
    // //////console.log(item)
    // //////console.log(this.sendingstrtag2)

  }
  redirectto3(items: any) {
    let a = items.tags
    let strtag: any = a.split('\n ');
    this.sendingstrtag3 = strtag[2];
    // //////console.log(item)
    this.router.navigate(['search-by-tag/'], { queryParams: { search_by: this.sendingstrtag3 } });
    // //////console.log(item)
    // //////console.log(this.sendingstrtag3)

  }
  forall(items: any) {
    // //////console.log("Aa",items)
  }

  openmodal(item: any) {
    
    let valuebtoa = btoa(item.loop_id)
    
    // //////console.log(item)
    // //////console.log("in")
    let idfromloop: any = item.user_id
    this.common.getDataInCookie(VARS.Login_Client_data).then((res: any) => {
      if (!res) {
        this.getUserDetail();
      }
      let resa = JSON.parse(res)
      if (resa) {
        let idfromwebsite: any = resa.user_type_id
        // //////console.log(idfromwebsite, idfromloop)

        if (idfromloop === idfromwebsite) {
          // //////console.log("gaya")
          this.downloadloop(item)
          // this.apihitelsepart(item);
        } else {
          // //////console.log(this.urlpass + `&loop_id=${valuebtoa}`);
  this.urlpass = this.urlpass + `&loop_id=${valuebtoa}`
          // //////console.log("getting")
          this.apihitelsepart(item);

        }
      };
    })
  }


  //   downloadloop(res: any) {
  //     //////console.log(res)
  //     if (res.loop_type == 1) {

  //       this.src = CONSTNAME.fILE_PATH + res.loop_path;
  //       // //////console.log(CONSTNAME.fILE_PATH + res.loop_path)
  //  this.fileService.downloadFile().subscribe((response: any) => {
  //       window.location.href = CONSTNAME.fILE_PATH +  res.loop_path;
  //     }), (error: any) => //////console.log('Error downloading the file'),
  //       () => //console.info('File downloaded successfully');



  //     } else {
  //       this.src = CONSTNAME.fILE_PATH + res.tagged_loop_path;
  //       // //////console.log(CONSTNAME.fILE_PATH + res.tagged_loop_path)
  //       this.fileService.downloadFile().subscribe((response: any) => {
  //         window.location.href = CONSTNAME.fILE_PATH + res.tagged_loop_path;
  //       }), (error: any) => //////console.log('Error downloading the file'),
  //         () => //console.info('File downloaded successfully');

  //     }


  //   }

  // downloadloop(res: any) {
  //   //////console.log(res)
  //   if (res.loop_type == 1) {
  //     let file_name = CONSTNAME.fILE_PATH + res.loop_path
  //     window.open(file_name, '_blank');
  //   } else {
  //     let file_name = CONSTNAME.fILE_PATH + res.tagged_loop_path
  //     window.open(file_name, '_blank');
  //   }
  // }


  getUserDetail() {
    // this.common.showLoader();
    this.api.postApiHit(APIURL.user_get_logged_in_user_details, {}, VARS.ClIENT_TYPE).then((res: any) => {
      this.common.hideLoader();
      if (res.response.status) {
        this.common.setDataInCookie(VARS.Login_Client_data, res.response.data);
        this.common.getDataInCookie(VARS.Login_Client_data).then((res: any) => {
          let resa = JSON.parse(res)
          this.api.geturltoredirectexceptplandetail(resa.is_on_boarding_complete, resa.steps_completed);
        })
      }
    })
  }


  apihitelsepart(item: any) {
    // //////console.log(item.loop_id)
    // this.common.showLoader();
    let sendingdata = {
      "loop_id": item.loop_id
    }
    this.api.postApiHit(`/user/get_upload_detail`, sendingdata).then((res: any) => {
      if (res.response.status == 1) {
        // //////console.log(item)
        if (res.response.data.loop_details[0].is_already_downloaded == 0 && res.response.data.loop_details[0].is_already_paid == 0) {

          if (res.response.data.loop_details[0].selling_preference == 1) {
            this.download("free", item);
            this.common.hideLoader();
            // //////console.log("free")
          } else {
            if (res.response.data.loop_details[0].loop_price == 0) {
              this.download("free", item);
              this.common.hideLoader();
              // //////console.log("free")
            } else {
              this.download("premium", item);
              this.common.hideLoader();
              // //////console.log("premium")
            }


          }
        } else if (res.response.data.loop_details[0].is_already_downloaded == 0 && res.response.data.loop_details[0].is_already_paid == 1) {


          this.common.hideLoader();
        } else if (res.response.data.loop_details[0].is_already_downloaded == 1 && res.response.data.loop_details[0].is_already_paid == 1) {
          // //////console.log("yes");
          this.common.hideLoader();
          this.downloadloop(item)
        } else if (res.response.data.loop_details[0].is_already_downloaded == 1 && res.response.data.loop_details[0].is_already_paid == 0) {
          this.downloadloop(item)
        }
        // if (res.response.data.loop_details[0].is_already_downloaded == 1 && res.response.data.loop_details[0].is_already_paid == 0) {

        //   if (res.response.data.loop_details[0].selling_preference == 1) {
        //     this.download("free", item);
        //     this.common.hideLoader();
        //     // //////console.log("free")
        //   } else {
        //     if (res.response.data.loop_details[0].loop_price == 0) {
        //       this.download("free", item);
        //       this.common.hideLoader();
        //     // //////console.log("free")
        //     } else {
        //       this.download("premium", item);
        //       this.common.hideLoader();
        //     // //////console.log("premium")
        //     }


        //   }
        // }

      }


      else if (res.response.status == 2) {
        let errorthroughapi = res.response.errors;
        let error = [];
        this.common.hideLoader();
        for (var i = 0; i < errorthroughapi.length; i++) {
          this.toastr.error(res.response.errors[i], "", { positionClass: 'toast-bottom-right' })
        }
      }
      else if (res.response.status == 4) {
        // //////console.log("private account")
        // let errorthroughapi = res.response.errors;
        // let error = [];
        // this.common.hideLoader();
        // for (var i = 0; i < errorthroughapi.length; i++) {
        this.toastr.error("This account is Private, Follow to download the loop", "", { positionClass: 'toast-bottom-right' })
        // }
      }
      else {
        let errorthroughapi = res.response.errors;
        let error = [];
        this.common.hideLoader();
        for (var i = 0; i < errorthroughapi.length; i++) {
          this.toastr.error(res.response.errors[i], "", { positionClass: 'toast-bottom-right' })
        }
      }
    }, async e => {
      this.common.hideLoader();
      this.toastr.error("Error in Api", "", { positionClass: 'toast-bottom-right' });
    });
  }

  downloadlooppaid(res: any, item: any) {
    // //////console.log(res,item)
    if (res) {
      this.encrypted = btoa(item)
      this.encrypted2 = btoa(res)
      this.router.navigate(['/invoice'], { queryParams: { loop_id: this.encrypted, payment_id: this.encrypted2, type: 2 } });
    }
    this.common.hideLoader();
    window.location.href = `${APIURL.BASE_URL}/user/download_loop?loop_id=${item}`;

  }
  downloadloop(res: any) {
    // //////console.log("in")





    this.common.hideLoader();
    // window.location.href = `${APIURL.BASE_URL}/user/download_loop?loop_id=${res.loop_id}`;
    window.location.href = `${APIURL.BASE_URL}/user/download_loop?loop_id=${res.loop_id}`;

  }


  download(value: any, item: any): void {
    // //////console.log(item)
    let dialogue = this.matDialog.open(DownloadmodalComponent, { panelClass: 'forgot-password', disableClose: true, data: { pagevalue: value, allitems: item,url: this.urlpass } });
    dialogue.afterClosed().subscribe(result => {

      if (result.data == "downloadpre") {
        this.downloadlooppaid(result.data2, item);
        // //////console.log("download")
      } else if (result.data == "downloadfree") {
        // //////console.log("gaya")
        this.downloadloop(item);
      } else {

      }

    });
    dialogue.afterOpened().subscribe(() => {
      document.documentElement.classList.remove('cdk-global-scrollblock');
      document.documentElement.style.top = '0px';
    });
  }


  apihitforpaymentapi() {
    // //////console.log(this.loopidfromurl)
    // this.common.showLoader();
    // this.btndis = true;
    let sendingdata = {
      "loop_id": this.loopidfromurl,
      // "success_referrer": this.urlfromback,
      // "cancel_referrer": this.cancelurl
    }


    this.api.postApiHit(`/user/verify_stripe_payment`, sendingdata).then((res: any) => {
      if (res.response.status == 1) {
        this.common.hideLoader();
        
        // //////console.log(res.response.data)
        // //////console.log(this.loopidfromurl);

        this.downloadlooppaid(res.response.data, this.loopidfromurl);
        
        // //////console.log(res.response.msg)
        // this.matDialog.closeAll()
        // this.toastr.success(res.response.msg, "", { positionClass: 'toast-bottom-right' })
        // this.dialogRef.close({ data: "downloadpre", data2: res.response.data });


        this.common.hideLoader();

      }
      else if (res.response.status == 2) {
        let errorthroughapi = res.response.errors;
        let error = [];

        this.common.hideLoader();
        for (var i = 0; i < errorthroughapi.length; i++) {
          this.toastr.error(res.response.msg, "", { positionClass: 'toast-bottom-right' })
        }


      }
      else if (res.response.status == 3) {
        let errorthroughapi = res.response.errors;
        let error = [];
        this.common.hideLoader();
        // //////console.log("in going")
        for (var i = 0; i < errorthroughapi.length; i++) {
          let jk = error.push(errorthroughapi);
          // this.toastr.error(res.response.errors[i], "", { positionClass: 'toast-bottom-right' })
        }

        this.common.clearAllLocalAndSession();
        // localStorage.removeItem("clientintoken");
        this.router.navigate(['/login']);


      }
      else if (res.response.status == 4) {
        let errorthroughapi = res.response.errors;
        let error = [];

        this.common.hideLoader();
        for (var i = 0; i < errorthroughapi.length; i++) {
          this.toastr.error(res.response.msg, "", { positionClass: 'toast-bottom-right' })
        }


      }
      else {
        let errorthroughapi = res.response.errors;
        let error = [];
        // this.cancel();
        this.common.hideLoader();
        for (var i = 0; i < errorthroughapi.length; i++) {
          this.toastr.error(res.response.errors[i], "", { positionClass: 'toast-bottom-right' })
        }
      }
    }, async e => {

      this.common.hideLoader();
      this.toastr.error("Error in Api", "", { positionClass: 'toast-bottom-right' });
    });
  }
  redirecttodownloadspage(item: any){
    let dialogue = this.matDialog.open(DownloadComponent, { panelClass: 'downloads', disableClose: true, data: { data:item.loop_id  }});
    dialogue.afterClosed().subscribe(result => {

    })
    dialogue.afterOpened().subscribe(() => {
      document.documentElement.classList.remove('cdk-global-scrollblock');
      document.documentElement.style.top = '0px';
    });
  }
  openshare(item: any):void{
    let _key = CryptoJS.enc.Utf8.parse(this.tokenFromUI);
    let _iv = CryptoJS.enc.Utf8.parse(this.tokenFromUI);
    let encrypted = CryptoJS.AES.encrypt(
      JSON.stringify(item.loop_id ), _key, {
      keySize: 16,
      iv: _iv,
      mode: CryptoJS.mode.ECB,
      padding: CryptoJS.pad.Pkcs7
    });
    this.encrypted = encrypted.toString();
    this.encrypted = encodeURIComponent(this.encrypted);
    let dialogue = this.matDialog.open(ShareComponent, { panelClass: 'share', disableClose: true, data: { link:this.encrypted  }});
    dialogue.afterClosed().subscribe(result => {

    })
    dialogue.afterOpened().subscribe(() => {
      document.documentElement.classList.remove('cdk-global-scrollblock');
      document.documentElement.style.top = '0px';
    });
  }
  // openEdit(item:any){
  //   //////console.log("item",item);
  //   let encodedId=btoa(item.loop_id);
  //   this.router.navigate([`/upload-loop/${encodedId}`])
  // }
}
