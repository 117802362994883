<app-header></app-header>
<div >
  <div class="page-header"
    style="background-image:url(../../../../assets/img/background1.png);background-size: cover; margin-bottom: 0px;">
    <div class="page-header-image"></div>
    <div class="divModal" id="upload-mutliple" style="position: relative; margin-top: 0px;">
      <div class="row">
        <div class="col-lg-12 col-md-12 col-12 mx-auto client-login">
          <div class="card z-index-0">
            <h1 class="color page-head">Upload</h1>
            <form *ngIf="drafts?.length<50">
              <div class="drop-bx-rw">
                <div class="col-md col hed">
                  <label class="color" style="font-size: 19px; font-weight: 600">Upload .mp3 or .wav
                  </label>
                </div>
                <div class="col-md col-12">
                  <div class="browse-div">
                    <div style="padding: 20px; position: relative; z-index: 10;" *ngIf="disable_input_as_itsloading">
                      <div *ngIf="loopprogress" class="spinner-box">
                        <circle-progress [percent]="percentage" [radius]="50" [outerStrokeWidth]="5"
                          [innerStrokeWidth]="2" [outerStrokeColor]="'black'" [innerStrokeColor]="'#6c757d'"
                          [animation]="false"></circle-progress>
                        <button class="cancel" (click)="cancelUploadRequest()">Cancel</button>
                      </div>
                    </div>
                    <div class="before-text" *ngIf="!disable_input_as_itsloading">
                      <img src="../../../assets/img/file-download-03.svg" class="select-Label"
                        *ngIf="!disable_input_as_itsloading && !loopprogress" />
                      <div *ngIf="!disable_input_as_itsloading" class="select-Label"
                        [matTooltip]="disable_input_as_itsloading ? 'You cannot perform this action while uploading is in progress' : ''"
                        for="uploadlooptit">Drag & Drop Files or
                        <div class="form-group" style="margin-bottom: 0px;" [ngClass]="dragClass">
                          <input type="file" id="fileinput" accept=".wav, .mp3" multiple class="form-control"
                            (change)="selectFiles($event)" placeholder="Title" style="position: absolute;" />
                          <span class="link"> Browse</span>
                        </div>
                        <span class="file-format">.wav or .mp3 files only</span>
                      </div>
                    </div>
                    <label for="fileinput" >
                    <ngx-file-drop dropZoneClassName="drop-zone" class="dropBox"
                      (onFileDrop)="onFilesDrop($event)"></ngx-file-drop>
                      </label>
                  </div>
                </div>

              </div>
            </form>
            <div *ngIf="!noDrafts" class="card-tble">
              <div class="table-hed">
                <h3>Uploaded Files</h3>
              </div>
              <div class=" data-tbl table-responsive">
                <table>
                  <tr *ngFor="let items of drafts,let i = index" id="player-row-{{i}}" style="position: relative;">
                  
                    <td class="loop-art-td">
                      <div class="loopartwork">
                        <div class="upload-img-btn" (click)="triggerImgInput(i)">
                          <img [src]="setimage(items)" width="130px" height="130px">
                          <input type="file" id="imginput{{i}}" #fileInput1 accept=".jpeg, .jpg, .png"
                            class="form-control" (change)="selectFileImage($event,items.loop_id)" placeholder="Title"
                            style="position: absolute;visibility: hidden;cursor: pointer;" />
                          <p class="upload">
                            Upload Image
                          </p>
                        </div>
                        <div class="play-fnction">
                          <span ngcontent-yrg-c82="" class="play-btn hover">
                            <span class="play-btn hover play_button" id="play_button"  *ngIf="waveformLoaded[i] && !items.hidepausebutton"
                              (click)="playAudio(items, i)">
                              <i aria-hidden="true" class="fa fa-play"></i>
                            </span>
                            <span class="play-btn hover play_button" id="play_button"  *ngIf="waveformLoaded[i] && items.hidepausebutton"
                              (click)="stopAudio(items,i)">
                              <i class="fa fa-pause" aria-hidden="true"></i></span>

                          </span>
                        </div>
                      </div>
                      <div *ngIf="items.imageProgress" class="imgprog">
                        <mat-progress-bar mode="determinate" value="{{items.imgpercentage}}"></mat-progress-bar>

                      </div>

                      <div class="loop-name">
                        <h5 *ngIf="!items.isEditing" (click)="openEdit(items)" >{{items.title}}</h5>
                        <textarea [(ngModel)]="items.title" *ngIf="items.isEditing" class="nameinput" (change)="updateName($event, items.loop_id)"
                           ></textarea>
                        <!-- <div style="cursor: pointer;margin-right: 10px;" (click)="openEdit(items)"  *ngIf="!items.isEditing"><img 
                          src="../../../../assets/img/edit.svg"/></div> -->

                      </div>
                      <div class="private">
                        <h5 class="color">Make it private</h5>
                        <label _ngcontent-xyg-c237="" for="checkbox{{i}}" class="switch">
                          <input _ngcontent-xyg-c237="" type="checkbox" id="checkbox{{i}}" [(ngModel)]="items.visiblity"
                            [ngModelOptions]="{standalone: true}" (change)="updateVisible(items.loop_id,i)" />
                          <div _ngcontent-xyg-c237="" class="slider round"></div>
                        </label>
                      </div>
                    </td>
                    <td>
                      <div class="tb-inpu">
                        <label class="color label">Key</label>
                        <select class="short-input key-inp" [(ngModel)]="items.key"
                          [ngModelOptions]="{ standalone: true }" placeholder="Enter key"
                          (change)="keychange($event,items.loop_id)">
                          <option *ngFor="let i of keys" [value]="i">{{i}}</option>

                        </select>
                      </div>
                    </td>
                    <td>
                      <div class="tb-inpu">
                        <label class="color label">BPM</label>
                        <input class="short-input bpm-inp" id="bpm" [(ngModel)]="items.bpm"
                          [ngModelOptions]="{ standalone: true }" (change)="updateBpm($event, items.loop_id)"
                           />
                      </div>
                    </td>
                    <td>
                      <div class="tb-inpu">
                        <label class="color label">Stems  <button
                          style="
                          box-shadow: none;
                          min-width: auto;
                          background-color: inherit;
                          border: none;
                          height: 10px;
                        "
                          matTooltip="The stems will be included in the download along with the audio file of the loop."
                          aria-label="Button that displays a tooltip when focused or hovered over"
                        >
                          <img src="../../../assets/img/Icon.svg"/>
                          </button></label>
                        <div class="stem-upload">
                          <input type="file" id="fileinput{{i}}" accept=".zip" class="form-control" #fileInput2
                            (change)="selectFileZip($event,items.loop_id,i)" placeholder="Title"
                            style="position: absolute;visibility: hidden;" />
                          <div *ngIf="items.zipfilename" class="name">
                           <p class="stemname"> {{ items.zipfilename }}</p>
                          <button class="rm-zip" *ngIf="items.disable_input_as_itsloading2"  (click)="deleteZipFile(i)">x</button>
                          </div>
                          <div style="display: flex;color: rgba(68, 68, 69, 0.80);cursor: pointer;" (click)="triggerFileInput2(i)"
                            *ngIf="!items.zipfilename">Upload <img src="../../../assets/img/upload.svg" /></div>
                        </div>
                      </div>
                      <div *ngIf="items.zipProgress" class="zipprog">
                        <div class="deletezip">
                          <mat-progress-bar mode="determinate" value="{{items.stempercentage}}"></mat-progress-bar>
                        </div>
                        <p class="color">{{items.stempercentage}}%</p>
                      </div>

                    </td>
                    <td class="tab-inpu-div">
                      <div class="tb-inpu ">
                        <label class="color label">Tags</label>
                        <mat-chip-list #chipList aria-label="Fruit selection">
                          <input #fruitInput [formControl]="fruitCtrl"
                            [matChipInputFor]="chipList" class="short-input"
                            *ngIf="items.fruits?.length<3&&items.fruits?.length>0||items.showinput" style="color: #444445;
                        width: 100%;
                        max-width: 670px;" [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                            (matChipInputTokenEnd)="add($event,i)" />
                        </mat-chip-list>

                        <mat-chip *ngFor="let fruit of items.fruits" class="fi" [selectable]="selectable"
                          [removable]="removable" (removed)="remove(fruit,items.loop_id,i)" matChipRemove>
                          {{ fruit[0] == "#" ? "" + fruit : "#" + fruit }}
                        </mat-chip>
                        <button class="addtag" *ngIf="!items.showinput&&items.fruits?.length<3&&items?.fruits?.length<1" (click)="show(i)">Add
                        </button>
                        <!-- <button class="addtag" *ngIf="items.showinput" (click)="UpdateTags(items.loop_id)">Save
                      </button> -->
                      </div>
                    </td>
                    <td>
                      <div class="tb-inpu">
                        <div class="">
                          <label class="color label">Usage Rights</label>
                          <textarea placeholder="i.e. 50/50 split and Production Credits" class="custom-input"
                            [(ngModel)]="items.usage_rights" [ngModelOptions]="{ standalone: true }"
                            (keydown)="updateUsage($event,items.loop_id)">
                        </textarea>
                          <button (click)="ApplytoAll()" class="upload-btn1">Apply to All</button>
                        </div>
                        <button class="btn delete-loop-btn" (click)="delete(items.loop_id,i)">x</button>
                      </div>
                    </td>
                    <div style=" position: absolute;right: 49%; top: 39%;display: none;" >
                    <div style="    width: 400px;
                   
                    top: 49%;">
                      <div _ngcontent-yrg-c82="" class="wave">
                        <div _ngcontent-yrg-c82="" class="media-equlizer">
                           <app-waveform #waveformRef [arr]="srcforsound(items)" [loop_id]="items.loop_id" [items]="i"[passedValue]="items" 
                           [slide]="sliderValue"  (sliderValueChange)="onSliderValueChanged($event)"  (waveformLoaded)="onWaveformLoaded(i)" ></app-waveform>
        
                        </div>
                    </div>
                  </div>
                </div>
                </tr>
                </table>
              </div>
              <div class="button-rw">
                <input type="file" id="fileinput" #fileInput accept=".wav, .mp3" multiple class="form-control"
                  (change)="selectFiles($event)" placeholder="Title" style="position: absolute;visibility: hidden;" />
                <button class="addmre" *ngIf="drafts?.length<50" (click)="triggerFileInput()"> Add More Files </button>
                <button class="upload-btn" (click)="openConfirm()">{{drafts?.length>1?"Upload All":"Upload"}}</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<app-footer></app-footer>