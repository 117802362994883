import { Component, ElementRef, HostListener, NgZone, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, NavigationStart, Router } from '@angular/router';
import { ApiServiceService } from 'src/app/allServices/api-service.service';
import { commonservice } from 'src/app/allServices/commonservice';
import * as CryptoJS from 'crypto-js';
import { APIURL, CONSTNAME, VARS } from 'src/app/allServices/constant.service';
import { ToastrService } from 'ngx-toastr';
import { MatDialog } from '@angular/material/dialog';
import { ShareComponent } from '../MODAL/share/share.component';
import { ZipService } from 'src/app/allServices/zip.service';
import { EditLoopComponent } from '../MODAL/edit-loop/edit-loop.component';
import { EditPackComponent } from '../MODAL/edit-pack/edit-pack.component';
import { HttpClient } from '@angular/common/http';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-pack-detials',
  templateUrl: './pack-detials.component.html',
  styleUrls: ['./pack-detials.component.css']
})
export class PackDetialsComponent implements OnInit {
  encryptedagainremoveslashes: string;
  value: any;
  tokenFromUI: string = "0123456789123456";
  decrypted: any;
  arraytableforallvalues: any;
  afterload: boolean;
  idfromwebsite: any;
  artworkpathimage = "assets/img/artwork.jpg";
  tableData: any=[];
  durationofaudio: any;
  encrypted: string;
  id: any;
  profile_image_pathofcomments = "assets/img/artwork.jpg";
  showAudioPlayer: boolean;
  player: any =0;
  sliderValue: any;
  volumeValue: number = 1; 
  above15second: boolean;
  apihitforstream: boolean;
  above5secondlessthan15: boolean;
  above1secondlessthan5: boolean;
  tablefortagsdisplay1: any;
  tablefortagsdisplay2: any;
  tablefortagsdisplay3: any;
  showEdit: any;
  sameshowdownload: boolean;
  user: any;
  disable: boolean;
  steps: number;
  mute: boolean;
  private navigationSubscription: Subscription;

  constructor(public router: Router,private zone: NgZone, private zipService: ZipService, private http: HttpClient,private matDialog: MatDialog,private route: ActivatedRoute, public api: ApiServiceService,public common: commonservice,private toastr: ToastrService,    private elementRef: ElementRef
  ) { 
    this.navigationSubscription = this.router.events.subscribe((event) => {
      if (event instanceof NavigationStart) {
        // Navigation is about to start, handle cleanup logic
        this.handleCleanup();
      } else if (event instanceof NavigationEnd) {
        // Navigation has ended, perform any necessary actions
        // (e.g., trigger the click event)
        this.handleNavigationEnd();
      }
    });
  }
  @HostListener("window:keydown", ["$event"])
  handleKeyboardEvent(event: KeyboardEvent) {
    //console.warn("hii", event, this.player);
   
    if (event.key === "ArrowUp") {
      event.preventDefault();
      if (this.player >= 0) {
        this.previous();
      }
    } else if (event.key === "ArrowDown") {
      if(this.player<this.tableData.length){
      this.next(this.player);
      }
      // else{
      //   this.player = 0
      //   this.next()
      // }
    }
     else if (event.key === " ") {
      const searchInput: HTMLInputElement = this.elementRef.nativeElement.querySelector('.form-control');
      const isFocused = (searchInput === document.activeElement);
            if(!isFocused){
              event.preventDefault();
              if (this.tableData[this.player]?.hidepausebutton) {
                this.stopAudio(this.tableData[this.player], this.player);
              } else {
                this.playAudio(this.tableData[this.player], this.player, event);
                // }
              }
          }
    }
  }
  private handleCleanup() {
    // Perform cleanup logic before navigation
    // (e.g., pausing audio or video playback)
    const element = this.elementRef.nativeElement.querySelector("#player-row-" +  this.player + " .play_button.pause");
    if (element) {
      element.click();
    }
   
    // ////console.log('Performing cleanup before navigation');
  }
  fontSize(item:any){
    if (item.title.length <= 50 && item.title.length >= 30) {
      return 'long'
    } else if (item.title.length <= 30 && item.title.length >= 10) {
      return 'medium'
    } else {
      return 'small'
    }
  }
  private handleNavigationEnd() {
    // Navigation has ended, perform necessary actions
    // (e.g., trigger the click event)
    // ////console.log('Navigation has ended, triggering click event');

    const element = this.elementRef.nativeElement.querySelector("#player-row-" +  this.player + " .play_button.pause");
    if (element) {
      element.click();
    }
  }
  ngOnInit(): void {
    this.value = this.route.snapshot.queryParamMap.get('pack_id');
   
    
    this.encryptedagainremoveslashes = decodeURIComponent(this.value);
    this.decryptUsingAES256();
     var sendingdata = {
      pack_id : this.decrypted
    }
    this.api.forclientgettokenpublic(`/user/pack_detail`, sendingdata).then(async (res: any) => {
      // this.api.postApiHit(, sendingdata).then((res: any) => {

      this.arraytableforallvalues = await res.response.data
      this.tableData = res.response.data.loop_detials
      // ////console.log(this.arraytableforallvalues);
      this.common.getDataInCookie(VARS.Login_Client_data).then(async (res: any) => {
        if (!res) {
          // this.getUserDetail();
        }
        let resa = JSON.parse(res)
      if (resa) {
        // ////console.log('ji');
        
        this.zone.run(() => {
          this.idfromwebsite = resa.user_type_id
        
        // ////console.log(this.idfromwebsite);
        // ////console.log(this.arraytableforallvalues.user_id);
      if(this.arraytableforallvalues.user_id===this.idfromwebsite){

          this.sameshowdownload = true
          // ////console.log(this.sameshowdownload )
        } else {
          // ////console.log( res)
          this.sameshowdownload = false
        }
    })
  }
    })
      this.afterload = true;
      if (res.response.status == 1) {
        this.common.hideLoader();
        if (res.response.data.pack_cover_img !== null) {
          this.artworkpathimage = CONSTNAME.fILE_PATH + res.response.data.pack_cover_img;
          // ////console.log("dfdf");
        } else if (res.response.data.profile_image_path !== null) {
          this.artworkpathimage = CONSTNAME.fILE_PATH + res.response.data.profile_image_path;
        }
        let _key = CryptoJS.enc.Utf8.parse(this.tokenFromUI);
        let _iv = CryptoJS.enc.Utf8.parse(this.tokenFromUI);
        let encrypted = CryptoJS.AES.encrypt(
          JSON.stringify(this.tableData?.user_id), _key, {
          keySize: 16,
          iv: _iv,
          mode: CryptoJS.mode.ECB,
          padding: CryptoJS.pad.Pkcs7
        });
        this.encrypted = encrypted.toString();
        this.encrypted = encodeURIComponent(this.encrypted);
       
      }})
  
  }
  
  ngOnDestroy() {
    // this.stopAudio(this.tableData[this.player], this.player);
  }
  openEdit(item:any){
    // ////console.log("item",item);
    // let _key = CryptoJS.enc.Utf8.parse(this.tokenFromUI);
    // let _iv = CryptoJS.enc.Utf8.parse(this.tokenFromUI);
    // let encrypted = CryptoJS.AES.encrypt(
    //   JSON.stringify(item.loop_id), _key, {
    //   keySize: 16,
    //   iv: _iv,
    //   mode: CryptoJS.mode.ECB,
    //   padding: CryptoJS.pad.Pkcs7
    // });
    // this.encrypted = encrypted.toString();
    // this.encrypted = encodeURIComponent(this.encrypted);
    // this.router.navigate(['upload-loop'], { queryParams: { loop_id: this.encrypted } });
    this.openDialog(this.decrypted)
  }
  openDialog(item:any): void {
    // ////console.log(item);
    
    let dialogue = this.matDialog.open(EditPackComponent, { panelClass: 'share', disableClose: true, data: { data: item }});
    dialogue.afterClosed().subscribe(result => {
      this.ngOnInit()
    })
    dialogue.afterOpened().subscribe(() => {
      document.documentElement.classList.remove('cdk-global-scrollblock');
      document.documentElement.style.top = '0px';
    });
  }
  downloadzip(): void {
    let wavFiles:any=[]
    this.tableData.map((i: any,index:any)=>{
    // ////console.log(i,index);
    wavFiles.push({ name : i.title,data : CONSTNAME.fILE_PATH + i.loop_path})
    })
    // ////console.log(wavFiles);
    this.zipService.addSongToZip(wavFiles.name, wavFiles.data);


    this.zipService.downloadZip(wavFiles,this.arraytableforallvalues.pack_name);
    this.user = localStorage.getItem("client_data");
    const user_id = JSON.parse(this.user);   
     this.api.forclientgettoken('/user/save_free_pack',{user_id:user_id.user_type_id,pack_id:this.decrypted}).then((res)=>{

    })
  }

  timeget(items: any) {
    // ////console.log("great ", items)


    let newa: any = new Date();
    let next: any = new Date(items?.created_on)

    var seconds: any = Math.floor((newa - next) / 1000);

    var interval: any = seconds / 31536000;

    if (interval > 1) {
      if (Math.floor(interval) == 1) {
        return "Added " + Math.floor(interval) + " Year ago";
      } else {
        return "Added " + Math.floor(interval) + " Years ago";
      }
    }
    interval = seconds / 2592000;
    if (interval > 1) {
      if (Math.floor(interval) == 1) {
        return "Added " + Math.floor(interval) + " Month ago";
      } else {
        return "Added " + Math.floor(interval) + " Months ago";
      }
    }
    interval = seconds / 86400;
    if (interval > 1) {
      if (Math.floor(interval) == 1) {
        return "Added " + Math.floor(interval) + " Day ago";
      } else {
        return "Added " + Math.floor(interval) + " Days ago";
      }

    }
    interval = seconds / 3600;
    if (interval > 1) {
      if (Math.floor(interval) == 1) {
        return "Added " + Math.floor(interval) + " Hour ago";
      } else {
        return "Added " + Math.floor(interval) + " Hours ago";
      }
    }
    interval = seconds / 60;
    if(interval==1){
      return "Added " + Math.floor(interval) + " Minute ago";
    }
    if (interval > 1) {
      return "Added " + Math.floor(interval) + " Minutes ago";
    }
    if(Math.floor(seconds)>1){
    return "Added " + Math.floor(seconds) + " Seconds ago";
    }else{
      return "Added " + Math.floor(seconds) + " Second ago";

    }
  }
  setimage(data: any) {
    // ////console.log(data);
    
    if (data?.artwork_path == null) {
      if (data?.profile_image_path == null) {
        return this.profile_image_pathofcomments;

      } else {
      // ////console.log("i am in null");
      if (data?.profile_image_path.includes("/profile_photos/")) {
        return  CONSTNAME.fILE_PATH +  data.profile_image_path;

        }else
      return  data?.profile_image_path;
      }
    } else {
      // ////console.log("i am not in null");
      return CONSTNAME.fILE_PATH + data.artwork_path;
    }
  }
  setimage1(data: any) {
    if (data?.pack_cover_img == "undefined"||data?.pack_cover_img==null) {
      if (data?.profile_image_path == null) {
        return this.profile_image_pathofcomments;

      } else {
        if (data.profile_image_path.includes("/profile_photos/")) {
          return  CONSTNAME.fILE_PATH +  data.profile_image_path;
  
          }else
        return  data.profile_image_path;
      }

    } else {

      return CONSTNAME.fILE_PATH + data?.pack_cover_img;
    }
  }
  openEditloop(item:any): void {
    // //////console.log(item);
    this.stopAudio(this.tableData[this.player], this.player)
    let dialogue = this.matDialog.open(EditLoopComponent, { panelClass: 'share',  data: { data: item }});
    dialogue.afterClosed().subscribe(result => {
      // window.location.reload();
      this.ngOnInit()
      // this.getapicallforcouponcode()

    })
  }
  /* Start of Player Functions */
  next(i:any) {
    if (this.tableData.length - 1 == i) {
      this.disable = false;
      this.tableData[i].hidepausebutton = false;
      this.tableData[i].audio.currentTime = 0;
      this.sliderValue = 0;
      //  this.player = this.player + 1
      // this.playAudio(this.tableData[0], 0, event);
    } else {
      this.disable = false;
      this.tableData[i].audio.currentTime = 0;
      this.sliderValue = 0;
      this.stopAudio(this.tableData[i], i);
      i = i + 1;
      var seek_btn = document.querySelectorAll(
        "#player-row-" + i + " .play_button.vol"
      )[0];
      seek_btn.setAttribute("data-seek-vol", String(this.volumeValue));
      (seek_btn as HTMLElement).click();
      setTimeout(() => {
      this.playAudio(this.tableData[i], i, event);
        
      }, 1000);
      if(this.mute===true){
        var seek_btn = document.querySelectorAll(
          "#player-row-" + i + " .play_button.mute"
        )[0];
        // seek_btn.setAttribute("data-seek-time", String());
        (seek_btn as HTMLElement).click();
      }
    }
  }
  previous() {
    ////////console.log(this.tableData.length - 1, this.player);

    if (this.player == 0) {
      this.disable = true;
    } else {
      this.disable = false;
      this.sliderValue = 0;
      this.tableData[this.player].audio.currentTime = 0;
      this.stopAudio(this.tableData[this.player], this.player);
      this.player = this.player - 1;
      this.playAudio(this.tableData[this.player], this.player, event);
    }
  }
  onInput(event: any): void {
    // ////console.log('event',event.target.value);
//     // this.stopAudio(this.tableData[this.player],this.player)
    var seek_time = parseFloat(event.target.value).toFixed(2);
//     this.tableData[this.player].audio.currentTime = seek_time;
//     ////console.log("sli",this.tableData[this.player].audio.currentTime);
//     // this.playAudio(this.tableData[this.player],this.player,event)
    
this.sliderValue = seek_time
// ////console.log("sli",this.sliderValue);

    var seek_btn = document.querySelectorAll(
      "#player-row-" + this.player + " .play_button.seek"
    )[0];
    seek_btn.setAttribute("data-seek-time", String(seek_time));
    (seek_btn as HTMLElement).click();
  }


  onVolumeInput(event: any): void {
    // //////console.log(event.target.value);
    var seek_time = parseFloat(event.target.value).toFixed(2);
    this.tableData[this.player].audio.volume = parseFloat(event.target.value);
    this.volumeValue =  this.tableData[this.player].audio.volume
    var seek_btn = document.querySelectorAll(
      "#player-row-" + this.player + " .play_button.vol"
    )[0];
    seek_btn.setAttribute("data-seek-vol", String(seek_time));
    (seek_btn as HTMLElement).click();
    if(this.volumeValue>0){
      this.Unmute()
    }else if(this.volumeValue==0){
      this.Mute()
    }
  }
  Mute() {
    this.volumeValue = 0
    this.mute = true;
    this.tableData[this.player].audio.muted = true;
    var seek_btn = document.querySelectorAll(
      "#player-row-" + this.player + " .play_button.mute"
    )[0];
    // seek_btn.setAttribute("data-seek-time", String());
    (seek_btn as HTMLElement).click();
  }
  Unmute() {
    this.volumeValue = this.tableData[this.player].audio.volume
    this.mute = false;
    this.tableData[this.player].audio.muted = false;
    var seek_btn = document.querySelectorAll(
      "#player-row-" + this.player + " .play_button.unmute"
    )[0];
    // seek_btn.setAttribute("data-seek-time", String());
    (seek_btn as HTMLElement).click();
  }
  waveformLoaded: boolean[] = [];

  onWaveformLoaded(index: number) {
    this.waveformLoaded[index] = true;
  }
  forward() {
    // this.steps = this.steps + 0.10;
    this.tableData[this.player].audio.currentTime += 2;
    this.sliderValue =  this.tableData[this.player].audio.currentTime
    var seek_btn = document.querySelectorAll(
      "#player-row-" + this.player + " .play_button.forward"
    )[0];
    // seek_btn.setAttribute("data-seek-time", String());
    (seek_btn as HTMLElement).click();
    ////////console.log(this.steps);
  }
  backward() {
    // this.steps = this.steps - 0.01;
    this.tableData[this.player].audio.currentTime -= 2;
    this.sliderValue =  this.tableData[this.player].audio.currentTime
    var seek_btn = document.querySelectorAll(
      "#player-row-" + this.player + " .play_button.backward"
    )[0];
    // seek_btn.setAttribute("data-seek-time", String(seek_time));
    (seek_btn as HTMLElement).click();
  }

  playAudio(item: any, index: any, event: any) {
    // ////console.log(item,index);
    const divsWithClass = document.querySelectorAll('div.list');

    divsWithClass.forEach(div=>{
      div.classList.add('ply');
    })
    // this.common.showLoader();
   
    this.showAudioPlayer = true;
  
    // list.forEach((menu: Element) => {
    //   // //////////console.log(menu);
    //   menu.classList.remove('ply');
    // })

    // this.waveform = item
    this.player = index;
    var play_time: any =0.00;
    // document.querySelectorAll("#player-row-"+index+ " .play_button.play")[0].click();
   

    this.tableData.map((r: any) => {
      r.hidepausebutton = false;
    });
    this.tableData.map((r: any,i:any) => {
      if (r.audio) {
        ////////console.log('hii');
        
        r.audio.pause();
        const element = document.querySelectorAll(
          "#player-row-" + i + " .play_button.pause"
        )[0];
    
        if (element && "click" in element) {
          (element as HTMLElement).click();
        }
      }
    });
    const element = document.querySelectorAll(
      "#player-row-" + index + " .play_button.play"
    )[0];

    // ////console.log(element);
    
    play_time = document
      .querySelectorAll("#player-row-" + index + " .play_button.seek")[0]
      ?.getAttribute("data-seek-time");
    ////////console.log(play_time);

    play_time = parseFloat(play_time).toFixed(2);
    // this.tableData[index].audio.addEventListener('timeupdate', () => {
    //   this.sliderValue = this.tableData[index].audio.currentTime;
    // });

    if (!item.audio) {
      if (item.loop_type == 1) {
        if (item.tagged_loop_path !== null) {
          item.file = CONSTNAME.fILE_PATH + item.tagged_loop_path;
          this.durationofaudio = item.tagged_loop_duration;
        } else {
          item.file = CONSTNAME.fILE_PATH + item.loop_path;
          this.durationofaudio = item.loop_duration;
        }
      } else {
        item.file = CONSTNAME.fILE_PATH + item.tagged_loop_path;
        this.durationofaudio = item.tagged_loop_duration;
      }
      // if (!item.duration) {
      //   let sound: any;
      //   sound = await new Promise((resolve, reject) => {
      //     new p5.SoundFile(item.file, (s: any) => resolve(s), (err: any) => reject(err));
      //   });
      // }
      item.duration = this.durationofaudio;
      item.audio = new Audio(item.file);
      // this.childComponent.onPlayButtonClick(event)
      // ////////console.log(this.waveform);
      // item.waveform = new NgWaveformComponent(item.file);
      // this.waveform = item.waveform
      // ////////console.log(this.waveform);
      // this.waveform.play();
      // var target = event.target || event.srcElement || event.currentTarget;
      // var currently_playing_btn:any = document.querySelectorAll(".is-playing");
      // if (currently_playing_btn.length > 0) {
      //   if (target != currently_playing_btn[0]) {
      //     currently_playing_btn[0].nextSibling.click();
      //   }
      // }
      // item.audio.currentTime = play_time;
    
      // item.audio.addEventListener('loadedmetadata', () => {
        if(!this.mute){
          item.audio.muted = false
            }
      item.audio.volume = this.volumeValue
      
    //  const i =  item.audio.play();
    //  ////console.log(i);
     
      // if(!item.audio.paused){
      if (element && "click" in element) {
        (element as HTMLElement).click();
      }
    // }
      // });
      // item.audio.play();

      this.common.hideLoader();
      // item.audio.playbackRate = 1.3
      // ////console.log(item.audio.currentTime);
    //   item.audio.addEventListener("timeupdate", (test:any, test2:any) => {
    //     // //////console.log("fff",test, test2);
    // ////console.log("timeupdate",item.audio.currentTime);
        
    //   this.sliderValue = item.audio.currentTime;
    //     // this?.currentwaveform?.setRegionStart(this.sliderValue);
    //   //////////console.log(item.audio.currentTime);
    //   //////////console.log(this.sliderValue);
    //     var seek_btn = document.querySelectorAll(
    //       "#player-row-" + index + " .play_button.seek"
    //     )[0];
    //     // seek_btn.setAttribute("data-seek-time", String(item.audio.currentTime));
    //     // (seek_btn as HTMLElement).click();
    //     // this.currentwaveform.setRegionStart(this.sliderValue);
    //   });
      item.audio.addEventListener("ended", () => {
        // item.audio.currentTime = 0.00
        this.next(index);
      });
      if (item.duration >= 15) {
        this.above15second = true;
        this.apihitforstream = true;
      } else if (item.duration < 15 && item.duration >= 5) {
        this.apihitforstream = true;
        this.above5secondlessthan15 = true;
      } else if (item.duration > 1 && item.duration <= 5) {
        this.apihitforstream = true;
        this.above1secondlessthan5 = true;
      }
    } else {
      if(!this.mute){
        item.audio.muted = false
          }
      item.audio.volume = this.volumeValue
      // item.audio.play();
      // item.audio.currentTime = play_time;
      // ////console.log("currentTime",item.audio.currentTime);
      // item.audio.addEventListener("timeupdate", () => {
      //   this.sliderValue = item.audio.currentTime;
      //   // var seek_btn = document.querySelectorAll(
      //   //   "#player-row-" + index + " .play_button.seek"
      //   // )[0];
      //   // seek_btn.setAttribute("data-seek-time", String(item.audio.currentTime));
      //   // (seek_btn as HTMLElement).click();
      //   ////////console.log( this.currentwaveform);
      //   // this?.currentwaveform?.setRegionStart(item.audio.currentTime);
        
      //   // this.currentwaveform.setRegionStart(this.sliderValue);
      // });
      item.audio.addEventListener("ended", () => {
        // item.audio.currentTime = 0.00
        // this?.currentwaveform?.setRegionStart(item.duration);
        this.next(index);
      });
      if (element && "click" in element) {
        (element as HTMLElement).click();
      }
      this.common.hideLoader();
    }

    item.hidepausebutton = true;
    let r: any;
    this.id = setInterval(() => {
      r = item.audio.currentTime;
      if (r === item.duration || r > item.duration) {
        setTimeout(() => {}, 1000);
      }

      let dura: any = item.duration;
      if (item.duration >= 15) {
        if (r > 15) {
          if (this.apihitforstream == true) {
            this.apihitforstreaming(item, r, dura);
            clearInterval(this.id);
            this.apihitforstream = false;
          }
        }
      } else if (item.duration < 15 && item.duration >= 5) {
        if (r > 5) {
          if (this.apihitforstream == true) {
            this.apihitforstreaming(item, r, dura);
            clearInterval(this.id);
            this.apihitforstream = false;
          }
        }
      } else if (item.duration > 1 && item.duration <= 5) {
        if (r >= 1) {
          if (this.apihitforstream == true) {
            this.apihitforstreaming(item, r, dura);
            clearInterval(this.id);
            this.apihitforstream = false;
          }
        }
      }
    }, 200);
  }
  stopAudio(item: any, index: any) {
    //////////console.log(item);
    if (item) {
      if (item.audio) {
        item.audio.pause();
        // this.showAudioPlayer = false;s
      }
      clearInterval(this.id);

      this.tableData.map((r: any) => {
        r.hidepausebutton = false;
      });
    } else {
      this.tableData.map((r: any) => {
        if (r.audio) {
          r.audio.pause();
        }
        r.hidepausebutton = false;
      });
    }
    // item.waveform.pause();

    // document.querySelectorAll("#player-row-"+index+ " .play_button.pause")[0].click();
    const element = document.querySelectorAll(
      "#player-row-" + index + " .play_button.pause"
    )[0];

    if (element && "click" in element) {
      (element as HTMLElement).click();
    }
  }
  /* End of Player Functions */
  timeupdate(){
    const du =  this.tableData[this.player].duration || this.tableData[this.player].loop_duration || this.tableData[this.player].tagged_loop_duration
    return du * 1000
   }
   onSliderValueChanged(value: number) {
    this.sliderValue = value; // Update sliderValue in the parent component
   
  }
  redirecttoupload(item:any){
    let _key = CryptoJS.enc.Utf8.parse(this.tokenFromUI);
    let _iv = CryptoJS.enc.Utf8.parse(this.tokenFromUI);
    let encrypted = CryptoJS.AES.encrypt(
      JSON.stringify(item.pack_id), _key, {
      keySize: 16,
      iv: _iv,
      mode: CryptoJS.mode.ECB,
      padding: CryptoJS.pad.Pkcs7
    });
    this.encrypted = encrypted.toString();
    this.encrypted = encodeURIComponent(this.encrypted);
    // ////console.log(this.encrypted);
    this.router.navigate(['create-pack'], { queryParams: { pack_id: this.value } });
  }

  async apihitforstreaming(item: any, r: any, dura: any) {

    let tokkenget = await this.common.getlocal(VARS.CLIENT_LOCAL_TOKEN_KEY);

    let headers: any;
    if (tokkenget) {
      headers = { 'Authorization': `Bearer ` + tokkenget, 'My-Custom-Header': 'foobar', };

    }

    let sendingdata = {
      "audio_duration": dura,
      "loop_id": item.loop_id,
      "play_duration": r
    }
    this.http.post(APIURL.BASE_URL + `/user/stream_upload`, sendingdata, { headers }).subscribe(async (res: any) => {
      if (res.response.status == 1) {
        // this.toastr.success(res.response.msg, "", { positionClass: 'toast-bottom-right' });
      }

      else {
        let errorthroughapi = res.response.errors;
        let error = [];
        // this.cancel();
        this.common.hideLoader();
        for (var i = 0; i < errorthroughapi.length; i++) {
          this.toastr.error(res.response.errors[i], "", { positionClass: 'toast-bottom-right' })
        }
      }


    }, async (e: any) => {

      this.common.hideLoader();
      this.toastr.error("Error in Api", "", { positionClass: 'toast-bottom-right' });
    });
  }
  srcforsound(items: any) {
    if (items.loop_type == 1) {
      if (items.tagged_loop_path !== null) {
        this.durationofaudio = items.tagged_loop_duration;
        return CONSTNAME.fILE_PATH + items.tagged_loop_path;
        // ////console.log("checking");
      } else {
        this.durationofaudio = items.loop_duration;
        return CONSTNAME.fILE_PATH + items.loop_path;
      }

      // ////console.log(this.src);
    } else {
      this.durationofaudio = items.tagged_loop_duration;
      return CONSTNAME.fILE_PATH + items.tagged_loop_path;
      // ////console.log(this.src);
    }
  }
  redirecttolooppage(value: any) {


    let _key = CryptoJS.enc.Utf8.parse(this.tokenFromUI);
    let _iv = CryptoJS.enc.Utf8.parse(this.tokenFromUI);
    let encrypted = CryptoJS.AES.encrypt(
      JSON.stringify(value), _key, {
      keySize: 16,
      iv: _iv,
      mode: CryptoJS.mode.ECB,
      padding: CryptoJS.pad.Pkcs7
    });
    this.encrypted = encrypted.toString();
    this.encrypted = encodeURIComponent(this.encrypted);


    this.router.navigate(['detail-loop'], { queryParams: { loop_id: this.encrypted } });



  }
  redirecttouser(id: any){
    // ////console.log(id);
    
     this.router.navigate([`user/sounds/@${id}`])
  }
  redirectto(item: any) {
    // ////console.log(item)
    this.router.navigate(['search-by-tag/'], { queryParams: { search_by: item } });
    // ////console.log(item)
    
  }
  openshare1(item: any):void{
    let _key = CryptoJS.enc.Utf8.parse(this.tokenFromUI);
    let _iv = CryptoJS.enc.Utf8.parse(this.tokenFromUI);
    let encrypted = CryptoJS.AES.encrypt(
      JSON.stringify(item.loop_id ), _key, {
      keySize: 16,
      iv: _iv,
      mode: CryptoJS.mode.ECB,
      padding: CryptoJS.pad.Pkcs7
    });
    this.encrypted = encrypted.toString();
    this.encrypted = encodeURIComponent(this.encrypted);
    let dialogue = this.matDialog.open(ShareComponent, {  disableClose: true,   panelClass: 'no-scroll-block',  data: { link:this.encrypted,data:"loop"  }});
    dialogue.afterOpened().subscribe(() => {
      document.documentElement.classList.remove('cdk-global-scrollblock');
      document.documentElement.style.top = '0px';
    });
    dialogue.afterClosed().subscribe(result => {

    })
  }
  openshare(item: any):void{
    let _key = CryptoJS.enc.Utf8.parse(this.tokenFromUI);
    let _iv = CryptoJS.enc.Utf8.parse(this.tokenFromUI);
    let encrypted = CryptoJS.AES.encrypt(
      JSON.stringify(item.pack_id ), _key, {
      keySize: 16,
      iv: _iv,
      mode: CryptoJS.mode.ECB,
      padding: CryptoJS.pad.Pkcs7
    });
    this.encrypted = encrypted.toString();
    this.encrypted = encodeURIComponent(this.encrypted);
    let dialogue = this.matDialog.open(ShareComponent, { panelClass: 'share', disableClose: true, data: { link:this.encrypted,data:"pack"  }});
    dialogue.afterClosed().subscribe(result => {

    })
    dialogue.afterOpened().subscribe(() => {
      document.documentElement.classList.remove('cdk-global-scrollblock');
      document.documentElement.style.top = '0px';
    });
  }
  getFileExtension(filename: string): string {
    // Extract the extension by splitting the filename at the last dot
    const parts = filename.split('.');
    if (parts.length > 1) {
      return parts[parts.length - 1].toLowerCase();
    } else {
      // If there's no dot in the filename, consider it as having no extension
      return '';
    }
  }
  
  downloadFile(filePath: any) {
    // Create a hidden anchor element
    const a = document.createElement("a");
    // ////console.log("jid");
    const fileExtension = this.getFileExtension(CONSTNAME.fILE_PATH + filePath.loop_path ||
      CONSTNAME.fILE_PATH + filePath.tagged_loop_path);
    document.body.appendChild(a);
    a.style.display = "none";
    this.common.getDataInCookie(VARS.Login_Client_data).then((res: any) => {
      // ////console.log(res);
      if(res===null){
        fetch(
          CONSTNAME.fILE_PATH + filePath.loop_path ||
            CONSTNAME.fILE_PATH + filePath.tagged_loop_path
        )
          .then((response) => response.blob())
          .then((blob) => {
            // Create a blob URL for the file
            const blobUrl = window.URL.createObjectURL(blob);
            
            // Set the anchor's href and download attributes
            a.href = blobUrl;

            a.download =
              filePath.title +
              "_" +
              filePath.bpm +
              "_" +
              filePath.sub_key +
              "_@" +
              filePath.username +
              `.${fileExtension}`; // Set the desired filename
            this.common.hideLoader();

            // Simulate a click on the anchor to trigger the download
            a.click();

            // Clean up by revoking the blob URL
            window.URL.revokeObjectURL(blobUrl);
          })
          .catch((response) => {
            this.toastr.error(response.response.errors, "", {
              positionClass: "toast-bottom-right",
            });
          })
      }
      let resa = JSON.parse(res);
      this.idfromwebsite = resa.user_type_id;
      if (filePath.user_id !== this.idfromwebsite) {
        this.api
          .postApiHit(`/user/save_free_loop`, {
            loop_id: filePath.loop_id,
            user_type_id: this.idfromwebsite,
          })
          .then((res: any) => {
            if (res.response.status) {
              fetch(
                CONSTNAME.fILE_PATH + filePath.loop_path ||
                  CONSTNAME.fILE_PATH + filePath.tagged_loop_path
              )
                .then((response) => response.blob())
                .then((blob) => {
                  // Create a blob URL for the file
                  const blobUrl = window.URL.createObjectURL(blob);
                  
                  // Set the anchor's href and download attributes
                  a.href = blobUrl;

                  a.download =
                    filePath.title +
                    "_" +
                    filePath.bpm +
                    "_" +
                    filePath.sub_key +
                    "_@" +
                    filePath.username +
                    `.${fileExtension}`; // Set the desired filename
                  this.common.hideLoader();

                  // Simulate a click on the anchor to trigger the download
                  a.click();

                  // Clean up by revoking the blob URL
                  window.URL.revokeObjectURL(blobUrl);
                })
                .catch((response) => {
                  this.toastr.error(response.response.errors, "", {
                    positionClass: "toast-bottom-right",
                  });
                });

              // Remove the anchor element from the DOM
              document.body.removeChild(a);
            } else {
              this.toastr.error(res.response.errors, "", {
                positionClass: "toast-bottom-right",
              });
            }
          });
      } else {
        fetch(
          CONSTNAME.fILE_PATH + filePath.loop_path ||
            CONSTNAME.fILE_PATH + filePath.tagged_loop_path
        )
          .then((response) => response.blob())
          .then((blob) => {
            // Create a blob URL for the file
            const blobUrl = window.URL.createObjectURL(blob);

            // Set the anchor's href and download attributes
            a.href = blobUrl;

            a.download =
              filePath.title +
              "_" +
              filePath.bpm +
              "_" +
              filePath.sub_key +
              "_@" +
              filePath.username +
              `.${fileExtension}`; // Set the desired filename
            this.common.hideLoader();

            // Simulate a click on the anchor to trigger the download
            a.click();

            // Clean up by revoking the blob URL
            window.URL.revokeObjectURL(blobUrl);
          })
          .catch((response) => {
            this.toastr.error(response.response.errors, "", {
              positionClass: "toast-bottom-right",
            });
          });

        // Remove the anchor element from the DOM
        document.body.removeChild(a);
      }
    });
    if(filePath.stems){
      this.zipService.downloadFile(CONSTNAME.fILE_PATH  + filePath.stems).subscribe((data) => {
        const blob = new Blob([data], { type: 'application/zip' });
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download = `${filePath.title}.zip`;
        link.click();
    })
  }
    // Create a blob for the file data
  }
  decryptUsingAES256() {

    let _key = CryptoJS.enc.Utf8.parse(this.tokenFromUI);
    let _iv = CryptoJS.enc.Utf8.parse(this.tokenFromUI);
    this.decrypted = CryptoJS.AES.decrypt(
      this.encryptedagainremoveslashes, _key, {
      keySize: 16,
      iv: _iv,
      mode: CryptoJS.mode.ECB,
      padding: CryptoJS.pad.Pkcs7
    }).toString(CryptoJS.enc.Utf8);

    this.decrypted = this.decrypted.replace(/[""]/gi, '');

    // ////console.log(this.decrypted);
    // this.getapicallforcouponcode();


  }
}