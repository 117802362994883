<nav class="navbar-expand-lg fixed-top logout-nav" [ngClass]="{'header-color': showCustomClass}" *ngIf="showMenu" >
  <!-- <div class="container"> -->
    <div class="container ">
      <div class="row">
      <div class="navbar-translate logged-out col-sm-7 col-7" >
        <img src="./assets/img/logo.png" href="index.html"  (click)="redirecttohome()" height="60" class="header-logo-img">
        <a class="navbar-brand" href="index.html" rel="tooltip" data-placement="bottom">
          Loophead
        </a>
        <div class="form-group has-feedback" [class.has-search]="showSearch">
          <span class="glyphicon glyphicon-search form-control-feedback" ></span>
          <input type="text" class="form-control" placeholder="Search"  [(ngModel)]="search" (keyup.enter)="submit()" >
        </div>
      </div>

      <div class="col-sm-5 col-5 navbar-logo"style="text-align:end;" id="navigation" data-nav-image="./assets/img/blurred-image-1.jpg">
            <a class="nav-link btn btn-neutral"  (click)="redirecttologin()">
              <p style="font-weight: bold; font-size: medium; color:black;margin: 0px;">Log In</p>
            </a>
          
      </div>
      </div>
    </div>
  <!-- </div> -->
</nav>

<div class="header"  *ngIf="showMenulogin" (clickOutside)="clickedOutside()" >
    <nav class="navbar-expand-md navbar" [ngClass]="{'header-height': showCustomClass}" >
      <div class="container main-header-cntnr" style="cursor: pointer;">
        <div class="header-cntnt">
            <div class="logo">
              <div class="logo-name" (click)="!showMenulogin?redirecttohome():redirecttoexplore()"  *ngIf="showSearch">
                <img src="./assets/img/logo.png" style="cursor: pointer;">
                <a class="navbar-brand" href="index.html" rel="tooltip" data-placement="bottom" >
                  Loophead
                </a>
              </div> 
                <div class="form-group has-feedback " [class.has-search]="showSearch">
                  <i class="srch-icon fa fa-search" aria-hidden="true"></i>
                  <!-- <span class="glyphicon glyphicon-search form-control-feedback"></span> -->
                  <input type="text" class="form-control" placeholder="Search" [(ngModel)]="search" (keyup.enter)="submit()">
                  <div class="mobi-srch"  (click)="displaySearch()">
                    <span class="glyphicon glyphicon-search form-control-feedback"></span>
                  </div>
                </div>
               
            </div>
          <button  (click)="toggleMenu()"  class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent"
           aria-controls="navbarSupportedContent" aria-expanded=""  aria-label="Toggle navigation">
            <span class="navbar-toggler-icon"><img src="../../../../assets/img/menu-icon.svg"/></span>
          </button>
            <div class="collapse navbar-collapse nav-list"  id="navbarSupportedContent"  >
            <!-- <div class="loggedinMenu justify-content-end" *ngIf="showMenu" id="navigation"
                data-nav-image="./assets/img/blurred-image-1.jpg"> -->
                <div class="drawer_open" data-toggle="collapse" data-target="#navbarSupportedContent"></div>
              <ul class="navbar-nav">
                <!-- <li class="respnsive-logo">
                  <div _ngcontent-uaa-c130="" class="logo" (click)="redirecttoexplore()">
                    <img _ngcontent-uaa-c130="" src="./assets/img/logo.png" height="60">
                    <a _ngcontent-uaa-c130="" href="index.html" rel="tooltip" data-placement="bottom" class="navbar-brand"> Loophead </a>
                  </div>
                </li> -->
                <li class="nav-item"  [class.active]="isActive('/explore')">
                  <a class="nav-link" (click)="redirecttoexplore()">
                    <p style="font-weight: 500; font-size: medium; color:white; text-transform: capitalize;">Explore</p>
                  </a>
                </li>

                <li class="nav-item" style="" [class.active]="isActive('/my-feed')">
                  <a class="nav-link" (click)="redirecttomyfeed()">
                    <p style="font-weight: 500; font-size: medium; color:white; text-transform: capitalize;">Feed</p>
                  </a>
                </li>

            <li class="nav-item upload-li" style="" (click)="redirecttoupload()" >
              <a class="nav-link btn btn-neutral"  style="">
                <p >Upload</p>
              </a>
            </li>
            <li class="nav-item ntify bell"  (click)="remove()">
              <span *ngIf="totalnotification!=0" class="nti-count" [ngClass]="adddropdownclass ? 'hidebadge' : '' ">{{ totalnotification }}</span>
              <button class="btn dropdwn-btn btn-default ps-dropdown1" type="button" id="dropdownMenu1" aria-expanded="true">
                <img src="assets/img/icons/notification-bell.svg">
              </button>
              <ul class="dropdown-menu ps-dropdown-menu1" aria-labelledby="dropdownMenu1">
                <div >
                  <div *ngIf="total_records!=0"  >
                  <div class="ntification-line"  *ngFor="let item of arrayfortable" >
                      <div  *ngIf="item.notification_type===1"><img src="../../../../assets/img/heart-regular.svg"/></div>
                      <div *ngIf="item.notification_type===2"><img src="../../../../assets/img/comment.svg"/></div>
                      <div *ngIf="item.notification_type===3"><img src="../../../../assets/img/repost.svg"/></div>
                      <div *ngIf="item.notification_type===4" style="min-width: 24px;
                      text-align: start;"><img src="../../../../assets/img/user-plus-01 (1).svg"/></div>
                      <div *ngIf="item.notification_type===5" style="min-width: 24px;
                      text-align: start;"><img src="../../../../assets/img/user-plus-01 (1).svg"/></div>
                      <div *ngIf="item.notification_type===6" style="min-width: 24px;
                      text-align: start;"><img src="../../../../assets/img/sales.svg"/></div>
                      <div *ngIf="item.notification_type===7" style="min-width: 24px;
                      text-align: start;"><img src="../../../../assets/img/download-01 (2).svg"/></div>
                      <div *ngIf="item.notification_type===8" style="min-width: 24px;
                      text-align: start;"><img src="../../../../assets/img/list (2).svg"/></div>
                      <img [src] = "setimage(item)" min-width="50px" style="border-radius: 50%;min-height: 50px; max-width: 50px;    min-width: 50px;
                      max-height: 50px;object-fit: cover;"/>
                          <div class="my-auto ntify-name">
                              <span class="name-tag" (click)="redirecttousernoti(item.notification_text_1)">{{item.notification_text_1}}</span>
                              <span >{{item.notification_text_2 }}</span>
                              <span style="cursor: pointer;" class="name-tag" *ngIf="item.notification_type===7"  (click)="redirecttoLoop(item.loop_id)" > {{item.notification_text_3}}</span>
                              <span style="cursor: pointer;" class="name-tag" *ngIf="item.notification_type===8" (click)="redirecttopack(item)" >{{item.notification_text_3}}</span>
                          </div>
                  </div>
              </div><div *ngIf="total_records==0" style="width: 100%;display: flex;justify-content: center;margin-top: 10%;">
                      <p>No Notifications</p>
                  </div>
                  <div class="see-all" (click)="redirecttoallnotification()">See All Notifications</div>
              </div>
              </ul>
            </li>
            <!-- <li class="mobi-shw">
              <a class="nav-link ">
                <p style="font-weight: bold; font-size: medium; color:white; text-transform: capitalize;" (click)="redirecttoallnotification()">Notification</p>
              </a>
            </li> -->
            <li class="nav-item profile-resp" style="">
              <button class="nav-link" (click)="toggleDropdown()" id="dropdown" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                <img style="border-radius: 9999px; height: 44px; width: 44px" [src]="imageView" />
                <span style="font-weight: bold; font-size: medium; color:white; text-transform: capitalize;"> Profile</span>
              </button>
              <ul class="dropdown-content menu dropdown-menu dropdwn-2 ps-dropdown-menu" aria-labelledby="dropdown" [ngClass]="isDropdownOpen?'show':''"  type="none">
                <li (click)="redirecttodashboard()">
          <img src="./../../../../assets/img/user-01.svg" height="20">
          Profile</li>
                <li (click)="redirecttodownload()"> 
          <img src="./../../../../assets/img/download.svg"  height="20">
          My Downloads</li>
                <li (click)="redirecttosettings()">
          <img src="./../../../../assets/img/settings-01.svg"  height="20">
          Settings</li>
                <li (click)="redirecttologout()" style="color: red;">
          <img src="./../../../../assets/img/log-in-01.svg"  height="20">
          Log Out</li>
              </ul>
            </li> 
            <li class="nav-item ntify profile" >
              <button class="btn dropdwn-btn btn-default ps-dropdown" type="button" id="dropdownMenu" aria-expanded="false">
              <div class="profile-li">
                <img  [src]="imageView" />
                <span style="font-weight: bold; font-size: medium; color:white; text-transform: capitalize;"> Profile</span>
              </div>
              </button>
              <ul class="dropdown-content menu dropdown-menu dropdwn-2 ps-dropdown-menu" aria-labelledby="dropdownMenu" type="none"  >
                <div >
                  <li (click)="redirecttodashboard()">
                    <img src="./../../../../assets/img/user-01.svg" height="20">
                    Profile</li>
                          <li (click)="redirecttodownload()"> 
                    <img src="./../../../../assets/img/download-1.svg"  height="20">
                    My Downloads</li>
                          <li (click)="redirecttosettings()">
                    <img src="./../../../../assets/img/settings-01.svg"  height="20">
                    Settings</li>
                          <li (click)="redirecttologout()" style="color: #C70000;">
                    <img src="./../../../../assets/img/log-in-01.svg"  height="20">
                    Log Out</li>
              </div>
              </ul>
            </li>
            
          </ul>
          <!-- <div class="dropdown" *ngIf="isDropdownOpen && !a?.showMenu && !a?.regType" id="dropdown" aria-labelledby="dropdown" >
            <!-- <img class="userImage" [src]="imageView" /> --
            <div class="dropdown-list">
             
            </div>
          </div> -->
        <!-- </div> -->
        </div>
    
        </div>
      </div>
      
    </nav>
  </div>