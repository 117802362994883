import { Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormControl, Validators, FormBuilder, FormGroupDirective, NgForm } from '@angular/forms'
// import { CustomvalidationService } from './customvalidation.service';
import { HttpClient } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';
import { ActivatedRoute, Router } from '@angular/router';
import { commonservice } from 'src/app/allServices/commonservice';
import { APIURL, VARS } from 'src/app/allServices/constant.service';
import { ElementRef } from '@angular/core';
import { EMPTY } from 'rxjs';
import { ApiServiceService } from 'src/app/allServices/api-service.service';
import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';

@Component({
  selector: 'app-registers',
  templateUrl: './registers.component.html',
  styleUrls: ['./registers.component.css']
})
export class RegistersComponent implements OnInit {

  signupformforloophead: FormGroup;
  buttonShow = false;
  url = "assets/img/images.png";
  tableDataofcountry: any[] = [];
  tableDataofstate: any[] = [];
  countryid: any;
  Passandconfirmvalid = false;
  atleastonesocialmedialink = false;
  forcity: any = false;
  disabvalue: any = false;
  displayname = "none";
  errorforusername = false;
  usernameerror: string;
  first:any=true
  isMobile: boolean;

  // imagevalidation = true;


  constructor(private formbuilder: FormBuilder,    public api: ApiServiceService,public common: commonservice, private http: HttpClient, private toastr: ToastrService, public router: Router, private route: ActivatedRoute, private el: ElementRef,private breakpointObserver: BreakpointObserver) {
    // this.getapicallforcountry();

    const reg = '(https?://)?([\\da-z.-]+)\\.([a-z.]{2,6})[/\\w .-]*/?';
    const no = '^[0-9]{4,11}$';
    const password = '^(?=.*[A-Z])(?=.*[@#$%^&+*!=]).*$';
    // const instagram = /(?:(?:http|https):\/\/)?(?:www\.)?(?:instagram\.com|instagr\.am)\/([A-Za-z0-9-_\.]+)/im;
    // const twitter = /http(?:s)?:\/\/(?:www\.)?twitter\.com\/([a-zA-Z0-9_]+)/;
    // const youtube = /^(?:https?:\/\/)?(?:m\.|www\.)?(?:youtu\.be\/|youtube\.com\/(?:embed\/|v\/|watch\?v=|watch\?.+&v=))((\w|-){11})(?:\S+)?$/;
    // const tiktok = /(?:(?:http|https):\/\/)?(?:www\.)?(?:tiktok\.com)\/@*([A-Za-z0-9-_\.]+)/;
    // const twitch = /https:\/\/(?:clips|www)\.twitch\.tv\/(?:(?:[a-z])\/clip\/)?([a-zA-Z]+)/
    // const beatstars = /http(?:s)?:\/\/(?:www\.)?beatstars\.com\/([a-zA-Z0-9_]+)/
    const username = /^\S+$/;
    this.signupformforloophead = formbuilder.group({

      tickbox: [false, [Validators.requiredTrue]],
      // Firstname: ["", [Validators.required]],
      // full_name: ["", [Validators.required]],
      // Lastname: ["", [Validators.required]],
      username: ["", [Validators.required, Validators.pattern(username)]],
      emailerr: ["", [Validators.required, Validators.email]],
      password: ["", [Validators.minLength(6), Validators.required, Validators.pattern(password)]],
      confirmpassword: ["", [Validators.minLength(6), Validators.required, Validators.pattern(password)]],
      // country: ["", [Validators.required]],
      // state: ["", [Validators.required]],
      // city: ["", Validators.required],
      // zip: ["", [Validators.required, Validators.pattern(no)]],
      // instagram: ["", [Validators.pattern(instagram)]],
      // twitter: ["", [Validators.pattern(twitter)]],
      // youtube: ["", [Validators.pattern(youtube)]],
      // beatstars: ["", [Validators.pattern(beatstars)]],
      // tiktok: ["", [Validators.pattern(tiktok)]],
      // twitch: ["", [Validators.pattern(twitch)]],
    });
this.signupformforloophead.reset();
  }
  ngOnInit(): void {
    this.breakpointObserver.observe(['(max-width: 576px)']).subscribe((state: BreakpointState) => {
      this.isMobile = state.matches;
    });
    this.signupformforloophead.value.tickbox = false
this.signupformforloophead.reset();
    const check = document.getElementById('tickbox')?.className
    let local = localStorage.getItem("clientintoken");
    if (local) {

      this.router.navigate(['/explore']);


    }
    
  }
  redirecttologin() {
    this.router.navigate(['/login']);
  }
  ngAfterViewInit() {
    this.signupformforloophead.value.username = ""
    this.signupformforloophead.value.emailerr = ""
    this.signupformforloophead.reset();
   

  }

  ngOnDestroy(): void {
    this.signupformforloophead.value.username = ""
    this.signupformforloophead.value.emailerr = ""
    this.signupformforloophead.reset();
    this.signupformforloophead.get('tickbox')?.setValue(false);

  }

  


  confirmpasserror : any =''



  passwordConfirm() {
    // ////console.log('hi');
    const password = this.signupformforloophead.get('password')?.value;
    const confirmPassword = this.signupformforloophead.get('confirmpassword')?.value;
        this.confirmpasserror = document.getElementById('confirmpasserror');
    if(password){
    if (password !== confirmPassword) {
      this.confirmpasserror = 'Password and Confirm Password do not match';
    } else {
    this.confirmpasserror = '';
    }
    }
    else{
      this.confirmpasserror = '';
      
      }
      }







  onSubmit() {


    for (const key of Object.keys(this.signupformforloophead.controls)) {
      if (this.signupformforloophead.controls[key].invalid) {
        const invalidControl = this.el.nativeElement.querySelector('[formcontrolname="' + key + '"]');
        invalidControl.focus();
        break;
      }
    }

    if (this.signupformforloophead.value.username.indexOf('@') == 0) {        //if @ is present in the beginning of word , remove it
      this.signupformforloophead.value.username = this.signupformforloophead.value.username.replace(/^@+/, '')
    }
    // ////console.log(this.signupformforloophead.value.username)
    if (this.signupformforloophead.value.username == "") {
      this.usernameerror = "write '@' like this";
      this.errorforusername = true;

      // ////console.log("khali");
    } else {
      this.usernameerror = "";
      this.errorforusername = false;
    }



    let againid: any = document.getElementById("confirmpasserror");
    // let atleast1social: any = document.getElementById("atleastonesocialmedialink");
    // ////console.log(this.Passandconfirmvalid, this.signupformforloophead.valid);
    let passwordverification: any = this.signupformforloophead.value.password === this.signupformforloophead.value.confirmpassword;
    // ////console.log(passwordverification);

    // if (this.signupformforloophead.value.tiktok.length == 0 && this.signupformforloophead.value.twitch.length == 0 && this.signupformforloophead.value.youtube.length == 0 && this.signupformforloophead.value.beatstars.length == 0 && this.signupformforloophead.value.instagram.length == 0 && this.signupformforloophead.value.twitter.length == 0) {
    //   // atleast1social.innerHTML = "At Least one of the social media link is required"
    //   this.atleastonesocialmedialink = false;

    // } else {
    //   atleast1social.innerHTML = "";
    //   this.atleastonesocialmedialink = true;
    // }

    if (passwordverification) {
      againid.innerHTML = "";
      this.Passandconfirmvalid = true;
    } else {
      againid.innerHTML = "Passsword and Confirm Password didnt match";
      this.Passandconfirmvalid = false;
    }


    // ////console.log(this.signupformforloophead.valid);
    // ////console.log(this.Passandconfirmvalid);
    // ////console.log(this.atleastonesocialmedialink);

    this.passwordConfirm();
    this.signupformforloophead.markAllAsTouched();
    if (this.signupformforloophead.valid == true && this.Passandconfirmvalid == true  && this.errorforusername == false) {


      this.apiforsubmitform();
      this.displayname = "";

    }
    else {
      // ////console.log("not working");

    }

  }
  changevalue = false;


  // valuepass() {
  //   this.countryid = this.signupformforloophead.value.country;

  //   this.forcity = true;
  //   this.getapicallforcity(this.countryid);
  //   if (this.signupformforloophead.value.country === "") {
  //     this.changevalue = false;
  //   } else {
  //     this.changevalue = true;
  //   }



  // }

  testhtml() {
    if (this.changevalue == true) {
      return "Loading...Please wait"
    } else {
      return "Select";
    }

  }


  // getapicallforcountry() {



  //   const headers = "";

  //   this.http.post(APIURL.BASE_URL + '/user/get_all_countries', { headers }).subscribe(async (res: any) => {
  //     if (res.response.status == 1) {
  //       this.tableDataofcountry = res.response.data;



  //     } else {
  //       let errorthroughapi = res.response.errors;
  //       let error = [];

  //       for (var i = 0; i < errorthroughapi.length; i++) {
  //         let jk = error.push(errorthroughapi);
  //         this.toastr.error(res.response.errors[i], "", { positionClass: 'toast-bottom-right' })
  //       }
  //     }
  //   }, async e => {
  //     this.toastr.error("Failed to get country details ", "", { positionClass: 'toast-bottom-right' });
  //   });
  // }





  // getapicallforcity(value: any) {
  //   let sendingData: any;
  //   if (this.signupformforloophead.value.country === "") {
  //     this.tableDataofstate = [];
  //     this.changevalue = false;
  //     // ////console.log("hey");
  //   } else {
  //     sendingData = {
  //       "country_id": value
  //     }


  //     this.http.post(APIURL.BASE_URL + '/user/get_states_for_country_id', sendingData).subscribe(async (res: any) => {
  //       if (res.response.status == 1) {
  //         this.changevalue = false;
  //         this.tableDataofstate = res.response.data;





  //       } else {
  //         let errorthroughapi = res.response.errors;
  //         let error = [];
  //         for (var i = 0; i < errorthroughapi.length; i++) {
  //           let jk = error.push(errorthroughapi);
  //           this.toastr.error(res.response.errors[i], "", { positionClass: 'toast-bottom-right' })
  //         }

  //       }
  //     }, async e => {
  //       this.changevalue = false;
  //       this.toastr.error("Failed to get state details ", "", { positionClass: 'toast-bottom-right' });
  //     });
  //   }




  // }

  Privacy(){
    this.router.navigate(['/PrivacyPolicy'])
  }
  terms(){
    this.router.navigate(['/termsandcondition'])
  }

  apiforsubmitform() {
    this.common.showLoader();
    this.buttonShow = true;

    // if (this.signupformforloophead.value.username.indexOf('@') == 0) {        //if @ is present in the beginning of word , remove it
    //   this.signupformforloophead.value.username = this.signupformforloophead.value.username.replace(/^@+/, '')
    // }

    let sendingData = {
      // first_name: this.signupformforloophead.value.Firstname,
      // last_name: this.signupformforloophead.value.Lastname,
      // full_name: this.signupformforloophead.value.full_name,
      email: this.signupformforloophead.value.emailerr,
      // country: this.signupformforloophead.value.country,
      confirm_new_password: this.signupformforloophead.value.confirmpassword,
      // city: this.signupformforloophead.value.city,
      // beatstars_link: this.signupformforloophead.value.beatstars,
      // instagram_link: this.signupformforloophead.value.instagram,
      password: this.signupformforloophead.value.password,
      // state: this.signupformforloophead.value.state,
      terms_policy_checkbox: this.signupformforloophead.value.tickbox,
      // tiktok_link: this.signupformforloophead.value.tiktok,
      // twitch_link: this.signupformforloophead.value.twitch,
      // twitter_link: this.signupformforloophead.value.twitter,
      username: this.signupformforloophead.value.username,
      // youtube_link: this.signupformforloophead.value.youtube,
      // zip: this.signupformforloophead.value.zip

    }
    this.http.post(APIURL.BASE_URL + '/user/register_user', sendingData).subscribe(async (res: any) => {
      this.common.hideLoader();
      this.buttonShow = false;
      if (res.response.status == 1) {
        this.toastr.success("Succesfully logged in", "", { positionClass: 'toast-bottom-right' });
        // ////console.log(res.response.data.user_details.first_name);
        localStorage.setItem(VARS.CLIENT_LOCAL_TOKEN_KEY, res.response.data.token);
        this.router.navigate(['emailverify']);
        this.api.postApiHit(APIURL.user_get_logged_in_user_details, {}, VARS.ClIENT_TYPE).then((res: any) => {
          this.common.hideLoader();
          if (res.response.status) {
            
            this.common.setDataInCookie(VARS.Login_Client_data, res.response.data);
            this.common.getDataInCookie(VARS.Login_Client_data).then((res: any) => {
              let resa = JSON.parse(res)
              this.common.eventPublish('_Update_profile');
              this.api.getUrlToRedirect(resa.is_on_boarding_complete, resa.steps_completed);
            })
          }
        })
        this.displayname = "none";
      } else {
        let errorthroughapi = res.response.errors;
        let error = [];
        for (var i = 0; i < errorthroughapi.length; i++) {
          let jk = error.push(errorthroughapi);
          this.toastr.error(res.response.errors[i], "", { positionClass: 'toast-bottom-right' })
          this.displayname = "none";
        }
      }
    }, async e => {
      this.common.hideLoader();


      this.displayname = "none";

      this.toastr.error("Please fill the form again ", "", { positionClass: 'toast-bottom-right' });



    });


  }


}
