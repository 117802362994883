import { HttpClient } from '@angular/common/http';
import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { ApiServiceService } from 'src/app/allServices/api-service.service';
import { commonservice } from 'src/app/allServices/commonservice';
import { APIURL, CONSTNAME, VARS } from 'src/app/allServices/constant.service';
import * as CryptoJS from 'crypto-js';

@Component({
  selector: 'app-update-pack',
  templateUrl: './update-pack.component.html',
  styleUrls: ['./update-pack.component.css']
})
export class UpdatePackComponent implements OnInit {
  @Output() messageEvent = new EventEmitter<string>();
  pageno: any = 1;
  rowsize: any = 10;
  pagercount: any = [];
  sorttype = "";
  profile_image_pathofcomments = "assets/img/artwork.jpg";
  sorton = "";
  user_id: any;
  list: any;
  durationofaudio: any;
  sliderValue : any = 0.00
  total: any;
  above15second: boolean;
  apihitforstream: boolean;
  above5secondlessthan15: boolean;
  above1secondlessthan5: boolean;
  id: NodeJS.Timeout;
  decrypted: any;
  encryptedagainremoveslashes: string;
  tokenFromUI: string = "0123456789123456";
  value: any;
  user: any;
  checked:boolean
  loops_list: any=[];
  search: any;
  encrypted: string;
  paginationempty = false;
  hidepagination: boolean;
  pagenocount: any;
  record: any;
  nocomments: boolean;
  pagenolist: number;
  user1: any;
  pages: any;
  player: any = 0;
  finished: boolean=false;
  goprevious: any=false;
  constructor(private router: Router, public common: commonservice,private route: ActivatedRoute, public api: ApiServiceService,private http: HttpClient, private toastr: ToastrService) { }

  ngOnInit(): void {
    let tokkenget = this.common.getlocal(VARS.CLIENT_LOCAL_TOKEN_KEY);
    // ////console.log('jii');

    let headers: any;
    if (tokkenget) {
      headers = { 'Authorization': `Bearer ` + tokkenget, 'My-Custom-Header': 'foobar', };

    }
    this.value = this.route.snapshot.queryParamMap.get('pack_id');
    // ////console.log(this.value);
    
    this.encryptedagainremoveslashes = decodeURIComponent(this.value);
    // ////console.log(this.encryptedagainremoveslashes);
    // this.decryptUsingAES256();
    let _key = CryptoJS.enc.Utf8.parse(this.tokenFromUI);
    let _iv = CryptoJS.enc.Utf8.parse(this.tokenFromUI);
    this.decrypted = CryptoJS.AES.decrypt(
      this.encryptedagainremoveslashes, _key, {
      keySize: 16,
      iv: _iv,
      mode: CryptoJS.mode.ECB,
      padding: CryptoJS.pad.Pkcs7
    }).toString(CryptoJS.enc.Utf8);
    // ////console.log(this.decrypted)
    // ////console.log(this.decrypted);
    // ////console.log(this.decrypted)
    this.decrypted = this.decrypted.replace(/[""]/gi, '');
    // ////console.log(this.decrypted);
    
     var sendingdata1= {
      pack_id :this.decrypted
    }
    // ////console.log(this.decrypted);
    this.api.forclientgettokenpublic(`/user/pack_detail`, sendingdata1).then(async (res: any) => {
      this.user = res.response.data
      // this.loops_list = res.response.data.loop_detials
    })
    this.getallsounds()
  }
  ngOnDestroy() {
      // ////console.log('y',this?.user);
      if(!this?.finished&&!this.goprevious){
      // ////console.log('y',this.encrypted)
      this.toastr.error("Please select loops first", "", { positionClass: 'toast-bottom-right' })
      this.router.navigate(['/add-loops'], { queryParams: { pack_id: this.value }});

    }
    this.stopAudio(this.loops_list[this.player]);
  }
  getallsounds(){
    this.user_id = localStorage.getItem('client_data');
    const user = JSON.parse(this.user_id).user_type_id;
    let sendingdata = {
      "page_no": this.pageno,
      "sort_on": this.sorton,
      "row_size": this.rowsize,
      "sort_type": this.sorttype,
      "search" : this.search,
      "loop_search": 1,
      "profile_user_id":user
    }

    // this.http.post(APIURL.BASE_URL + `/user/get_all_user_sounds`, sendingdata, { headers }).subscribe(async (res: any) => {
    this.api.forclientgettokenpublic(`/user/get_all_user_sounds`, sendingdata).then((res: any) => {
        // ////console.log(res);
        // ////console.log("res",res.response.data.pagging_list);
     this.list =  res.response.data.pagging_list
         if(res.response.status){
    this.common.hideLoader()
    if (res.response.data.total_pages == 1) {
      this.hidepagination = true;
      // ////console.log("hide");
    } else {
      this.hidepagination = false;
      // ////console.log("not hide");
    }
    this.record = this.list.length;
    if (this.record == 0) {
      this.nocomments = false;
    } else {
      this.nocomments = true;
    }
    this.pagenolist = res.response.data.total_pages + 1;

    this.record = this.pageno - 1 + "1";
    // ////console.log(10*(this.pageno - 1) + res.response.data.pagging_list.length);
    this.pagenocount = 10 * (this.pageno - 1) + res.response.data.pagging_list.length

    this.list = res.response.data.pagging_list;
    // this.record = this.tableData.length;
    this.pagenolist = res.response.data.total_pages + 1;
    if (this.record == "01") {
      this.record = 1;
    }
    for (let i = 1; i < this.pagenolist; i++) {
      this.pagercount.push(i);
    }
    this.pages = res.response.data.total_pages 
    this.total = res.response.data.total_records;   
    this.total = res.response.data.total_records;
        res.response.data.pagging_list.map((item:any,index: any) =>{
        if(item.tags){
          res.response.data.pagging_list[index].tags_array= item.tags.split('\n');
    // ////console.log("res", res.response.data.pagging_list[index].tags_array);
  }
  this.loops_list.map((i:any,ind:any)=>{
    if(i.loop_id===item.loop_id){
      item.checked = i.checked
    }
    
  })
      });
      }

  })
  }
  paginationgetmore() {

    this.paginationempty = true;

    // this.pageno = pageinfo;
    this.rowsize += this.rowsize
    // ////console.log(this.rowsize);
    this.getallsounds()

    // this.getapicallforcouponcode();



  }
  addloop(item:any){
    // ////console.log(item);
    var flag = 0 
    this.loops_list.map((i:any,ind:any)=>{
      if(i.loop_id===item.loop_id){
        this.loops_list.splice(ind,1)
        item.checked = false
        this.user.number_of_loops -= 1  
        flag=1
      }
    })
    if(!flag){
    this.loops_list.push(item)
    // ////console.log(this.loops_list);
    item.checked = true
    this.user.number_of_loops += 1 
    }
    
  }
  remove(item:any,index:any){
    // ////console.log(item,index);
    this.loops_list.splice(index,1)
    item.checked = false
    this.user.number_of_loops -= 1 
    this.api.forclientgettokenpublic(`/user/remove_loop`, {loop_id:item.loop_id}).then(async (res: any) => {})
  }  
  redirectto(item: any) {
    // ////console.log(item)
    this.router.navigate(['advancesearch/'], { queryParams: { page_no:1,sort_on:'relevance',row_size:10,sort_type:'DESC',key_word:item,loop_search:1 }  });
    // ////console.log(item)
    
  }
  setimage1(data: any) {
    // ////console.log(data);
    this.user1 = localStorage.getItem('client_data')
    const profile = JSON.parse(this.user1)
    if (data?.pack_cover_img == "undefined"||data?.pack_cover_img == undefined) {
      if (profile.profile_image_path == null) {
        return this.profile_image_pathofcomments;

      } else {
        if (data.profile_image_path.includes("/profile_photos/")) {
          return  CONSTNAME.fILE_PATH +  data.profile_image_path;
  
          }else
        return  data.profile_image_path;
      }

    } else {

      return CONSTNAME.fILE_PATH + data.pack_cover_img;
    }
  
  }
  setimage(data: any) {
    // ////console.log(data?.pack_cover_img);
    
    if(data?.artwork_path==null){
      if (data?.pack_cover_img == "undefined"||data?.pack_cover_img == undefined) {
      if (data?.profile_image_path == null) {
        return this.profile_image_pathofcomments;

      } else {
        return data.profile_image_path;
      }

    } else {

      return CONSTNAME.fILE_PATH + data.pack_cover_img;
    }
  }else{
    return CONSTNAME.fILE_PATH + data.artwork_path;

  }
  }
  decryptUsingAES256() {
    // ////console.log(this.encryptedagainremoveslashes);
    let _key = CryptoJS.enc.Utf8.parse(this.tokenFromUI);
    let _iv = CryptoJS.enc.Utf8.parse(this.tokenFromUI);
    this.decrypted = CryptoJS.AES.decrypt(
      this.value, _key, {
      keySize: 16,
      iv: _iv,
      mode: CryptoJS.mode.ECB,
      padding: CryptoJS.pad.Pkcs7
    }).toString(CryptoJS.enc.Utf8);
    // ////console.log(this.decrypted)
    // ////console.log(this.decrypted);
    // ////console.log(this.decrypted)
    this.decrypted = this.decrypted.replace(/[""]/gi, '');


    // ////console.log(this.decrypted);
    // this.getapicallforcouponcode();


  }
  playAudio(item: any,index: any) {
   
    // this.showAudioPlayer = true;
    // ////console.log(item,index);
    
    this.player = index
    this.list.map((r: any) => {
      r.hidepausebutton = false;
    })
    this.list.map((r: any) => {
      if (r.audio) r.audio.pause()
    })


    // this.tableData[index].audio.addEventListener('timeupdate', () => {
    //   this.sliderValue = this.tableData[index].audio.currentTime;
    // });

    if (!item.audio) {
      if (item.loop_type == 1) {
        if (item.tagged_loop_path !== null) {
          item.file = CONSTNAME.fILE_PATH + item.tagged_loop_path;
          this.durationofaudio = item.tagged_loop_duration;
        } else {
          item.file = CONSTNAME.fILE_PATH + item.loop_path;
          this.durationofaudio = item.loop_duration;
        }
      } else {
        item.file = CONSTNAME.fILE_PATH + item.tagged_loop_path;
        this.durationofaudio = item.tagged_loop_duration;
      }
      // if (!item.duration) {
      //   let sound: any;
      //   sound = await new Promise((resolve, reject) => {
      //     new p5.SoundFile(item.file, (s: any) => resolve(s), (err: any) => reject(err));
      //   });
      // }
      item.duration = this.durationofaudio;
      item.audio = new Audio(item.file);
      item.audio.play();
      item.audio.addEventListener('timeupdate', () => {
        this.sliderValue = item.audio.currentTime;
      });
      item.audio.addEventListener('ended', () => {
        // this.next();
      });
      if (item.duration >= 15) {
        this.above15second = true;
        this.apihitforstream = true

      }
      else if (item.duration < 15 && item.duration >= 5) {

        this.apihitforstream = true
        this.above5secondlessthan15 = true;
      } else if (item.duration > 1 && item.duration <= 5) {

        this.apihitforstream = true
        this.above1secondlessthan5 = true;
      }
    } else {
      item.audio.addEventListener('timeupdate', () => {
        this.sliderValue = item.audio.currentTime;
      });
      item.audio.addEventListener('ended', () => {
        // this.next();
      });
      item.audio.play();
    }
    




    item.hidepausebutton = true;
    let r: any;
    this.id = setInterval(() => {
      r = item.audio.currentTime;
      if (r === item.duration || r > item.duration) {

        setTimeout(() => {

        }, 1000);
      }

      let dura: any = item.duration;
      if (item.duration >= 15) {
        if (r > 15) {
          if (this.apihitforstream == true) {
            this.apihitforstreaming(item, r, dura);
            clearInterval(this.id);
            this.apihitforstream = false;

          }

        }
      } else if (item.duration < 15 && item.duration >= 5) {

        if (r > 5) {

          if (this.apihitforstream == true) {
            this.apihitforstreaming(item, r, dura);
            clearInterval(this.id);
            this.apihitforstream = false;

          }
        }


      } else if (item.duration > 1 && item.duration <= 5) {
        if (r >= 1) {
          if (this.apihitforstream == true) {
            this.apihitforstreaming(item, r, dura);
            clearInterval(this.id);
            this.apihitforstream = false;

          }

        }
      }
    }, 200);

  }
  stopAudio(item: any) {
    // ////console.log(item);
    if (item) {
      if (item.audio) {
        item.audio.pause()
        // this.showAudioPlayer = false;s
      }
      clearInterval(this.id);

      this.list.map((r: any) => {
        r.hidepausebutton = false;
      })

    } else {
      this.list?.map((r: any) => {
        if (r.audio) {
          r.audio.pause()
        }
        r.hidepausebutton = false;
      })

    }


  }
  async apihitforstreaming(item: any, r: any, dura: any) {

    let tokkenget = await this.common.getlocal(VARS.CLIENT_LOCAL_TOKEN_KEY);

    let headers: any;
    if (tokkenget) {
      headers = { 'Authorization': `Bearer ` + tokkenget, 'My-Custom-Header': 'foobar', };

    }

    let sendingdata = {
      "audio_duration": dura,
      "loop_id": item.loop_id,
      "play_duration": r
    }
    this.http.post(APIURL.BASE_URL + `/user/stream_upload`, sendingdata, { headers }).subscribe(async (res: any) => {
      if (res.response.status == 1) {
        // this.toastr.success(res.response.msg, "", { positionClass: 'toast-bottom-right' });
      }

      else {
        let errorthroughapi = res.response.errors;
        let error = [];
        // this.cancel();
        this.common.hideLoader();
        for (var i = 0; i < errorthroughapi.length; i++) {
          this.toastr.error(res.response.errors[i], "", { positionClass: 'toast-bottom-right' })
        }
      }


    }, async e => {

      this.common.hideLoader();
      this.toastr.error("Error in Api", "", { positionClass: 'toast-bottom-right' });
    });
  
  
  }
 
  previous(){
    this.goprevious = true
    this.router.navigate(['create-pack'],{queryParams: { pack_id: this.value }})
  }

  finish(){
    // ////console.log(this.user.number_of_loops);
    this.finished = true
    if(this.user.number_of_loops==null||this.user.number_of_loops==0){
      this.toastr.error("Please select atleast 1 loop", "", { positionClass: 'toast-bottom-right' });
    }else{
    // this.common.showLoader()
    let sendingdata={
      pack_id:this.decrypted,
      loops : this.loops_list,
      number_of_loops : this.user.number_of_loops,
      edit:false
    }
    this.user_id = localStorage.getItem('client_data');
    const user = JSON.parse(this.user_id).username;
    let _key = CryptoJS.enc.Utf8.parse(this.tokenFromUI);
    let _iv = CryptoJS.enc.Utf8.parse(this.tokenFromUI);
    let encrypted = CryptoJS.AES.encrypt(
      JSON.stringify(user), _key, {
      keySize: 16,
      iv: _iv,
      mode: CryptoJS.mode.ECB,
      padding: CryptoJS.pad.Pkcs7
    });
    this.encrypted = encrypted.toString();
    this.encrypted = encodeURIComponent(this.encrypted);
    this.api.forclientgettokenpublic(`/user/update_pack`, sendingdata).then(async (res: any) => {})
    this.toastr.success("Pack Created", "", { positionClass: 'toast-bottom-right' });
    this.common.hideLoader()
    this.router.navigate([`user/sounds/@${user}`])
  }
}

  
}
