import { Component, OnInit } from '@angular/core';
import { commonservice } from 'src/app/allServices/commonservice';
import { VARS } from 'src/app/allServices/constant.service';
import { environment } from 'src/app/sevicesall/alldata';
@Component({
  selector: 'app-termandcondition',
  templateUrl: './termandcondition.component.html',
  styleUrls: ['./termandcondition.component.css']
})
export class TermandconditionComponent implements OnInit {
  mena = environment.menubar;
  constructor(public common: commonservice) { }

  ngOnInit(): void {
    // this.mena.menuItems.map(i => {
    //   i.hide = true;
    //   // ////console.log(i);
    // })
    // this.mena.seconMenu.map(i => {
    //   i.hide = true;
    //   // ////console.log(i);
    // })
    // this.common.getlocal(VARS.CLIENT_LOCAL_TOKEN_KEY).then(data => {
    //   if (data) {
    //     this.mena.seconMenu.map(i => {
    //       if (i.val == 'logout') i.hide = false;
    //       // ////console.log("in");
    //       // ////console.log(i);
    //     })
    //   } else {
    //     this.mena.menuItems.map(i => {
    //       if (i.val == 'rg') i.hide = false;
    //       if (i.val == 'ad') i.hide = false;
          
    //       // ////console.log("out");
    //       // ////console.log(i);
    //      })
          
    //     }
      // if (data) {
      //   this.menu.seconMenu.map(i => {
      //     if (i.val == 'logout') i.hide = false;
      //     // ////console.log("in");
      //     // ////console.log(i);
      //   })
      // } else {
      //   this.menu.seconMenu.map(i => {
      //     if (i.val == 'rg') i.hide = false;
      //     if (i.val == 'ad') i.hide = false;
          
      //     // ////console.log("out");
      //     // ////console.log(i);
      //    })
          
      //   }
      // 
    // })
  }
}

