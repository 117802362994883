import { HttpClient } from '@angular/common/http';
import { ElementRef } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { ApiServiceService } from 'src/app/allServices/api-service.service';
import { commonservice } from 'src/app/allServices/commonservice';
import { APIURL, INPUT_TYPE_NAME } from 'src/app/allServices/constant.service';
import { StaticService } from 'src/app/sevicesall/static.service';
import { EmailsentComponent } from '../MODAL/emailsent/emailsent.component';
// import { CustomvalidationService } from '../+register/customvalidation.service';
// import { ApiServiceService } from '../allServices/api-service.service';
// import { commonservice } from '../allServices/commonservice';
// import { APIURL, INPUT_TYPE_NAME } from '../allServices/constant.service';
// import { StaticService } from '../sevicesall/static.service';


@Component({
  selector: 'app-forgotpasswordmodel',
  templateUrl: './forgotpasswordmodel.component.html',
  styleUrls: ['./forgotpasswordmodel.component.css']
})
export class ForgotpasswordmodelComponent implements OnInit {
  forgotpasswordloophead = StaticService.forgotpasswordClient();
  forgoterror: boolean;
  constructor(
    private el: ElementRef,
    public matDialog: MatDialog,
    private formbuilder: FormBuilder,
    private http: HttpClient,
    private toastr: ToastrService,
    public router: Router, 
    private route: ActivatedRoute,
    public api: ApiServiceService,
    public common: commonservice,) {
    }

  ngOnInit(): void {
    let local = localStorage.getItem("clientintoken");
    if (local) {

      this.router.navigate(['/explore']);


    }
  }
  cancel(){
    this.matDialog.closeAll()
  }


  vCheck(fieldName: any, type: INPUT_TYPE_NAME, options: any = {}) {
    options.isRequired = true;
    const cFrm = this.forgotpasswordloophead.controls;
    if (cFrm) {
      return StaticService.formError(cFrm, fieldName, type, options).msg;
    }
  }
  redirecttologin(){
    this.router.navigate(['/login']);

  }
  
  onSubmit() {
    const invalidElements = this.el.nativeElement.querySelectorAll('.ng-invalid');
    StaticService.markFormGroupTouched(this.forgotpasswordloophead, invalidElements);
    let sendingData = {
      "email" : this.forgotpasswordloophead.value.email
    }
    // ////console.log(sendingData);
    if (this.forgotpasswordloophead.valid){
      this.common.showLoader();
      this.api.forclientgettokenpublic(APIURL.FORGET_PASSWORD_MAIL,sendingData).then((res: any) => {
        if (res.response.status == 1) {
          this.common.hideLoader();
          // this.toastr.success(res.response.msg, "", { positionClass: 'toast-bottom-right' });
          let dialogue = this.matDialog.open(EmailsentComponent, { panelClass: 'email-sent', disableClose: true });
          dialogue.afterOpened().subscribe(() => {
            document.documentElement.classList.remove('cdk-global-scrollblock');
            document.documentElement.style.top = '0px';
          });
          // dialogue.afterClosed().subscribe(result => {
      
          // });
          // this.cancel();
        }
        else {
          this.forgoterror = true
          let errorthroughapi = res.response.errors;
          let error = [];
          // this.cancel();
          this.common.hideLoader();
          for (var i = 0; i < errorthroughapi.length; i++) {
            this.toastr.error(res.response.errors[i], "", { positionClass: 'toast-bottom-right' })
          }
        }
      }, async e => {
        this.cancel();
        this.common.hideLoader();
        this.toastr.error("Error in Api", "", { positionClass: 'toast-bottom-right' });
      });
    }
  }
}
