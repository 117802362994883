import { HttpClient } from '@angular/common/http';
import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import * as CryptoJS from 'crypto-js';
import { ApiServiceService } from 'src/app/allServices/api-service.service';
import { commonservice } from 'src/app/allServices/commonservice';
import { APIURL, CONSTNAME, VARS } from 'src/app/allServices/constant.service';
import { ConfirmCancelComponent } from 'src/app/CLIENT_PAGES/MODAL/confirm-cancel/confirm-cancel.component';
import { ConfirmDeleteComponent } from 'src/app/CLIENT_PAGES/MODAL/confirm-delete/confirm-delete.component';
import { DeleteaccountComponent } from 'src/app/CLIENT_PAGES/MODAL/deleteaccount/deleteaccount.component';
import { ImageoptionsComponent } from 'src/app/CLIENT_PAGES/MODAL/imageoptions/imageoptions.component';
import { environment } from 'src/app/sevicesall/alldata';
import { ImagePreviewComponent } from '../MODAL/image-preview/image-preview.component';
import { HeaderComponent } from '../CLIENT_PAGE_HEADER_FOOTER/header/header.component';

var thiscomp: any;
@Component({
  selector: 'app-my-account',
  templateUrl: './my-account.component.html',
  styleUrls: ['./my-account.component.css'],
})
export class MYACCOUNTComponent implements OnInit {
  @ViewChild(HeaderComponent) childComponent!: HeaderComponent;
  emailget: any;
  temp_emailget: any;
  tempemail: any;
  showemailtemperrors = false;
  tokenFromUI: string = "0123456789123456";
  encrypted: any = "";
  signupformforloophead: FormGroup;
  buttonShow = false;
  tableDataofcountry: any[] = [];
  tableDataofstate: any[] = [];
  countryid: any;
  Passandconfirmvalid = false;
  atleastonesocialmedialink = false;
  forcity: any = false;
  disabvalue: any = false;
  displayname = "none";
  shouldhitapi: any = false;
  profiletype: string;
  showcityerror: string;
  showerrorforstate: boolean;
  firsttime = true;
  usernameerror: any;
  errorforusername = false;
  errorshowfortitle: string;
  validtitle: boolean = true;
  url = "assets/img/artwork.jpg";
  imagevalidation: boolean;
  reader: any;
  imagenullforleft: boolean;
  imageObject: Array<object> = [{
    image: this.url
  }];
  selectedImageIndex: any;
  showFlag: boolean;
  currentIndex: number;
  user_id: any;
  result: any;
  
  constructor(private router: Router, private http: HttpClient, private formbuilder: FormBuilder, private toastr: ToastrService, private route: ActivatedRoute, public api: ApiServiceService, public common: commonservice, public matDialog: MatDialog, private titleService: Title,private cdr: ChangeDetectorRef) {
    // this.titleService.setTitle("Dashboard");
    // this.getapicallforcountry();

    const reg = '(https?://)?([\\da-z.-]+)\\.([a-z.]{2,6})[/\\w .-]*/?';
    const no = '^[0-9]{4,11}$';
    const password = '^(?=.*[A-Z])(?=.*[@#$%^&+*!=]).*$';
    const instagram =/(?:https?:\/\/)?(?:www\.)?(instagr.*am.*)\//;
    const twitter = /(?:https?:\/\/)?(?:www\.)?(twitt.*er.*|x.*)\//;    // const youtube = /^(?:https?:\/\/)?(?:m\.|www\.)?(?:youtu\.be\/|youtube\.com\/(?:embed\/|v\/|watch\?v=|watch\?.+&v=))((\w|-){11})(?:\S+)?$/;
    const tiktok = /(?:(?:http|https):\/\/)?(?:www\.)?(?:tiktok\.com)\/@*([A-Za-z0-9-_\.]+)/;
    const twitch = /https:\/\/(?:clips|www)\.twitch\.tv\/(?:(?:[a-z])\/clip\/)?([a-zA-Z]+)/
    const beatstars = /(?:https?:\/\/)?(?:www\.)?(beatsta.*rs.*)\//;
    const youtube =  /(?:https?:\/\/)?(?:www\.)?(youtu.*be.*)\//;
    const username = /^\S+$/;
    this.signupformforloophead = formbuilder.group({
      username: ["", [Validators.required, Validators.pattern(username)]],
      instagram: ["", [Validators.pattern(instagram)]],
      twitter: ["", [Validators.pattern(twitter)]],
      youtube: ["", [Validators.pattern(youtube)]],
      beatstars: ["", [Validators.pattern(beatstars)]],
      tiktok: ["", [Validators.pattern(tiktok)]],
      twitch: ["", [Validators.pattern(twitch)]],
      bio_guidelines: ["", [Validators.maxLength(160)]],
    });
    
  }

  ngOnInit(): void {
    // ////console.log("ruuunn");
    
    this.reader = new FormData();
    // ////console.log("ruuunn");

    this.common.getDataInCookie(VARS.Login_Client_data).then((res: any) => {
      if (!res) {
        this.getUserDetail();
      }
      // ////console.log("ruuunn");

      let resa = JSON.parse(res)
      if (resa) this.api.geturltoredirectexceptemailverifydashboard(resa.is_on_boarding_complete, resa.steps_completed); {
        this.call(resa);
      }

      // ////console.log("ruuunn");

      

    })
    this.getImage()
  }

  goback(){
    window.history.back();
  }
  apihitforimage() {
    // ////console.log(this.reader);
    
    this.api.postApiHit('/user/upload_profile_photo', this.reader, VARS.ClIENT_TYPE).then((res: any) => {
      if (res.response.status == 1) {
        let keys = res.response.data.loop_id;
        this.toastr.success(res.response.msg, "", { positionClass: 'toast-bottom-right' });
        this.getUserDetail();
        // this.common.hideLoader();

      } else {
        let errorthroughapi = res.response.errors;
        let error = [];

        for (var i = 0; i < errorthroughapi.length; i++) {
          let jk = error.push(errorthroughapi);
          this.toastr.error(res.response.errors[i], "", { positionClass: 'toast-bottom-right' })
        }
        this.common.hideLoader();
      }
    }, async e => {
      this.toastr.error("", "", { positionClass: 'toast-bottom-right' });
      this.common.hideLoader();
    });

  }
  ngAfterViewInit() {
    setTimeout(() => {
      // Update data or call functions here
      // For example:
      this.childComponent.ngOnInit();
      this.getUserDetail();
      
      // Trigger change detection
      this.cdr.detectChanges();
    }, 5000);
  }
  selectFile(event: any) {
    // ////console.log("yes in hittning")
    let dialogue = this.matDialog.open(ImagePreviewComponent, { panelClass: 'image-preview', data: { image: event.target.files[0] , pagename : "profile" } });
    dialogue.afterClosed().subscribe(result => {
      // ////console.log(result);
      // window.location.reload()
      if (result.data == true) {
        // this.childComponent.ngOnInit();
        this.getUserDetail();
        
      // Trigger change detection
        this.cdr.detectChanges();
}
      // //   this.removeheaderima();
      // // } else {
      // // this.url = 
      
      // }

    });
    dialogue.afterOpened().subscribe(() => {
      document.documentElement.classList.remove('cdk-global-scrollblock');
      document.documentElement.style.top = '0px';
    });
    // let id: any = document.getElementById("imageerror");
    // if (event.target.files[0] == undefined) {
    //   this.url = "assets/img/artwork.jpg";
    //   this.imagevalidation = true;
    //   id.innerHTML = "";
    // } else {
    //   if (event.target.files[0].type == 'image/jpeg' || event.target.files[0].type == 'image/png' || event.target.files[0].type == 'image/jpg') {

    //     if (event.target.files[0].size > 10000000) {
    //       this.imagevalidation = false;

    //       id.innerHTML = "Image size cannot be max 10 mb";

    //       var read = new FileReader()
    //       read.readAsDataURL(event.target.files[0])
    //       read.onload = (event: any) => {
    //         this.url = event.target.result;
    //       }
    //     } else {
    //       if (event.target.files) {

    //         this.imagevalidation = true;
    //         id.innerHTML = "";
    //         // ////console.log( event.target.files[0]);
            
    //         this.reader.append('profile_image_path', event.target.files[0]);
    //         // this.imageshow = event.target.files[0];
    //         var read = new FileReader()
    //         read.readAsDataURL(event.target.files[0])
    //         read.onload = (event: any) => {
    //           this.url = event.target.result;
    //         }
    //         this.apihitforimage();
    //       }
    //     }
    //   } else {
    //     this.imagevalidation = false;
    //     id.innerHTML = "Image file should be in jpeg,png,jpg";

    //   }
    // }
  }
  removeimage() {
    this.opendialogueforverification();
  }
  opendialogueforverification(): void {
    let dialogue = this.matDialog.open(ConfirmDeleteComponent, { panelClass: 'forgot-password', disableClose: true, data: { pageValue: "remove" , pagename : "profile" } });
    dialogue.afterClosed().subscribe(result => {
      if (result.data == true) {
        this.removeheaderima();
      } else {
        
      }
      
    });
  }
  removeheaderima() {
    let sendingdata = {
      "remove_profile_image":1
    }
    this.api.postApiHit('/user/remove_profile_header_image', sendingdata, VARS.ClIENT_TYPE).then((res: any) => {
      if (res.response.status == 1) {
        let keys = res.response.data.loop_id;
        this.toastr.success(res.response.msg, "", { positionClass: 'toast-bottom-right' });
        this.getUserDetail();
        this.common.hideLoader();

      } else {
        let errorthroughapi = res.response.errors;
        let error = [];

        for (var i = 0; i < errorthroughapi.length; i++) {
          let jk = error.push(errorthroughapi);
          this.toastr.error(res.response.errors[i], "", { positionClass: 'toast-bottom-right' })
        }
        this.common.hideLoader();
      }
    }, async e => {
      this.toastr.error("", "", { positionClass: 'toast-bottom-right' });
      this.common.hideLoader();
    });
  }
  
  getImage(){
    // ////console.log("ruuunn1");

    this.common.getDataInCookie(VARS.Login_Client_data).then((res: any) => {
      let resa = JSON.parse(res);
      if (resa.profile_image_path == null) {
        this.imagenullforleft = true;
      } else {
        this.imagenullforleft = false;
      }
      if(resa && resa.profile_image_path){
        if (resa.profile_image_path.includes("/profile_photos/")) {
        this.url = CONSTNAME.fILE_PATH + resa.profile_image_path;
        // return  CONSTNAME.fILE_PATH + this.profile_image_pathofcomments;
  
          }else
          this.url = resa.profile_image_path;
          this.imageObject = [];
        let img = {
          image: this.url
        }
        this.imageObject[0]= img
      } else{
        this.url= 'assets/img/artwork.jpg';
        let img = {
          image: this.url
        }
        this.imageObject[0]= img
      }
    });
  }

  keyupfunc() {
    var text_max = 160;
    let get: any = document.getElementById("descriptiontext");
    // ////console.log(get.value.length);
    let text_remainning = text_max - get.value.length
    // ////console.log(text_remainning);
    let getid: any = document.getElementById("textarea_feedback");
    if (get.value.length > 0) {
      getid.innerHTML = (text_remainning + " characters remaining");
    } else {
      getid.innerHTML = "";
    }
  }




  editleft() {
    // ////console.log("file-input");
    let a:any = document.getElementById('file-inputshiddenforleft');
    a.click()
  }
  showLightbox(index: any) {
    this.selectedImageIndex = index;
    this.showFlag = true;
  }

  closeEventHandler() {
    this.showFlag = false;
    this.currentIndex = -1;
  }



  onSubmit() {
    // ////console.log(this.signupformforloophead)
  
    if (this.signupformforloophead.value.username.indexOf('@') == 0) {        //if @ is present in the beginning of word , remove it
      this.signupformforloophead.value.username = this.signupformforloophead.value.username.replace(/^@+/, '')
    }
    if (this.signupformforloophead.value.username.length > 50) {
      this.usernameerror = "Please enter username of less than 50 characters";
      this.errorforusername = true;
      window.scrollTo(0,0)
    }else {
      this.usernameerror = "";
      this.errorforusername = false;
    }
    let againid: any = document.getElementById("confirmpasserror");
    let atleast1social: any = document.getElementById("atleastonesocialmedialink");
    // ////console.log(this.Passandconfirmvalid, this.signupformforloophead.valid);
    let passwordverification: any = this.signupformforloophead.value.password === this.signupformforloophead.value.confirmpassword;
    this.signupformforloophead.markAllAsTouched();
    // ////console.log(this.errorforusername );
    
    if (this.signupformforloophead.valid == true   && this.errorforusername == false && this.validtitle == true) {


      this.apiforsubmitform();
      this.displayname = "";

    }
    else {
      // ////console.log("not working");

    }

  }
  changevalue = false;


  valuepass() {
    this.countryid = this.signupformforloophead.value.country;

    this.forcity = true;
    // this.getapicallforcity(this.countryid);
    if (this.signupformforloophead.value.country === "") {
      this.changevalue = false;
    } else {
      this.changevalue = true;
    }



  }

  testhtml() {
    if (this.changevalue == true) {
      return "Loading...Please wait"
    } else {
      return "Select";
    }

  }


  // getapicallforcountry() {



  //   const headers = "";

  //   this.http.post(APIURL.BASE_URL + '/user/get_all_countries', { headers }).subscribe(async (res: any) => {
  //     if (res.response.status == 1) {
  //       // ////console.log(res.response.data);
  //       this.tableDataofcountry = res.response.data;
  //       // ////console.log(this.firsttime)
  //       // this.getUserDetail();
        
  //       // 



  //     } else {
  //       let errorthroughapi = res.response.errors;
  //       let error = [];

  //       for (var i = 0; i < errorthroughapi.length; i++) {
  //         let jk = error.push(errorthroughapi);
  //         this.toastr.error(res.response.errors[i], "", { positionClass: 'toast-bottom-right' })
  //       }
  //     }
  //   }, async e => {
  //     this.toastr.error("Failed to get country details ", "", { positionClass: 'toast-bottom-right' });
  //   });
  // }





  // getapicallforcity(value: any) {

  //   let sendingData: any;
  //   if (this.signupformforloophead.value.country == "" ) {
  //     this.tableDataofstate = [];
  //     this.changevalue = false;
  //     // ////console.log("hey");
  //   } else {
  //     sendingData = {
  //       "country_id": value
  //     }


  //     this.http.post(APIURL.BASE_URL + '/user/get_states_for_country_id', sendingData).subscribe(async (res: any) => {
  //       if (res.response.status == 1) {
  //         this.changevalue = false;
  //         // ////console.log(res.response.data);
  //         if (this.firsttime == true) {
  //           // ////console.log("first time")
  //           this.firsttime = false;
  //         } else {
  //           this.signupformforloophead.value.state = "";
  //           // ////console.log("second time")
  
  //         }
  //         // this.signupformforloophead.value.state = "";
  //         this.tableDataofstate = res.response.data;





  //       } else {
  //         let errorthroughapi = res.response.errors;
  //         let error = [];
  //         for (var i = 0; i < errorthroughapi.length; i++) {
  //           let jk = error.push(errorthroughapi);
  //           this.toastr.error(res.response.errors[i], "", { positionClass: 'toast-bottom-right' })
  //         }

  //       }
  //     }, async e => {
  //       this.changevalue = false;
  //       this.toastr.error("Failed to get state details ", "", { positionClass: 'toast-bottom-right' });
  //     });
  //   }




  // }




  apiforsubmitform() {
    this.buttonShow = true;
    let sendingData = {
      "user_type_id": this.user_id,
      "beatstars_link": this.signupformforloophead.value.beatstars,
      "instagram_link": this.signupformforloophead.value.instagram,
      "tiktok_link": this.signupformforloophead.value.tiktok,
      "twitch_link": this.signupformforloophead.value.twitch,
      "twitter_link": this.signupformforloophead.value.twitter,
      "username": this.signupformforloophead.value.username,
      "youtube_link": this.signupformforloophead.value.youtube,
      "bio_guidelines": this.signupformforloophead.value.bio_guidelines

    }

    if (this.signupformforloophead.value.bio_guidelines !== "") {
      sendingData["bio_guidelines"] = this.signupformforloophead.value.bio_guidelines;
    }

    this.api.postApiHit(`/user/save_user_profile`, sendingData, VARS.ClIENT_TYPE).then((res: any) => {
      this.common.hideLoader();
      this.buttonShow = false;
      if (res.response.status == 1) {

        this.toastr.success(res.response.msg, "", { positionClass: 'toast-bottom-right' });
        this.getUserDetail();
        this.shouldhitapi = true;
        this.displayname = "none";
      let _key = CryptoJS.enc.Utf8.parse(this.tokenFromUI);
      let _iv = CryptoJS.enc.Utf8.parse(this.tokenFromUI);
      let encrypted = CryptoJS.AES.encrypt(
        JSON.stringify(this.user_id), _key, {
        keySize: 16,
        iv: _iv,
        mode: CryptoJS.mode.ECB,
        padding: CryptoJS.pad.Pkcs7
      });
      this.encrypted = encrypted.toString();
      this.encrypted = encodeURIComponent(this.encrypted);
        // ////console.log(res.response);
        setTimeout(() => {
        
          this.router.navigate([`user/sounds/@${sendingData.username}`])
        
      }, 200);
        
      } else {

        let errorthroughapi = res.response.errors;
        let error = [];
        for (var i = 0; i < errorthroughapi.length; i++) {
          let jk = error.push(errorthroughapi);
          this.toastr.error(res.response.errors[i], "", { positionClass: 'toast-bottom-right' })
          this.displayname = "none";
        }
      }
    }, async e => {
      this.common.hideLoader();


      this.displayname = "none";

      this.toastr.error("", "", { positionClass: 'toast-bottom-right' });



    });


  }

  openDialog() {
    this.opendialoguefordelete();
  }

  opendialoguefordelete(): void {
    let dialogue = this.matDialog.open(DeleteaccountComponent, { panelClass: 'forgot-password', disableClose: true });
    dialogue.afterClosed().subscribe(result => {
      // ////console.log('The dialog was closed');
    });
  }

  call(res:any) {
    // ////console.log(res)

    
    this.emailget = res.email;
    this.temp_emailget = res.temp_email;
    // ////console.log(this.temp_emailget);
    this.user_id  = res.user_type_id
    if (this.emailget !== this.temp_emailget) {
      this.showemailtemperrors = true;
      this.tempemail = this.temp_emailget

    }
    this.signupformforloophead.patchValue({
      profilevisible: res.profile_type,
      // Firstname: res.response.data.first_name,
      // Lastname: res.response.data.last_name,
      full_name: res.first_name + " " +res.last_name,
      username: res.username,
      emailerr: res.email,
      country: res.country,
      state: res.state,
      city: res.city,
      zip: res.zip,
      instagram: res.instagram_link,
      twitter: res.twitter_link,
      youtube: res.youtube_link,
      beatstars: res.beatstars_link,
      tiktok: res.tiktok_link,
      twich: res.twitch_link,
      bio_guidelines: res.bio_guidelines,
      confirmpassword: '',
      password: ''

  

    });

    if (res.profile_type == 1) {
      // this.profiletype = "Public"
      this.signupformforloophead.patchValue({
        profilevisible: 0
      })
    } else {
      // this.profiletype = "Private"
      this.signupformforloophead.patchValue({
        profilevisible: 1
      })
    }
    // ////console.log(res.response.data.state);
    if (this.shouldhitapi == false) {
      if (res.country == null) {
        
      } else {
        // this.getapicallforcity(res.country);
      }
      
    } else {

    }

    if (res.last_name == null) {
      this.signupformforloophead.patchValue({
        full_name: res.first_name
      })

    }
  }


  getapiforallinfo() {
    // this.common.showLoader();
    this.api.postApiHit(`/user/get_logged_in_user_details`, {}, VARS.ClIENT_TYPE).then((res: any) => {

      // this.afterload = true;
      if (res.response.status == 1) {


        // ////console.log(res.response);

        this.emailget = res.response.data.email;
        this.temp_emailget = res.response.data.temp_email;
        // ////console.log(this.temp_emailget);

        if (this.emailget !== this.temp_emailget) {
          this.showemailtemperrors = true;
          this.tempemail = this.temp_emailget

        }
        // ////console.log(res.response.data.username);

        //  if( res.response.data.temp_email.length !==) 

        this.signupformforloophead.patchValue({
          profilevisible: res.response.data.profile_type,
          // Firstname: res.response.data.first_name,
          // Lastname: res.response.data.last_name,
          full_name: res.response.data.first_name + " " + res.response.data.last_name,
          username: res.response.data.username,
          emailerr: res.response.data.email,
          country: res.response.data.country,
          state: res.response.data.state,
          city: res.response.data.city,
          zip: res.response.data.zip,
          instagram: res.response.data.instagram_link,
          twitter: res.response.data.twitter_link,
          youtube: res.response.data.youtube_link,
          beatstars: res.response.data.beatstars_link,
          tiktok: res.response.data.tiktok_link,
          twich: res.response.data.twitch_link,
          bio_guidelines: res.response.data.bio_guidelines,
          confirmpassword: '',
          password: ''

          // last_name: this.signupformforloophead.value.Lastname,
          // email: this.signupformforloophead.value.emailerr,
          // country: this.signupformforloophead.value.country,
          // city: this.signupformforloophead.value.city,
          // beatstars_link: this.signupformforloophead.value.beatstars,
          // instagram_link: this.signupformforloophead.value.instagram,
          // password: this.signupformforloophead.value.password,
          // confirm_new_password: this.signupformforloophead.value.confirmpassword,
          // state: this.signupformforloophead.value.state,

          // tiktok_link: this.signupformforloophead.value.tiktok,
          // twitch_link: this.signupformforloophead.value.twitch,
          // twitter_link: this.signupformforloophead.value.twitter,
          // username: this.signupformforloophead.value.username,
          // youtube_link: this.signupformforloophead.value.youtube,
          // zip: this.signupformforloophead.value.zip

        });

        if (res.response.data.profile_type == 1) {
          // this.profiletype = "Public"
          this.signupformforloophead.patchValue({
            profilevisible: 0
          })
        } else {
          // this.profiletype = "Private"
          this.signupformforloophead.patchValue({
            profilevisible: 1
          })
        }
        // ////console.log(res.response.data.state);
        if (this.shouldhitapi == false) {
          // this.getapicallforcity(res.response.data.country);
        } else {

        }

        if (res.response.data.last_name == null) {
          this.signupformforloophead.patchValue({
            full_name: res.response.data.first_name
          })

        }







        this.common.hideLoader();

      } else {
        let errorthroughapi = res.response.errors;
        let error = [];
        this.common.hideLoader();


        for (var i = 0; i < errorthroughapi.length; i++) {
          let jk = error.push(errorthroughapi);
          this.toastr.error(res.response.errors[i], "", { positionClass: 'toast-bottom-right' })
          this.common.hideLoader();
        }
      }
    }, async e => {

    });
  }
  verifyemail() {
    this.api.apihitforresendverification(this.temp_emailget);
    // ////console.log("click hua");
  }



  getUserDetail() {
    // ////console.log("!")
    // this.common.showLoader();
    this.api.postApiHit(APIURL.user_get_logged_in_user_details, {}, VARS.ClIENT_TYPE).then((res: any) => {
      this.common.hideLoader();
      if (res.response.status) {
        this.common.setDataInCookie(VARS.Login_Client_data, res.response.data);
        this.common.getDataInCookie(VARS.Login_Client_data).then((res: any) => {

          let resa = JSON.parse(res)
          this.call(resa)
          
        this.getImage();
        this.childComponent.ngOnInit();

          this.api.geturltoredirectexceptemailverifydashboard(resa.is_on_boarding_complete, resa.steps_completed);
        })
      }
    })
  }
  banned_word: any = [];

  getapicallforalldata() {
    const sendingdata = "";
    this.api.postApiHit(`/user/get_genre_instru_tags`, sendingdata, VARS.ClIENT_TYPE).then((res: any) => {
      if (res.response.status == 1) {
        
       
        
        
       
        for (i = 0; i < res.response.data.banned_words.length; i++) {
          this.banned_word.push(res.response.data.banned_words[i].banned_word);
        }

      } else {
        let errorthroughapi = res.response.errors;
        let error = [];
        for (var i = 0; i < errorthroughapi.length; i++) {
          let jk = error.push(errorthroughapi);
          this.toastr.error(res.response.errors[i], "", { positionClass: 'toast-bottom-right' })
        }
      }
    }, async e => {
      this.toastr.error("Failed to get details of Banned words", "", { positionClass: 'toast-bottom-right' });
    });
  }

  valuefortitle() {
    let tolower = function (x: any) {
      return x.toLowerCase();
    };
    let valuefortitle = this.signupformforloophead.value.bio_guidelines;
    var res = valuefortitle.split(" ");
    res = res.map(tolower);
    // ////console.log(this.banned_word);
    this.banned_word = this.banned_word.map(tolower)
    let allFounded = this.banned_word.some((ai: any) => res.includes(ai));
    if (allFounded == true) {
      this.errorshowfortitle = "Use of profanity is restricted"
      // ////console.log("error")
      this.validtitle = false;
    } else {
      // ////console.log("no error");
      this.errorshowfortitle = "";
      this.validtitle = true;
    }

  }




  // valuefortitle() {
  //   let tolower = function (x: any) {
  //     return x.toLowerCase();
  //   };
  //   let valuefortitle = this.signupformforloophead.value.bio_guidelines;
  //   var res = valuefortitle.split(" ");
  //   res = res.map(tolower);
  //   // ////console.log(this.banned_word);
  //   this.banned_word = this.banned_word.map(tolower)
  //   let allFounded = this.banned_word.some((ai: any) => res.includes(ai));
  //   if (allFounded == true) {
  //     this.errorshowfortitle = "Use of profanity is restricted"
  //     // ////console.log("error")
  //     this.validtitle = false;
  //   } else {
  //     // ////console.log("no error");
  //     this.errorshowfortitle = "";
  //     this.validtitle = true;
  //   }

  // }





 
}
